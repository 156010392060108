import React from 'react';
import {withFormik, FormikProps} from 'formik';
// import { connect } from 'react-redux';

import {UserFormSchema} from '../../../../helpers';
import {beautifyRole} from "../../../../helpers/BeautifierForPm";

export interface MyProfileFormValues {
	first_name: string,
	last_name: string,
	email: string,
	role: string,
}

interface OtherProps {
	title: string,

	onOpenModal(): void

	onSubmit(email: string, role: string, first_name: string, last_name: string): void,
}

interface InnerFormProps extends FormikProps<MyProfileFormValues>, OtherProps {

}

interface InnerFormState {

}

class InnerForm extends React.Component<InnerFormProps, InnerFormState> {
	render() {
		const {
			values,
			errors,
			touched,
			handleChange,
			handleBlur,
			handleSubmit,
			onOpenModal
		} = this.props;


		return (
			<form onSubmit={handleSubmit} className="page-wrapper">
				<div className="container-sm">
					<div className="box px-80 px-sm-15 py-60 py-sm-35 mt-45 mt-sm-0">
						<h2 className="fw-500 pb-30">My Profile</h2>
						<div className={"textfield with-icon" + ((errors.first_name && touched.first_name) ? ' error-field' : '')}>
							<label className="label">First Name</label>
							<input
								type="text"
								placeholder="First Name"
								value={values.first_name}
								name="first_name"
								onChange={handleChange}
								onBlur={handleBlur}
							/>
							<span className="icon-user icon">
                <span className="path1"></span>
                <span className="path2"></span>
                <span className="path3"></span>
              </span>
							{errors.first_name && touched.first_name ? (
								<p className="error-text">{errors.first_name}</p>
							) : null}
						</div>
						<div className={"textfield with-icon" + ((errors.last_name && touched.last_name) ? ' error-field' : '')}>
							<label className="label">Last Name</label>
							<input
								type="text"
								placeholder="Last Name"
								value={values.last_name}
								onChange={handleChange}
								onBlur={handleBlur}
								name="last_name"
							/>
							<span className="icon-user icon">
                <span className="path1"></span>
                <span className="path2"></span>
                <span className="path3"></span>
              </span>
							{errors.last_name && touched.last_name ? (
								<p className="error-text">{errors.last_name}</p>
							) : null}
						</div>
						<div className="textfield with-icon">
							<label className="label">Email</label>
							<input
								type="text"
								placeholder="Email"
								value={values.email}
								disabled={true}
								name="email"
							/>
							<span className="icon-email icon">
                <span className="path1"></span>
                <span className="path2"></span>
              </span>
						</div>
						<div className="textfield with-icon no-margin">
							<label className="label">Role</label>
							<input
								type="text"
								placeholder="Role"
								value={beautifyRole(values.role)}
								disabled={true}
								name="role"
							/>
							{
								values.role === 'admin'
									? (<span className="icon-admin icon">
				              <span className="path1"></span>
				              <span className="path2"></span>
			              </span>)
									: (values.role === 'back_officer'
									? (<span className="icon-user icon">
				              <span className="path1"></span>
				              <span className="path2"></span>
				              <span className="path3"></span>
			              </span>)
									: (<span className="icon-worker icon">
				              <span className="path1"></span>
				              <span className="path2"></span>
			                <span className="path3"/>
			              </span>))}
						</div>
						<div className="mt-25">
							<button onClick={onOpenModal} type='button' className="no-style color-primary fw-700">Change Password
							</button>
						</div>
						<div className="text-right mt-10">
							<button className="btn filled primary ripple" type='submit'><span>Save</span></button>
						</div>
					</div>
				</div>
			</form>

		);
	}
};

interface MyFormProps {
	initialValues: MyProfileFormValues,
	title: string,

	onOpenModal(): void

	onSubmit(email: string, role: string, first_name: string, last_name: string): void,
}

export const MyProfileForm = withFormik<MyFormProps, MyProfileFormValues>({
	enableReinitialize: true,

	mapPropsToValues: props => {
		const {initialValues} = props;
		return {
			first_name: initialValues.first_name || '',
			last_name: initialValues.last_name || '',
			email: initialValues.email || '',
			role: initialValues.role || 'user',
		}
	},

	validationSchema: UserFormSchema,

	handleSubmit(
		{first_name, last_name, email, role}: MyProfileFormValues,
		{props, setSubmitting, setErrors}
	) {
		props.onSubmit(email, role, first_name, last_name)
	},

})(InnerForm);