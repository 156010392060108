import React from 'react';
import { withFormik, FormikProps } from "formik";

import Logo from '../../../../assets/img/logo.svg'
import {ChangePasswordSchema} from "../../../../helpers";

interface FormValues {
	password: string
	confirm_password: string
}

interface OtherProps{
	onSubmit(password: string): void;
	disabled: boolean;
}

const InnerForm = (props: OtherProps & FormikProps<FormValues>) => {
	const {
		values,
		errors,
		touched,
		handleChange,
		handleBlur,
		handleSubmit,
		disabled,
	} = props;


	return(
		<form onSubmit={handleSubmit} className="sign-form sign-in">
			<div className="sign-header">
				<img src={Logo} alt="Aquasecurity"/>
			</div>
			<div className="sign-body">
				<div className={"textfield with-icon" + ((errors.password && touched.password)?' error-field':'')}>
					<input
						type="password"
						placeholder="New Password"
						name='password'
						onChange={handleChange}
						onBlur={handleBlur}
						value={values.password}
					/>
					<span className="icon-lock icon">
            <span className="path1"/>
            <span className="path2"/>
          </span>
					{errors.password && touched.password ? (
						<p className="error-text">{errors.password}</p>
					) : null}
				</div>
				<div className={"textfield with-icon" + ((errors.confirm_password && touched.confirm_password)?' error-field':'')}>
					<input
						type="password"
						placeholder="Confirm Password"
						name='confirm_password'
						onChange={handleChange}
						onBlur={handleBlur}
						value={values.confirm_password}
					/>
					<span className="icon-lock icon">
            <span className="path1"/>
            <span className="path2"/>
          </span>
					{errors.confirm_password && touched.confirm_password ? (
						<p className="error-text">{errors.confirm_password}</p>
					) : null}
				</div>
				<div className="flex justify-end mt-50">
					<button disabled={disabled} type='submit' className="btn filled primary ripple"><span>Confirm</span></button>
				</div>
			</div>
		</form>
	)
};

interface MyFormProps{
	initialValues?: FormValues,
	disabled: boolean;
	onSubmit(password: string): void,
}

export const NewPasswordForm = withFormik<MyFormProps, FormValues>({
	mapPropsToValues: () => ({
		password: '',
		confirm_password: '',
	}),

	validationSchema: ChangePasswordSchema,

	handleSubmit(
		{ password }: FormValues,
		{ props }
	) {
		props.onSubmit(password);
	},

})(InnerForm);