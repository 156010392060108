import React, {Component} from 'react';
import EmptyGallery from "../../../../assets/img/gallery/empty-gallery.svg";
import ShowImages from "../Gallery/ShowImages";
import {PicturesState} from "../../../../store/pictures/types";

interface NoNumberProps {
	userId: number
	pictures: PicturesState
	userRole: string
	nextValue: string
	loaded: boolean
	searchValue: string
	optionsCount: number
	device: string

	handleEdit(picture: any): void

	setSearchValue(searchParam: string): void

	redirectToGallery(): void

	onInfoOpen(picture:any): void

	onRightSidebarOpen(): void

	handleLoadMore(nextValue: string): void

	handleDeleteSelected(id: number): void

	bodyOverlay(): void

	handleSavePreviewFor360(id: number, x: number, y: number, z: number): void
}

interface NoNumberState {
	search: string
}

class GlobalGallery extends Component<NoNumberProps, NoNumberState> {

	state = {
		search: this.props.searchValue
	};

	handleChange = (e: React.FormEvent<HTMLInputElement>) => {
		this.setState({
			search: e.currentTarget.value,
		})
	};

	searchPictures = async () => {
		const {search} = this.state;
		await this.props.setSearchValue(search);
	};

	render() {
		return (
			<>
				{(this.props.pictures.count === 0)
					? (
						<>
							<div className="flex justify-between py-25">
								<div className="flex align-center">
									<h2>Search Results</h2>
								</div>
								<button onClick={() => {
									this.props.onRightSidebarOpen();
									this.props.bodyOverlay()
								}} className="btn outline primary fw-700">
									<span>Filter {this.props.optionsCount !== 0 && '(' + this.props.optionsCount + ')'}</span>
								</button>
							</div>
							<div className="text-center empty-page">
								<div className="pb-100">
									<img className="empty-img" src={EmptyGallery} alt=""/>
									<p className="pt-10 font-md">There are no images to display</p>
								</div>
							</div>
						</>
					)
					: (
						<>
							<div className="flex justify-between py-25">
								<div className="flex align-center">
									<h2>Search results</h2>
								</div>
								<button onClick={() => {
									this.props.onRightSidebarOpen();
									this.props.bodyOverlay()
								}} className="btn outline primary fw-700">
									<span>Filter {this.props.optionsCount !== 0 && '(' + this.props.optionsCount + ')'}</span>
								</button>
							</div>
							<ShowImages
								onInfoOpen={this.props.onInfoOpen}
								device={this.props.device}
								SavePreviewFor360={this.props.handleSavePreviewFor360}
								openEdit={this.props.handleEdit}
								handleDeleteSelected={this.props.handleDeleteSelected}
								userRole={this.props.userRole}
								userId={this.props.userId}
								pictures={this.props.pictures.results}
								handleLoadMore={() => this.props.nextValue?this.props.handleLoadMore(this.props.nextValue):{}}
							/>
							{/*{this.props.nextValue &&*/}
              {/*<div className="pb-30 text-center">*/}
              {/*    <button className="link no-style fw-700"*/}
              {/*            onClick={() => this.props.handleLoadMore(this.props.nextValue)}>Load More*/}
              {/*    </button>*/}
              {/*</div>*/}
							{/*}*/}
						</>
					)}
			</>
		);
	}
}

export default GlobalGallery;
