import React from 'react';
import {compose} from 'recompose';
import {Route, RouteComponentProps, withRouter} from "react-router";
import {connect} from 'react-redux'
import {ConnectedReduxProps, ApplicationState} from '../../../store';
import {SessionState} from "../../../store/session/types";
import {toast} from "react-toastify";
import {getUser} from "../../../store/session/actions";
import {verifyToken} from "../../../store/users/actions";
import {
    clearClients,
    getClientsBySearchParam,
    getClientsWithPictures, getFolderNameByID, getInstallationNumbers, getMoreOptions, getTemporaryIDs, loadOptions
} from "../../../store/clients/actions";
import {
    deleteSelected,
    getPicturesBy,
    clearPictures,
    LoadMore,
    editPicture,
    updateCoordinates
} from "../../../store/pictures/actions";
import {clearGlobalSearch, clearSearchValue, setSearchValue} from "../../../store/shared/actions";
import {ClientsState} from "../../../store/clients/types";
import AquaSecLoader from "../components/Loader";
import {PicturesState} from "../../../store/pictures/types";
import {SINGLE_PICTURE_DELETED} from "../../../helpers/toastMessages";
import GlobalGallery from "../components/Search_In_Gallery/GlobalGallery";
import {FormValues, GlobalFilter, GlobalFormInitialValues} from "../components/GlobalFilter";
import * as ROUTES from "../../../helpers/routes";
import {EditPicturesStep1Form} from "../components/Gallery/EditPicturesStep1Form";
import {EditPicturesStep2Form} from "../components/Gallery/EditPicturesStep2Form";
import {EditPicturesStep3Form} from "../components/Gallery/EditPicturesStep3Form";
import {getDefaultTags, getMoreTags} from "../../../store/tags/actions";
import {TagsState} from "../../../store/tags/types";
import ImageInfo from "../components/Gallery/ImageInfo";
import Modal from "react-responsive-modal";
import {DELETE_IMAGE} from "../../../helpers/modalMessages";

const refShowImages = React.createRef();

interface PropsFromState {
    errorMessage: any,
    session: SessionState,
    searchValue: string,
    installationNumberClients: [],
    temporaryIdClients: [],
    pictures: PicturesState,
    nextValue: string
    loading: boolean
    clients: ClientsState,
    globalSearchValues: FormValues,
    device: string,
    defaultTags: TagsState
}

interface PropsFromDispatch {
    editPicture(id: number,
                tags: string[],
                p_type: string,
                ins_num: string,
                tem_id: string,
                type: string,
                oldType: string): void

    setSearchValue(searchParam: string): void

    verifyToken(token: string): void

    getUser(id: number): void

    getClientsWithPictures(): void

    deleteSelected(id: number): void

    clearPictures(): void

    LoadMore(queryParams: string): void

    getClientsBySearchParam(search: string, type: string): void

    updateCoordinates(id: number, x: number, y: number, z: number): void

    clearClients(): void

    clearSearchValue(): void

    getFolderNameByID(id: number): any

    getInstallationNumbers(page?: number, search?: string): void

    getTemporaryIDs(page?: number, search?: string): void

    getMoreOptions(next: string, type: string): void

    loadOptions(search: string, type: string): any

    clearGlobalSearch(): void

    getMoreTags(next: string): void

    getDefaultTags(page: number, search: string, limit?: number): any

}

interface GalleryProps extends RouteComponentProps<any>, PropsFromState, PropsFromDispatch, ConnectedReduxProps {
    openRightSidebar: boolean

    openInfoBar: boolean

    handleGlobalSearchAndFilter(
        search: string,
        normal: boolean,
        threeSixty: boolean,
        no_number_boolean: boolean,
        temporary_id_boolean: boolean,
        installation_number_boolean: boolean,
        for_future_information: boolean,
        quotation: boolean,
        intervention: boolean,
        startDate: any,
        endDate: any
    ): void

    bodyOverlay(): void

    openInfoHandler(): void

    openGlobalFilter(): void

    runGlobalFilter(): void

    count: number
}

interface GalleryState {
    openLeftSidebar: boolean,
    openRightSidebar: boolean,
    initialValues: FormValues,
    folders: [],
    searchParam: string,
    openGlobalFilter: boolean
    filterParams: string
    type: string,
    singlePicture: any,
    installation_number_options: { value: string, label: string }[],
    temporary_id_options: { value: string, label: string }[]
    temporary_id: string,
    installation_number: string,
    installation_number_name: string,
    temporary_id_name: string,
    editedPictureType: string,
    pictureType: string
    searchInstallationFolderValue: string
    searchTemporaryFolderValue: string
    savePictureType: string
    editedFolderID: number
    folderName: string
    onceLoaded: boolean
    showDefault: boolean,
	openDelete: boolean,
}

class Search_In_Gallery extends React.Component<GalleryProps, GalleryState> {

    state: GalleryState = {
        openLeftSidebar: false,
        openRightSidebar: false,
        openGlobalFilter: false,
        initialValues: GlobalFormInitialValues,
        folders: [],
        searchParam: '',
        filterParams: '',
        type: '',
        singlePicture: {tags: [], type: ''},
        editedPictureType: '',
        editedFolderID: NaN,
        installation_number_options: [],
        temporary_id_options: [],
        temporary_id: '',
        installation_number: '',
        installation_number_name: '',
        temporary_id_name: '',
        pictureType: 'no_number',
        searchInstallationFolderValue: '',
        searchTemporaryFolderValue: '',
        savePictureType: '',
        folderName: '',
        onceLoaded: false,
        showDefault: false,
		openDelete: false
    };

    async componentDidMount() {
        if (this.props.count === 0) {
            await this.props.handleGlobalSearchAndFilter('', false, false, true, true, true, false, false, false, null, null);
        }
        this.setState({
            onceLoaded: true,
        });
    }

    setSearchValue = async (value: string) => {
        await this.setState({
            searchParam: value
        });
    };

    redirectToGallery = async () => {
        this.props.history.push('/gallery/installation_number');
    };

    goBack = () => {
        this.props.history.push(`/gallery/${this.props.match.params.type}`)
    };

    handleLoadMore = async (nextValue: string) => {
        await this.props.LoadMore(nextValue);
    };

    toggleShowDefault = (tags: string[]) => {
        this.setState((prevState: GalleryState) => ({
            showDefault: !prevState.showDefault,
            singlePicture: {
                ...prevState.singlePicture,
                tags: tags
            },
        }))
    };

    getMoreTags = async (next: string) => {
        if (next) {
            await this.props.getMoreTags(next);
        }
    };

    handleDeleteSelected = async (id: number) => {
        await this.props.deleteSelected(id);
        await this.props.clearPictures();
        const {
            search, normal, threeSixty, no_number_boolean,
            temporary_id_boolean, installation_number_boolean, for_future_information,
            intervention, quotation, startDate, endDate
        } = this.props.globalSearchValues;
        this.props.handleGlobalSearchAndFilter(search, normal, threeSixty, no_number_boolean,temporary_id_boolean,
            installation_number_boolean, for_future_information, intervention, quotation, startDate, endDate);
        toast.dismiss();
        toast(() =>
            <div className="toast-style success">
                <div className="left-side">
							<span className="icon-success icon">
								<span className="path1"/>
								<span className="path2"/>
							</span>
                </div>
                <div className="right-side">
                    <p>{SINGLE_PICTURE_DELETED}</p>
                </div>
            </div>
        )
    };

    handleEdit = async (picture: any) => {
        await this.props.getTemporaryIDs(1, '');
        await this.props.getInstallationNumbers(1, '');
        await this.props.getDefaultTags(1, '', 30);
        await this.props.openInfoHandler();
        await this.setState({
            installation_number_options: this.props.clients.installation_number_paginated.results.map((client: any) => ({
                value: client.id,
                label: client.name
            })),
            temporary_id_options: this.props.clients.temporary_id_paginated.results.map((client: any) => ({
                value: client.id,
                label: client.name
            })),
            singlePicture: picture,
            pictureType: picture.no_number ? 'no_number' : ''
        });
        this.props.history.push('/search-in-gallery/step1');
    };


    getMoreOptions = async (next: string, type: string) => {
        if (next) {
            if (type === 'installation_number') {
                await this.props.getMoreOptions(next, 'installation_number');
                this.setState({
                    installation_number_options: this.props.clients.installation_number_paginated.results.map((client: any) => ({
                        value: client.id,
                        label: client.name
                    })),
                })
            } else {
                await this.props.getMoreOptions(next, 'temporary_id');
                this.setState({
                    temporary_id_options: this.props.clients.temporary_id_paginated.results.map((client: any) => ({
                        value: client.id,
                        label: client.name
                    })),
                })
            }
        }
    };

    saveEditedPicture = async (tags: string[]) => {
        const {singlePicture, savePictureType, installation_number, temporary_id, type} = this.state;
        await this.props.clearPictures();
        await this.props.editPicture(singlePicture.id, tags, savePictureType, installation_number, temporary_id, type, this.state.pictureType);
        const {
            search, normal, threeSixty, no_number_boolean,
            temporary_id_boolean, installation_number_boolean, for_future_information,
            intervention, quotation, startDate, endDate
        } = this.props.globalSearchValues;
        this.props.handleGlobalSearchAndFilter(search, normal, threeSixty, no_number_boolean,
            temporary_id_boolean, installation_number_boolean, for_future_information,
            intervention, quotation, startDate, endDate);
    };

    handleSavePreviewFor360 = async (id: number, x: number, y: number, z: number) => {
        this.props.updateCoordinates(id, x, y, z);
    };

    onStopPropagtion = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
    };

    stepTwo = (pictureType: string,
               installation_number: string,
               installation_number_name: string,
               temporary_id: string,
               temporary_id_name: string) => {
        this.setState({
            savePictureType: pictureType,
            installation_number,
            installation_number_name,
            temporary_id,
            temporary_id_name,
        });
        this.props.history.push({
            pathname: '/search-in-gallery/step2',
        })
    };

    stepThree = (type: string) => {
        this.setState({
            type
        });
        this.props.history.push({
            pathname: '/search-in-gallery/step3',
        })
    };

	openInfo = ( picture: any ) => {
		this.setState({singlePicture: picture });
		this.props.openInfoHandler();
	};

	onCloseDelete = () => {
		this.setState({
			openDelete: false
		})
	};

	onOpenDelete = () => {
		this.props.openInfoHandler();
		this.setState({
			openDelete: true
		})
	};

    async componentWillUnmount() {
        await this.props.clearPictures();
        await this.props.clearClients();
        await this.props.clearSearchValue();
    }


    render() {
        const { device } = this.props;
        const openGlobalFilter = this.props.openRightSidebar ? "show" : " ";
		const showInfoBar = this.props.openInfoBar ? "show" : " ";

        return (
            (!this.state.onceLoaded)
                ? (<AquaSecLoader/>)
                : (<>
                    {/*right infoBar*/}
                    <aside onMouseDown={this.onStopPropagtion} className={"sidebar-right " + showInfoBar}>
                        <div className="sidebar-right-inner">
                            <ImageInfo
                                device={device}
                                onCloseSideBar={this.props.openInfoHandler}
                                handleEdit={this.handleEdit}
                                handleDeleteSelected={this.onOpenDelete}
                                picture={this.state.singlePicture}
                                userRole={this.props.session.role}
                                userId={this.props.session.pk}
                            />
                        </div>
                    </aside>
                    {/*
					//@ts-ignore*/}
                    <div className="modal modal-sm" ref={refShowImages}>
                        <Modal
                            open={this.state.openDelete}
                            onClose={this.onCloseDelete}
                            center
                            container={refShowImages.current}
                        >
                            <div>
                                <p className="font-md pt-40">{DELETE_IMAGE}</p>
                                <div className="flex justify-end mt-50">
                                    <button className="btn outline text mr-15"
                                            onClick={this.onCloseDelete}>Cancel
                                    </button>
                                    <button className="btn filled warning ripple" onClick={() => {
                                        this.handleDeleteSelected(this.state.singlePicture.id);
                                        this.onCloseDelete()
                                    }}>
                                        <span>Delete</span>
                                    </button>
                                </div>
                            </div>
                        </Modal>
                    </div>
                    {/*right infoBar*/}

                    {/*right sidebar*/}
                    <aside onMouseDown={this.onStopPropagtion} className={"sidebar-right " + openGlobalFilter}>
                        <div className="sidebar-right-inner">
                            <GlobalFilter
                                clearValues={this.props.clearGlobalSearch}
                                showReset={true}
                                isOpen={this.state.openGlobalFilter}
                                initialValues={this.props.globalSearchValues}
                                onSubmit={this.props.handleGlobalSearchAndFilter}
                                openGlobalFilter={this.props.openGlobalFilter}
                                bodyOverlay={this.props.bodyOverlay}
                            />
                        </div>
                    </aside>
                    {/*right sidebar*/}
                    <div className="page-wrapper">
                        <div className="container">
                            <Route exact path={ROUTES.SEARCH_IN_GALLERY_START} render={() => <GlobalGallery
                                device={this.props.device}
                                optionsCount={this.props.count}
                                handleSavePreviewFor360={this.handleSavePreviewFor360}
                                handleEdit={this.handleEdit}
                                bodyOverlay={this.props.bodyOverlay}
                                searchValue={this.state.searchParam}
                                setSearchValue={this.setSearchValue}
                                redirectToGallery={this.redirectToGallery}
                                loaded={this.props.loading}
                                nextValue={this.props.nextValue}
                                handleDeleteSelected={this.handleDeleteSelected}
                                userRole={this.props.session.role}
                                userId={this.props.session.pk}
                                pictures={this.props.pictures}
                                onRightSidebarOpen={this.props.openGlobalFilter}
                                onInfoOpen={this.openInfo}
                                handleLoadMore={this.handleLoadMore}
                            />}/>
                            <Route path='/search-in-gallery/step1' render={() => <EditPicturesStep1Form
                                nextInstallation={this.props.clients.installation_number_paginated.next}
                                nextTemporary={this.props.clients.temporary_id_paginated.next}
                                loadOptionsInstallation={(search: string) => this.props.loadOptions(search, 'installation_number')}
                                loadOptionsTemporary={(search: string) => this.props.loadOptions(search, 'temporary_id')}
                                getMore={this.getMoreOptions}
                                installation_number_options={this.state.installation_number_options}
                                temporary_id_options={this.state.temporary_id_options}
                                previousStep={() => this.props.history.goBack()}
                                nextStep={this.stepTwo}
                                initialValues={{
                                    picture_type: this.state.pictureType,
                                    temporary_id: {value: this.state.temporary_id, label: this.state.temporary_id_name},
                                    installation_number: {
                                        value: this.state.installation_number,
                                        label: this.state.installation_number_name
                                    }
                                }}
                            />}/>
                            <Route exact path='/search-in-gallery/step2' render={() => <EditPicturesStep2Form
                                previousStep={() => ((this.state.pictureType === 'no_number') ? this.props.history.goBack() : this.props.history.go(-2))}
                                nextStep={this.stepThree}
                                initialValues={{type: this.state.singlePicture.type}}
                            />}/>
                            <Route exact path='/search-in-gallery/step3' render={() => <EditPicturesStep3Form
                                handleEditClick={() => this.props.history.goBack()}
                                handleSave={this.saveEditedPicture}
                                initialValues={{
                                    tags: this.state.singlePicture.tags.map((tag: any) => tag.name),
                                    tagValue: ''
                                }}
                                showDefault={this.state.showDefault}
                                toggleShowDefault={this.toggleShowDefault}
                                defaultTags={this.props.defaultTags.results}
                                next={this.props.defaultTags.next}
                                loadMoreTags={this.getMoreTags}
                            />}/>
                        </div>
                    </div>
                </>)
        );
    }

}

const mapStateToProps = (state: ApplicationState): PropsFromState => {
    return {
        errorMessage: state.errorMessage,
        session: state.session,
        searchValue: state.shared.searchValue,
        installationNumberClients: state.clients.installation_number_with_pictures,
        temporaryIdClients: state.clients.temporary_id_with_pictures,
        pictures: state.pictures,
        nextValue: state.pictures.next,
        loading: state.shared.loading,
        clients: state.clients,
        globalSearchValues: state.shared.globalSearchValues,
        device: state.shared.device,
        defaultTags: state.tags
    }
};

const mapDispatchToProps: PropsFromDispatch = {
    verifyToken,
    getUser,
    getClientsWithPictures,
    deleteSelected,
    clearPictures,
    LoadMore,
    setSearchValue,
    editPicture,
    getClientsBySearchParam,
    updateCoordinates,
    clearClients,
    clearSearchValue,
    getFolderNameByID,
    getTemporaryIDs,
    getInstallationNumbers,
    loadOptions,
    getMoreOptions,
    clearGlobalSearch,
    getMoreTags,
    getDefaultTags
};

export default compose<GalleryProps, {}>(
    withRouter,
    connect<PropsFromState, PropsFromDispatch, {}, ApplicationState>(
        mapStateToProps,
        mapDispatchToProps,
    )
)(Search_In_Gallery);
