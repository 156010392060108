export const beautifyRole = (role: string): string => {
	switch(role) {
		case 'back_officer':
			return 'Back Officer';
		case 'worker':
			return 'Worker';
		case 'admin':
			return 'Admin';
		default:
			return '';
	}
};

export const beautifyPictureType = (type: string): string => {
	switch(type) {
		case 'quotation':
			return 'Quotation';
		case 'for_future_information':
			return 'For Future Information';
		case 'intervention':
			return 'Intervention';
		default:
			return '';
	}
};

export const beautifyTime = (time: string): string => {
	const currentDate = new Date();
	const deletionDate = new Date(time);
	const difference = deletionDate.getTime() - currentDate.getTime();
	const days = Math.floor(difference / 86400000);
	if(days > 0){
		if(days === 1) {
			return days + ' day left';
		}
		return days + ' days left';
	}
	const hours = Math.floor(difference/ 3600000);
	if(hours > 0) {
		if(hours === 1) {
			return hours + ' hour left';
		}
		return hours + ' hours left';
	}
	const minutes = Math.ceil(difference / 60000);
		if(minutes !== 1) {
			return minutes + ' minutes left';
		}
	return minutes + ' minute left';
};

