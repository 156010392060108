import React, { Component } from "react";
import { UploadedFiles } from "../../pages/UploadImages";

interface ImageSingleProps {
  index: number;
  file: UploadedFiles;
  selectable: boolean;
  device: string;
  onOpenNormalModal(index: number): void;
  onOpen360Modal(index: number): void;
  handleSelectPicture(index: number): void;
  checkPictureDegree(file: UploadedFiles): Promise<boolean>;
}

export class ImageSingle extends Component<ImageSingleProps, any> {

  render() {
    const { index, file, onOpenNormalModal, onOpen360Modal } = this.props;

    return (
      <div
        key={index + `${Math.round(Math.random() * 10)}`}
        onClick={
            async () => (this.props.selectable ? this.props.handleSelectPicture(index):
                (file.is_normal !== null || await this.props.checkPictureDegree(file))
                    ? onOpenNormalModal(index)
                    : (this.props.device === 'mobile' && onOpen360Modal(index)))}
        className={'image-list-item' + (file.selected ? ' selected-item' : '')}
        style={{backgroundImage: `url(${file.blob_url})`}}
      />
    )
  }
}