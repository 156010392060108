import {SharedActionTypes} from "./types";
import {Dispatch} from "redux";

export interface SetSearchValue {
	type: SharedActionTypes.SEARCH_VALUE,
	data: string,
}

export const setSearchValue = (val: string) => (dispatch: Dispatch<any>)=> {
	return dispatch({ type: SharedActionTypes.SEARCH_VALUE, data: val });
};

export const clearSearchValue = () => (dispatch: Dispatch<any>) => {
	return dispatch({type: SharedActionTypes.CLEAR_VALUE})
};

export const loading = (isLoading: boolean) => (dispatch: Dispatch<any>) => {
	return dispatch({type: SharedActionTypes.LOADING, data: isLoading})
};

export const changeDevice = () => (dispatch: Dispatch<any>) => {
	return dispatch({type: SharedActionTypes.CHANGE_DEVICE})
};

export const setGlobalSearchValues = (search: string,
                                      normal: boolean,
                                      threeSixty: boolean,
                                      no_number_boolean: boolean,
                                      temporary_id_boolean: boolean,
                                      installation_number_boolean: boolean,
                                      for_future_information: boolean,
                                      quotation: boolean,
                                      intervention: boolean,
                                      startDate: Date,
                                      endDate: Date) => (dispatch: Dispatch<any>) => {
	dispatch({type: SharedActionTypes.SET_GLOBAL_SEARCH_VALUES, data: {search,
			normal,
			threeSixty,
			no_number_boolean,
			temporary_id_boolean,
			installation_number_boolean,
			for_future_information,
			quotation,
			intervention,
			startDate,
			endDate}})
};

export const setCount = (count: number) => (dispatch: Dispatch<any>) => {
	return dispatch({type: SharedActionTypes.SET_COUNT, data: count})
};

export const clearGlobalSearch = () => (dispatch: Dispatch<any>) => {
	return dispatch({type: SharedActionTypes.CLEAR_GLOBAL_SEARCH})
};
