export interface User {
  id: number,
  last_login: string,
  created: string,
  modified: string,
  is_deleted: boolean,
  email: string,
  role: string,
  first_name: string,
  last_name: string,
  is_active: boolean,
  can_resend: boolean,
}

export enum UsersActionTypes {
  FETCH_USERS = '@@users/FETCH_USERS',
  UPDATE_EDITED_USER = '@@users/UPDATE_EDITED_USER'
} 
