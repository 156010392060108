import React from 'react';
import {FormValues, FilterForm, FormInitialValues} from '../components/Gallery/FilterForm';
import {compose} from 'recompose';
import {RouteComponentProps, withRouter} from "react-router";
import {connect} from 'react-redux'
import {ConnectedReduxProps, ApplicationState} from '../../../store';
import {SessionState} from "../../../store/session/types";
import {getUser} from "../../../store/session/actions";
import {verifyToken} from "../../../store/users/actions";
import {
	getClientsWithPictures,
	getFolderNameByID,
	getInstallationNumbers,
	getTemporaryIDs
} from "../../../store/clients/actions";
import {
	clearPictures,
	deleteRecDeletedPictures, deleteSelected, getPicturesBy,
	getRecDeletedPicturesBy,
	LoadMore,
	restorePictures,
	updateCoordinates
} from "../../../store/pictures/actions";
import RecentlyDeletedPictures from "../components/Recently_Deleted/RecentlyDeletedPictures";
import AquaSecLoader from "../components/Loader";
import {setSearchValue} from "../../../store/shared/actions";
import ImageInfo from "../components/Gallery/ImageInfo";
import {toast} from "react-toastify";
import {SINGLE_PICTURE_DELETED} from "../../../helpers/toastMessages";
import {ClientsState} from "../../../store/clients/types";
import {getDefaultTags} from "../../../store/tags/actions";


interface PropsFromState {
    errorMessage: any,
    session: SessionState,
    searchValue: string,
    installationNumberClients: [],
    temporaryIdClients: [],
    pictures: object[],
    picturesNext: string,
    loading: boolean
    device: string,
	clients: ClientsState,
}

interface PropsFromDispatch {
    verifyToken(token: string): void

    getUser(id: number): void

	deleteSelected(id: number): void

	clearPictures(): void

	getPicturesBy(queryParams: string): void

	restorePictures(picturesIdArray: Array<number>): void

    getRecDeletedPicturesBy(queryParams: string, searchParams: string, page: number, page_size: number): void

    LoadMore(queryParams: string): void

    getClientsWithPictures(): void

    updateCoordinates(id: number, x: number, y: number, z: number): void

    deleteRecDeletedPictures(picturesArray: Array<number>): void

    setSearchValue(searchParam: string): void,

	getTemporaryIDs(page?: number, search?: string): void,

	getInstallationNumbers(page?: number, search?: string): void,

	getDefaultTags(page: number,search: string,limit?: number): any,

}

interface GalleryProps extends RouteComponentProps<any>, PropsFromState, PropsFromDispatch, ConnectedReduxProps {
    openRightSidebar: boolean,
	openInfoBar: string,


    openRecentlyDeletedFilter(): void

    bodyOverlay(): void,

	openInfoHandler(): void,

}

interface GalleryState {
    initialValues: FormValues,
    folders: [],
    chosenFolder: {
        id: number
        name: string,
        index: string
    },
    onceLoaded: boolean
    filterOptionsCount: number
    pageSize: number,
	singlePicture: any,
	searchParam: string,
	filterParams: string,
	folderName: string,
	installation_number_options: { value: string, label: string }[],
	temporary_id_options: { value: string, label: string }[],
	editedPictureType: string,
	editedFolderID: number,
	pictureType: string,
	openDelete: boolean,
}

class RecentlyDeleted extends React.Component<GalleryProps, GalleryState> {

    state: GalleryState = {
        initialValues: FormInitialValues,
        folders: [],
        chosenFolder: {id: NaN, name: '', index: ''},
        onceLoaded: false,
        filterOptionsCount: 0,
        pageSize: 21,
		singlePicture: {tags: [], type: ''},
		searchParam: '',
		filterParams: '',
		folderName: '',
		installation_number_options: [],
		temporary_id_options: [],
		editedPictureType: '',
		editedFolderID: NaN,
		pictureType: 'no_umber',
		openDelete: false

	};

    async componentDidMount() {
        if (this.props.device === 'pc') {
            await this.setState({
                pageSize: 40,
            })
        }
        await this.props.getRecDeletedPicturesBy('', '', 1, this.state.pageSize);
        this.setState({
            onceLoaded: true
        })
    }

    onStopPropagtion = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
    };

    filterFormSubmit = (normal: boolean,
                        threeSixty: boolean,
                        for_future_information: boolean,
                        quotation: boolean,
                        intervention: boolean,
                        startDate: Date,
                        endDate: Date) => {
        let filterQuery = '';
        let optionsCount = 0;
        if (normal) {
            ++optionsCount;
            filterQuery += 'is_normal=True&';
        }
        if (threeSixty) {
            ++optionsCount;
            filterQuery += 'is_normal=False&';
        }
        if (normal && threeSixty) {
            optionsCount = 2;
            filterQuery = '';
        }
        if (for_future_information) {
            ++optionsCount;
            filterQuery += 'for_future_information_boolean=True&';
        }
        if (quotation) {
            ++optionsCount;
            filterQuery += 'quotation_boolean=True&';
        }
        if (intervention) {
            ++optionsCount;
            filterQuery += 'intervention_boolean=True&';
        }
        if (startDate) {
            ++optionsCount;
            filterQuery += 'from_date=' + startDate.toLocaleDateString() + '&';
        }
        if (endDate) {
            ++optionsCount;
            filterQuery += 'to_date=' + endDate.toLocaleDateString() + '&';
        }
        this.setState({
            filterOptionsCount: optionsCount
        });
        this.props.getRecDeletedPicturesBy(filterQuery, '', 1, this.state.pageSize)

    };

	handleDeleteSelected = async (id: number) => {
		await this.props.deleteSelected(id);
		await this.props.clearPictures();
		this.props.openInfoHandler();
		if (this.props.match.params.type === 'no_number') {
			const queryParams = `is_deleted=False&no_number=True&search=${this.state.searchParam}&${this.state.filterParams}&page=1&page_size=${this.state.pageSize}`;
			await this.props.getPicturesBy(queryParams)
		} else {
			const queryParams = `is_deleted=False&${this.props.match.params.type}=${this.state.folderName}&search=${this.state.searchParam}&${this.state.filterParams}&page=1&page_size=${this.state.pageSize}`;
			await this.props.getPicturesBy(queryParams)
		}
		toast.dismiss();
		toast(() =>
			<div className="toast-style success">
				<div className="left-side">
							<span className="icon-success icon">
								<span className="path1"/>
								<span className="path2"/>
							</span>
				</div>
				<div className="right-side">
					<p>{SINGLE_PICTURE_DELETED}</p>
				</div>
			</div>
		)
	};

	handleEdit = async (picture: any) => {
		await this.props.getTemporaryIDs(1,'');
		await this.props.getInstallationNumbers(1,'');
		await this.props.getDefaultTags(1,'', 30);
		this.props.openInfoHandler();
		this.setState({
			installation_number_options: this.props.clients.installation_number_paginated.results.map((client: any) => ({
				value: client.id,
				label: client.name
			})),
			temporary_id_options: this.props.clients.temporary_id_paginated.results.map((client: any) => ({
				value: client.id,
				label: client.name
			})),
			singlePicture: picture,
			editedPictureType: this.props.match.params.type,
			editedFolderID: this.props.match.params.name,
			pictureType: picture.no_number ? 'no_number' : ''
		});
		this.props.history.push(`/gallery/edit/step/1`);
	};

	openInfo = ( picture: any ) => {
		this.setState({singlePicture: picture });
		this.props.openInfoHandler();
	};



	clearFilterValues = () => {
        this.setState({initialValues: FormInitialValues,})
    };

	onOpenDelete = () => {
		this.props.openInfoHandler();
		this.setState({
			openDelete: true
		})
	};


	render() {
        const {picturesNext, openRightSidebar, match, openRecentlyDeletedFilter, bodyOverlay, LoadMore, device} = this.props;
        const {initialValues, filterOptionsCount} = this.state;
        const showClassName = openRightSidebar ? "show" : " ";
		const showInfoBar = this.props.openInfoBar ? "show" : " ";
        const type = match.params.type;

        return (
            (!this.state.onceLoaded)
                ? (<AquaSecLoader/>)
                : (<>
                    {/*right sidebar*/}
                    <aside onMouseDown={this.onStopPropagtion} className={"sidebar-right " + showClassName}>
                        <div className="sidebar-right-inner">
                            <FilterForm
                                isOpen={openRightSidebar}
                                onRightSidebarOpen={openRecentlyDeletedFilter}
                                bodyOverlay={bodyOverlay}
                                onSubmit={this.filterFormSubmit}
                                initialValues={initialValues}
                            />
                        </div>
                    </aside>
                    {/*right sidebar*/}

                    {/*right infoBar*/}
                    <aside onMouseDown={this.onStopPropagtion} className={"sidebar-right " + showInfoBar}>
                        <div className="sidebar-right-inner">
                            <ImageInfo
                                device={device}
                                onCloseSideBar={this.props.openInfoHandler}
                                handleEdit={this.handleEdit}
                                handleDeleteSelected={this.onOpenDelete}
                                picture={this.state.singlePicture}
                                userRole={this.props.session.role}
                                userId={this.props.session.pk}
								recentlyDeleted={true}
                            />
                        </div>
                    </aside>

                    <div className="page-wrapper">
                        <div className="container">
                            <RecentlyDeletedPictures
                                {...this.props}
								onInfoOpen={this.openInfo}
                                device={this.props.device}
                                filterOptionsCount={filterOptionsCount}
                                pictureType={type}
                                onRightSidebarOpen={openRecentlyDeletedFilter}
                                bodyOverlay={bodyOverlay}
                                picturesNext={picturesNext}
                                LoadMore={LoadMore}
                                clearFilterValues={this.clearFilterValues}
                                pageSize={this.state.pageSize}
                            />
                        </div>
                    </div>
                </>)
        );
    }

}

const mapStateToProps = (state: ApplicationState): PropsFromState => {
    return {
        errorMessage: state.errorMessage,
        session: state.session,
        searchValue: state.shared.searchValue,
        installationNumberClients: state.clients.installation_number_with_pictures,
        temporaryIdClients: state.clients.temporary_id_with_pictures,
        pictures: state.pictures.results,
        picturesNext: state.pictures.next,
        loading: state.shared.loading,
        device: state.shared.device,
		clients: state.clients,
    }
};

const mapDispatchToProps: PropsFromDispatch = {
    verifyToken,
    getUser,
    getClientsWithPictures,
    getRecDeletedPicturesBy,
	getPicturesBy,

    LoadMore,
    deleteRecDeletedPictures,
    restorePictures,
    updateCoordinates,
    setSearchValue,
	getTemporaryIDs,
	getInstallationNumbers,
	clearPictures,
	getDefaultTags,
	deleteSelected,
};

export default compose<GalleryProps, {}>(
    withRouter,
    connect<PropsFromState, PropsFromDispatch, {}, ApplicationState>(
        mapStateToProps,
        mapDispatchToProps,
    )
)(RecentlyDeleted);
