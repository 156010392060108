export interface ClientsState {
	installation_number: [],
	temporary_id_paginated: {
		count: number,
		next: any,
		previous: any,
		results: any[]
	},
	temporary_id: [],
	installation_number_with_pictures: [],
	temporary_id_with_pictures: [],
	installation_number_paginated: {
		count: number,
		next: any,
		previous: any,
		results: any[]
	}
}

export interface Client {
	id: number,
	created: string,
	modified: string,
	name: string,
	type: string
	index: string
}

export enum ClientsActionTypes {
	GET_INSTALLATION_CLIENTS = '@@clients/GET_INSTALLATION_CLIENTS',
	GET_TEMPORARY_CLIENTS = '@@clients/GET_TEMPORARY_CLIENTS',
	GET_INSTALLATION_WITH_PICTURES = '@@clients/GET_INSTALLATION_WITH_PICTURES',
	GET_TEMPORARY_WITH_PICTURES = '@@clients/GET_TEMPORARY_WITH_PICTURES',
	CLEAR_CLIENTS = '@@clients/CLEAR_CLIENTS',
	GET_TEMPORARY_CLIENTS_PAGINATED = '@@clients/GET_TEMPORARY_CLIENTS_PAGINATED',
	GET_INSTALLATION_CLIENTS_PAGINATED = '@@clients/GET_INSTALLATION_CLIENTS_PAGINATED',
	CLEAR_INSTALLATION_NUMBERS = '@@clients/CLEAR_INSTALLATION_NUMBERS',
	GET_MORE_TEMPORARY = '@@clients/GET_MORE_TEMPORARY',
}