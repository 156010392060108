import React from 'react';
import {compose} from 'recompose';
import {RouteComponentProps, withRouter} from "react-router";
import {connect} from 'react-redux'
import {ConnectedReduxProps, ApplicationState} from '../../../store';
import {SessionState} from "../../../store/session/types";
import {toast} from "react-toastify";
import {getUser} from "../../../store/session/actions";
import {verifyToken} from "../../../store/users/actions";
import {
	addClient,
	changeTemporaryToInstallation,
	clearClients, clearInstallationNumbers,
	deleteClient,
	editClient,
	getClientsWithPictures,
	getInstallationNumbers, getMoreOptions,
	getTemporaryIDs, loadOptions,
	sendTokenToEmail,
	temporaryIdGotPictures
} from "../../../store/clients/actions";
import {deleteSelected, getPicturesBy, clearPictures, LoadMore} from "../../../store/pictures/actions";
import {clearSearchValue, setSearchValue} from "../../../store/shared/actions";
import {Client, ClientsState} from "../../../store/clients/types";
import ClientsTable from "../components/Clients/ClientsTable";
import Modal from "react-responsive-modal";
import {ClientFormInitialValues, ClientFormValues, ClientsForm} from "../components/Clients/ClientsForm";
import AquaSecLoader from "../components/Loader";
import {
	TEMPORARY_ID_CREATED,
	TEMPORARY_ID_DELETED,
	TEMPORARY_ID_EDITED, TEMPORARY_ID_EXISTS,
	TEMPORARY_ID_FAILED, TOKEN_EXPIRED_MESSAGE, WRONG_DELETION_TOKEN
} from "../../../helpers/toastMessages";
import {TEMPORARY_ID_CONFIRMATION_CODE, TEMPORARY_ID_DELETION_WARNING} from "../../../helpers/modalMessages";


const refClientsForm = React.createRef();
const refClientsDelete = React.createRef();

interface PropsFromState {
	errorMessage: any,
	session: SessionState,
	searchValue: string,
	pictures: object[],
	nextValue: string
	loading: boolean
	picturesCount: number,
	clients: ClientsState,
}

interface PropsFromDispatch {
	temporaryIdGotPictures(name: string): any

	setSearchValue(searchParam: string): void

	verifyToken(token: string): void

	getUser(id: number): void

	getPicturesBy(queryParams: string): void

	getClientsWithPictures(): void

	deleteSelected(id: number): void

	clearPictures(): void

	LoadMore(queryParams: string): void

	addClient(name: string, index: string): void

	editClient(name: string, index: string, id: number): void

	changeTemporaryToInstallation(tem_id: number, ins_id: string): void

	deleteClient(token: string): void

	sendTokenToEmail(id: number): void

	getTemporaryIDs(page: number, searchValue: string): void

	getInstallationNumbers(page?: number, searchValue?: string): void

	clearClients(): void

	clearSearchValue(): void

	getMoreOptions(next: string,type: string): void

	clearInstallationNumbers(): void

	loadOptions(search: string,type: string): any
}

interface GalleryProps extends RouteComponentProps<any>, PropsFromState, PropsFromDispatch, ConnectedReduxProps {

}

interface GalleryState {
	openLeftSidebar: boolean,
	openRightSidebar: boolean,
	initialValues: ClientFormValues,
	searchParam: string,
	openGlobalFilter: boolean
	filterParams: string
	searchTypes: number,
	type: string,
	installation_number_options: { value: string, label: string }[],
	temporary_id: number,
	installation_number: string,
	installation_number_name: string,
	temporary_id_name: string,
	pictureType: string
	disabled: boolean,
	open: boolean
	openForm: boolean
	title: string
	deleteClicked: boolean
	deletionToken: string
	page: number
	onceLoaded: boolean,
}

class Clients extends React.Component<GalleryProps, GalleryState> {

	state: GalleryState = {
		openLeftSidebar: false,
		openRightSidebar: false,
		openGlobalFilter: false,
		initialValues: ClientFormInitialValues,
		searchParam: '',
		filterParams: '',
		searchTypes: 0,
		type: '',
		installation_number_options: [],
		temporary_id: NaN,
		installation_number: '',
		installation_number_name: '',
		temporary_id_name: '',
		pictureType: 'no_number',
		disabled: true,
		open: false,
		openForm: false,
		title: '',
		deleteClicked: false,
		deletionToken: '',
		page: 1,
		onceLoaded: false,
	};

	async componentDidMount() {
		try {
			await this.props.clearSearchValue();
			await this.props.getTemporaryIDs(1, this.props.searchValue);
			this.setState({
				onceLoaded: true
			});
			if (Object.keys(this.props.errorMessage).length !== 0) {
			}
		} catch (e) {
		}
	}

	async componentWillUnmount() {
		await this.props.clearClients();
	}

	handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
		this.setState({
			deletionToken: e.currentTarget.value,
		})
	};

	handlePageChange = async (page: number) => {
		await this.props.getTemporaryIDs(page, this.props.searchValue);
		this.setState({
			page
		})
	};

	setSearchValue = async (search: string) => {
		await this.props.getTemporaryIDs(1, search);
		await this.props.setSearchValue(search);
		this.setState({
			page: 1,
		})
	};

	onOpenModal = (client: Client) => {
		this.setState({
			temporary_id: client.id,
			open: true,
		});
	};

	onCloseFormModal = () => {
		this.setState({
			installation_number_options: [],
			openForm: false,
			disabled: true,
		});
	};

	onCloseModal = () => {
		this.setState({
			open: false,
			deleteClicked: false,
			deletionToken: ''
		});
	};

	handleSubmitForm = async (name: string, index: string) => {
		await this.props.addClient(name, index);
		if (Object.keys(this.props.errorMessage).length === 0) {
			toast.dismiss();
			toast(() =>
				<div className="toast-style success">
					<div className="left-side">
							<span className="icon-success icon">
								<span className="path1"/>
								<span className="path2"/>
							</span>
					</div>
					<div className="right-side">
						<p>{TEMPORARY_ID_CREATED}</p>
					</div>
				</div>
			);
			await this.props.clearSearchValue();
			this.setState({
				searchParam: ''
			});
			await this.props.getTemporaryIDs(this.state.page, this.props.searchValue);
		} else {
			toast.dismiss();
			toast(() =>
				<div className="toast-style error">
					<div className="left-side">
							<span className="icon-error icon">
								<span className="path1"/>
								<span className="path2"/>
							</span>
					</div>
					<div className="right-side">
						<p>{TEMPORARY_ID_FAILED}</p>
					</div>
				</div>
			);
		}
		this.onCloseFormModal();
	};

	handleEditForm = async (name: string, index: string, installation_number: { value: string, label: string }) => {
		if (installation_number.value !== '') {
			await this.props.changeTemporaryToInstallation(this.state.temporary_id, installation_number.value);
		} else {
			await this.props.editClient(name, index, this.state.temporary_id);
		}
		if (Object.keys(this.props.errorMessage).length === 0) {
			toast.dismiss();
			toast(() =>
				<div className="toast-style success">
					<div className="left-side">
							<span className="icon-success icon">
								<span className="path1"/>
								<span className="path2"/>
							</span>
					</div>
					<div className="right-side">
						<p>{TEMPORARY_ID_EDITED}</p>
					</div>
				</div>
			);
			await this.props.getTemporaryIDs(this.state.page, this.props.searchValue);
		} else {
			toast.dismiss();
			toast(() =>
				<div className="toast-style error">
					<div className="left-side">
							<span className="icon-error icon">
								<span className="path1"/>
								<span className="path2"/>
							</span>
					</div>
					<div className="right-side">
						<p>{TEMPORARY_ID_EXISTS}</p>
					</div>
				</div>
			);
		}
		this.onCloseFormModal();
	};

	getMoreOptions = async (next: string) => {
		if(next) {
			await this.props.getMoreOptions(next, 'installation_number');
			this.setState({
				installation_number_options: this.props.clients.installation_number_paginated.results.map((client: any) => ({
					value: client.id,
					label: client.name
				})),
			})
		}
	};

	sendEmail = async (id: number) => {
		await this.props.sendTokenToEmail(id);
		this.setState({
			deleteClicked: true
		});
	};

	handleDeleteTemporaryID = async (token: string) => {
		await this.props.deleteClient(token);
		if (Object.keys(this.props.errorMessage).length === 0) {
			await this.props.getTemporaryIDs(this.state.page, this.props.searchValue);
			this.onCloseModal();
			toast.dismiss();
			toast(() =>
				<div className="toast-style success">
					<div className="left-side">
							<span className="icon-success icon">
								<span className="path1"/>
								<span className="path2"/>
							</span>
					</div>
					<div className="right-side">
						<p>{TEMPORARY_ID_DELETED}</p>
					</div>
				</div>
			);
		} else if(this.props.errorMessage.message === "Invalid slug") {
			toast.dismiss();
			toast(() =>
				<div className="toast-style error">
					<div className="left-side">
							<span className="icon-error icon">
								<span className="path1"/>
								<span className="path2"/>
							</span>
					</div>
					<div className="right-side">
						<p>{WRONG_DELETION_TOKEN}</p>
					</div>
				</div>
			);
		} else {
			toast.dismiss();
			toast(() =>
				<div className="toast-style error">
					<div className="left-side">
							<span className="icon-error icon">
								<span className="path1"/>
								<span className="path2"/>
							</span>
					</div>
					<div className="right-side">
						<p>{TOKEN_EXPIRED_MESSAGE}</p>
					</div>
				</div>
			);
		}
	};

	openAddNewClient = () => {
		this.setState(() => ({
			title: 'Add Temporary ID',
			initialValues: ClientFormInitialValues,
			openForm: true,
			disabled: true,
		}));
	};

	openEditNewClient = async (client: Client) => {
		const hasPictures = await this.props.temporaryIdGotPictures(client.name);
		if(hasPictures && this.props.session.role === 'admin') {
			await this.props.getInstallationNumbers(1);
		} else {
			await this.props.clearInstallationNumbers();
		}
		await this.setState(() => ({
			initialValues: {
				name: client.name,
				index: client.index,
				installation_number: {value: '', label: ''}
			},
			title: 'Edit Temporary ID',
			temporary_id: client.id,
			openForm: true,
			disabled: false,
			installation_number_options: this.props.clients.installation_number_paginated.results.map((client: any) => ({
				value: client.id,
				label: client.name
			})),
		}));
	};


	render() {
		return (
			(!this.state.onceLoaded)
				? (<AquaSecLoader />)
				: (<>
					{/*header*/}
					{/*Table*/}
					<ClientsTable
						searchParam={this.props.searchValue}
						setSearchValue={this.setSearchValue}
						page={this.state.page}
						role={this.props.session.role}
						clients={this.props.clients.temporary_id_paginated.results}
						total={this.props.clients.temporary_id_paginated.count}
						openAddNewClient={this.openAddNewClient}
						onOpenModal={this.onOpenModal}
						openEdit={this.openEditNewClient}
						handlePageChange={this.handlePageChange}
					/>
					{/*Table*/}
					{/*Delete Modal*/}
					{/*
				//@ts-ignore*/}
					<div className="modal modal-md" ref={refClientsDelete}>
						<Modal
							open={this.state.open}
							onClose={this.onCloseModal}
							center
							container={refClientsDelete.current}
						>{
							this.state.deleteClicked
								?
								(<div>
									<p className="font-md pb-30">{TEMPORARY_ID_CONFIRMATION_CODE}</p>
									<div className="textfield">
										<input
											type="text"
											placeholder="Confirmation Code"
											value={this.state.deletionToken}
											onChange={this.handleInputChange}
										/>
									</div>
									<div className="flex justify-end mt-50">
										<button className="btn filled primary ripple"
										        onClick={() => this.handleDeleteTemporaryID(this.state.deletionToken)}>
											<span>Confirm</span>
										</button>
									</div>
								</div>)
								:
								(<div>
									<p className="font-md">{TEMPORARY_ID_DELETION_WARNING}</p>
									<div className="flex justify-end mt-50">
										<button className="btn outline text mr-15" onClick={this.onCloseModal}>No</button>
										<button className="btn filled warning ripple" onClick={() => {
											this.sendEmail(this.state.temporary_id)
										}}>
											<span>Yes</span>
										</button>
									</div>
								</div>)
						}
						</Modal>
					</div>
					{/*Delete Modal*/}
					{/*Add Client*/}
					{/*
					//@ts-ignore*/}
					<div className="modal modal-md" ref={refClientsForm}>
						<Modal
							open={this.state.openForm}
							onClose={this.onCloseFormModal}
							center
							container={refClientsForm.current}
						>
							<ClientsForm
								loadOptions={(search) => this.props.loadOptions(search,'installation_number')}
								next={this.props.clients.installation_number_paginated.next}
								getMore={this.getMoreOptions}
								title={this.state.title}
								role={this.props.session.role}
								onSubmit={this.state.title === 'Add Temporary ID' ? this.handleSubmitForm : this.handleEditForm}
								initialValues={this.state.initialValues}
								onCloseForm={this.onCloseFormModal}
								isOpen={this.state.openForm}
								disabled={this.state.disabled}
								installation_number_options={this.state.installation_number_options}
							/>
						</Modal>
					</div>
					{/*Add Client*/}
				</>)
		);
	}

}

const mapStateToProps = (state: ApplicationState): PropsFromState => {
	return {
		errorMessage: state.errorMessage,
		session: state.session,
		searchValue: state.shared.searchValue,
		pictures: state.pictures.results,
		picturesCount: state.pictures.count,
		nextValue: state.pictures.next,
		loading: state.shared.loading,
		clients: state.clients
	}
};

const mapDispatchToProps: PropsFromDispatch = {
	verifyToken,
	getUser,
	getClientsWithPictures,
	getPicturesBy,
	deleteSelected,
	clearPictures,
	LoadMore,
	setSearchValue,
	addClient,
	editClient,
	changeTemporaryToInstallation,
	deleteClient,
	sendTokenToEmail,
	getTemporaryIDs,
	clearClients,
	getInstallationNumbers,
	clearSearchValue,
	temporaryIdGotPictures,
	getMoreOptions,
	clearInstallationNumbers,
	loadOptions
};

export default compose<GalleryProps, {}>(
	withRouter,
	connect<PropsFromState, PropsFromDispatch, {}, ApplicationState>(
		mapStateToProps,
		mapDispatchToProps,
	)
)(Clients);
