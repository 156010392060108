
import { createBrowserHistory } from 'history';
const history = createBrowserHistory();


export default function request( url: string, headers?: any ) {
    return fetch(url, headers).then(res => {
        if (res.status === 403) {
            sessionStorage.clear();
            localStorage.clear();
            history.push('/sign-in');
        }
        return res;
    });
}