import { Reducer } from 'redux';
import {TagsActionTypes, TagsState} from './types';

export const TagsInitialState: TagsState = {
		count: 0,
		next: null,
		previous: null,
		results: []
};

const tagsReducer: Reducer<TagsState> = (state: TagsState = TagsInitialState, action) => {
	switch (action.type) {
		case TagsActionTypes.GET_DEFAULT_TAGS:
			return action.data;
		case TagsActionTypes.CLEAR_TAGS:
			return TagsInitialState;
		case TagsActionTypes.LOAD_MORE_TAGS:
			return {
				...action.data,
				results: [...state.results, ...action.data.results],
			};
		default:
			return state;
	}
};

export default tagsReducer;
