import React from 'react';

import {compose} from 'recompose'
import {connect} from 'react-redux';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import {SignInForm} from './components/SignInForm';
import {getUser, logInUser} from '../../../store/session/actions';
import {SessionState} from '../../../store/session/types';
import {ApplicationState, ConnectedReduxProps} from '../../../store';
import {toast} from "react-toastify";
import {randomTokenName} from "../../../App";
import * as ROUTES from "../../../helpers/routes";


interface PropsFromState {
	session: SessionState,
	errorMessage: object,
}

interface PropsFromDispatch {
	logInUser: (email: string, password: string, remember: boolean) => void;

	getUser(): void
}

interface SignInProps extends RouteComponentProps<any>, PropsFromDispatch, PropsFromState, ConnectedReduxProps {

}

interface SignInState {
	onceLoaded: boolean
}

class Signin extends React.Component<SignInProps, SignInState> {

	state = {
		onceLoaded: false
	};

	async componentDidMount() {
		if (localStorage.getItem(randomTokenName) || sessionStorage.getItem(randomTokenName)) {
			await this.props.getUser();
			switch (this.props.session.role) {
				case 'admin':
					this.props.history.push(ROUTES.USERS);
					break;
				case 'user':
					this.props.history.push(ROUTES.UPLOAD);
					break;
				case 'back_officer':
					this.props.history.push(ROUTES.CLIENTS);
					break;
				default:
					break;
			}
			this.setState({
				onceLoaded: true,
			});
		} else {
			this.setState({
				onceLoaded: true,
			});
		}
	}


	handleSubmit = async (email: string, password: string, remember: boolean) => {
		try {
			await this.props.logInUser(email, password, remember);
			if (Object.keys(this.props.errorMessage).length === 0) {
				switch (this.props.session.role) {
					case 'admin':
						this.props.history.push(ROUTES.USERS);
						break;
					case 'worker':
						this.props.history.push(ROUTES.UPLOAD);
						break;
					case 'back_officer':
						this.props.history.push(ROUTES.CLIENTS);
						break;
					default:
						break;
				}
			} else {
				toast.dismiss();
				Object.values(this.props.errorMessage).map(message =>
					toast(() =>
						<div className="toast-style error">
							<div className="left-side">
							<span className="icon-error icon">
								<span className="path1"/>
								<span className="path2"/>
							</span>
							</div>
							<div className="right-side">
								<p>{message}</p>
							</div>
						</div>
					))
			}
		} catch (e) {
		}
	};

	render() {

		return (
			!this.state.onceLoaded
				? (<>
				</>)
				: (<div className="sign-page">
					{/*sign in*/}
					<SignInForm
						onSubmit={this.handleSubmit}
					/>
					{/*end sign in*/}
				</div>)
		);
	}
}

const mapStateToProps = (state: ApplicationState): PropsFromState => {
	return {
		session: state.session,
		errorMessage: state.errorMessage,
	}
};

const mapDispatchToProps: PropsFromDispatch = {
	logInUser,
	getUser
};

export default compose<SignInProps, {}>(
	withRouter,
	connect<PropsFromState, PropsFromDispatch, {}, ApplicationState>(
		mapStateToProps,
		mapDispatchToProps,
	)
)(Signin);
