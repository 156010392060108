import React from 'react';
import SaveImagesBottomBar from "./SaveImagesBottomBar";
import {PicturePreview, UploadedFiles} from "../../pages/UploadImages";
import {connect} from "react-redux";
import {ApplicationState, ConnectedReduxProps} from "../../../../store";
import {checkPictureDegree} from "../../../../store/pictures/actions";
import {AWS, LITTLESIZE} from "../../../../helpers/image-resize-endpoints";


interface PropsFromDispatch {
	checkPictureDegree(file: File): any
}

interface SaveImagesProps extends PropsFromDispatch, ConnectedReduxProps{
	pictures: UploadedFiles[]
	tags: string[]
	type: string
	installation_number: string
	temporary_id: string
	picture_type: string
	defaultTags: string[]
	dropdownOpen: boolean

	handleEditClick(): void

	handleSave(): void

	deleteSelectedTag(index: number): void

	onDropdownOpen(): void

}

interface SaveImagesState {
	allChecked: boolean
}


class SaveImages extends React.Component<SaveImagesProps,SaveImagesState> {

	state = {
		allChecked: false,
	};

	componentDidMount(): void {
		const promises = this.props.pictures.map((pic: UploadedFiles) => {
			return new Promise(async res => {
				if(pic.is_normal === null) {
					pic.is_normal = await this.props.checkPictureDegree(pic.file);
				}
				res();
			})
		});
		Promise.all(promises).then(() => this.setState({
			allChecked: true,
		}))
	}

	render() {
		return (
			<>
				<div className="container">
					<div className={"dropdown mb-20" + (this.props.dropdownOpen ? " open" : '')}>
						<div className="dropdown-btn" onClick={this.props.onDropdownOpen}>
							<h5 className="fw-400 pb-5">{(this.props.picture_type === 'no_number') ?
								'No Number or ID'
								: (this.props.picture_type === 'temporary_id')
									? 'Temporary ID'
									: 'Installation Number'}</h5>
							<h4 className="fw-700">{this.props.temporary_id || this.props.installation_number}</h4>
							<span className="icon-next icon dropdown-arrow"/>
						</div>
						<div className="dropdown-content">
							<div className="pb-15">
								<span>Purpose: </span>
								<strong> {(this.props.type === 'intervention' ?
									'Intervention'
									: (this.props.type === 'quotation')
										? 'Quotation'
										: 'Future Information')}</strong>
							</div>
							<ul className="tag-group">
								{this.props.defaultTags.map((tag, index) => (
									<li key={index} className="tag-item with-close">
										<span className="tag-text">{tag}</span>
									</li>
								))}
								{this.props.tags.map((tag, index) => (
									<li key={index} className="tag-item with-close">
                                        <span className="tag-text">{tag}</span>
										<span
										onClick={() => this.props.deleteSelectedTag(index)}
										className="icon-close icon"/>
									</li>
								))}
							</ul>
						</div>
					</div>
					<div className="image-list pb-70">
						{this.props.pictures.map((picture, index) => (

							<div key={index} className="image-list-item"
							  style={{backgroundImage: `url(${picture.blob_url ? picture.blob_url : 
									  AWS + LITTLESIZE + '/' + picture.picture!.split('/').pop()})`}}
							>
								{(picture.is_normal !== null && !picture.is_normal) && <div className="image-list-item-action">
									<span className="icon icon-360"/>
								</div>}
							</div>
						))
						}
					</div>
				</div>
				<SaveImagesBottomBar
					handleEditClick={this.props.handleEditClick}
					handleSave={this.props.handleSave}
				/>
			</>
		);
	}
}

const mapDispatchToProps: PropsFromDispatch = {
	checkPictureDegree
};

export default connect<any, PropsFromDispatch, {}, ApplicationState>(
	null,
	mapDispatchToProps,
)(SaveImages);
