import React from 'react';
import {withFormik, FormikProps} from 'formik';
import EmptyGallery from "../../../../assets/img/gallery/empty-gallery.svg";
import DatePicker from "react-datepicker";


export const FormInitialValues: FormValues = {
	normal: false,
	threeSixty: false,
	for_future_information: false,
	intervention: false,
	quotation: false,
	startDate: null,
	endDate: null
};

export interface FormValues {
	normal: boolean,
	threeSixty: boolean,
	for_future_information: boolean,
	intervention: boolean,
	quotation: boolean
	startDate: any,
	endDate: any
}

interface OtherProps {
	isOpen: boolean

	onRightSidebarOpen(): void,

	bodyOverlay(): void,

	onSubmit(normal: boolean,
	         threeSixty: boolean,
	         for_future_information: boolean,
	         quotation: boolean,
	         intervention: boolean,
	         startDate: Date,
	         endDate: Date): void,
}

interface InnerFormProps extends FormikProps<FormValues>, OtherProps {

}

interface InnerFormState {

}

class InnerForm extends React.Component<InnerFormProps, InnerFormState> {

	render() {
		const {
			values,
			handleChange,
			handleBlur,
			handleSubmit,
			setFieldValue,
			onRightSidebarOpen,
			bodyOverlay,
			resetForm,
		} = this.props;

		return (
			<form onSubmit={handleSubmit}>
				<div className="flex align-center  mb-35 justify-between">
			<h2 className="fw-500">Filter</h2>
					<button type="button" onClick={() => {
							resetForm();
							handleSubmit();
						}}
		        className="link pointer no-style"
					>
						Reset
					</button>
				</div>
				<div className="pb-40">
					<h3 className="fw-500 mb-20">Select Image Type(s)</h3>
					<div className="checkbox mb-30">
						<label htmlFor="normal">
							<input
								type="checkbox"
								id="normal"
								name="normal"
								onChange={handleChange}
								onBlur={handleBlur}
								checked={values.normal}
							/>
							<span className="checkmark vertical-middle"/>
							<span className="fw-700 ml-20 vertical-middle">Normal</span>
						</label>
					</div>

					<div className="checkbox">
						<label htmlFor="360">
							<input
								type="checkbox"
								id="360"
								name="threeSixty"
								onChange={handleChange}
								onBlur={handleBlur}
								checked={values.threeSixty}
							/>
							<span className="checkmark vertical-middle"/>
							<span className="fw-700 ml-20 vertical-middle">360</span>
						</label>
					</div>
				</div>

				<div className="pb-40">
					<h3 className="fw-500 mb-20">Select Purpose(s)</h3>
					<div className="checkbox mb-30">
						<label htmlFor="future">
							<input
								type="checkbox"
								id="future"
								name="for_future_information"
								value="for_future_information"
								checked={values.for_future_information}
								onChange={handleChange}
								onBlur={handleBlur}
							/>
							<span className="checkmark vertical-middle"/>
							<span className="fw-700 ml-20 vertical-middle">For Future Information</span>
						</label>
					</div>
					<div className="checkbox mb-30">
						<label htmlFor="intervention">
							<input
								type="checkbox"
								id="intervention"
								name="intervention"
								value="intervention"
								checked={values.intervention}
								onChange={handleChange}
								onBlur={handleBlur}
							/>
							<span className="checkmark vertical-middle"/>
							<span className="fw-700 ml-20 vertical-middle">Intervention</span>
						</label>
					</div>
					<div className="checkbox">
						<label htmlFor="quotation">
							<input
								type="checkbox"
								id="quotation"
								name="quotation"
								value="quotation"
								checked={values.quotation}
								onChange={handleChange}
								onBlur={handleBlur}
							/>
							<span className="checkmark vertical-middle"/>
							<span className="fw-700 ml-20 vertical-middle">Quotation</span>
						</label>
					</div>
				</div>

				<div className="cols-md-2">
					<div className="col-md-6">
						<div className="textfield with-right-icon">
							<DatePicker
								placeholderText="From"
								value={values.startDate}
								selected={values.startDate}
								selectsStart
								startDate={values.startDate}
								endDate={values.endDate}
								onChange={e => setFieldValue('startDate', e)}
							/>
							<span className="icon-date-range icon">
                <span className="path1"/>
                <span className="path2"/>
              </span>
						</div>
					</div>
					<div className="col-md-6">
						<div className="textfield with-right-icon align-right">
							<DatePicker
								placeholderText="To"
								value={values.endDate}
								selected={values.endDate}
								selectsEnd
								startDate={values.startDate}
								endDate={values.endDate}
								onChange={e => setFieldValue('endDate', e)}
							/>
							<span className="icon-date-range icon">
                <span className="path1"/>
                <span className="path2"/>
              </span>
						</div>
					</div>
				</div>

				<div className="sidebar-meta">
					<button onClick={() => {
						onRightSidebarOpen();
						bodyOverlay();
					}} type='button' className="btn outline text mr-15">Cancel
					</button>
					<button type='submit' onClick={() => {
						onRightSidebarOpen();
						bodyOverlay();
					}} className="btn filled primary ripple">
						<span>Apply</span>
					</button>
				</div>
			</form>
		);
	}
}

interface MyFormProps {
	initialValues: FormValues,

	isOpen: boolean

	onRightSidebarOpen(): void,

	bodyOverlay(): void,

	onSubmit(normal: boolean,
	         threeSixty: boolean,
	         for_future_information: boolean,
	         quotation: boolean,
	         intervention: boolean,
	         startDate: Date,
	         endDate: Date): void,
}

export const FilterForm = withFormik<MyFormProps, FormValues>({
	enableReinitialize: true,

	mapPropsToValues: props => {
		const {initialValues} = props;
		return {
			normal: initialValues.normal,
			threeSixty: initialValues.threeSixty,
			for_future_information: initialValues.for_future_information,
			quotation: initialValues.quotation,
			intervention: initialValues.intervention,
			startDate: initialValues.startDate,
			endDate: initialValues.endDate
		}
	},

	handleSubmit(
		{normal, threeSixty, for_future_information, quotation, intervention, startDate, endDate}: FormValues,
		{props}
	) {
		props.onSubmit(normal, threeSixty, for_future_information, quotation, intervention, startDate, endDate);
	},

})(InnerForm);
