import React, {Component} from 'react';
import {AWS, BIGSIZE, LITTLESIZE } from '../../../../helpers/image-resize-endpoints';
import {beautifyTime} from "../../../../helpers/BeautifierForPm";

interface I360Styles {
    backgroundImage: string;
    backgroundPosition: string;
    backgroundSize: string;
    backgroundRepeat: string;
}

interface PictureProps {
    key: number;
    device: string;
    picture: any;
    open360Modal: (id: number,
                   picture: any,
                   x_range: number,
                   y_range: number,
                   zoom_range: number) => void;
    onOpenNormal: (url: string) => void;
    onInfoOpen: (picture: any) => void;
    recentlyDeleted?: boolean;
    selectable?: boolean;
    selectedPictures?: Array<number>;
    userId?: number;
    userRole?: string;

    onPictureSelect?(id: number): void;

    unselectPictures?(id: number): void;

}

interface PictureState {
    pictureReady: boolean;
    select: boolean;
}

export default class Picture extends Component<PictureProps, PictureState> {
    constructor(props: any) {
        super(props);
        // this.onPictureOpen = this.onPictureOpen.bind(this);
        this.checkIsReadyPicture = this.checkIsReadyPicture.bind(this);
        this.calculate360Styles = this.calculate360Styles.bind(this);
        this.state = {
            pictureReady: false,
            select: false,
        }
    }

    componentDidMount(): void {
        const {picture} = this.props;
        this.checkIsReadyPicture(picture);
    }

    public checkIsReadyPicture(picture: any): void {
        const image = new Image();
        image.src = picture.picture;
        image.onload = () => {
            this.setState({
                pictureReady: true,
            })
        }
    };

    public calculate360Styles(): I360Styles {
        const {picture} = this.props;
        const pictureName = this.props.picture.picture.split('/').pop();

        return {
            backgroundImage: `url(${AWS + LITTLESIZE + '/' + pictureName })`,
            backgroundPosition: `${50 + picture.x_range * (59 + (6 * picture.zoom_range) / 100) / 180}% ${50 - picture.y_range * 50 / 90}%`,
            backgroundSize: picture.zoom_range ? `${(100 - picture.zoom_range) * 5 + 450}%` : '450%',
            backgroundRepeat: 'repeat-x'
        }
    }


    public onPictureSelect(picture: any): void {
        const {
            onOpenNormal,
            selectable,
            onPictureSelect,
            open360Modal,
            device,
            userId
        } = this.props;

        if (selectable) {
            if (this.props.userRole === "admin") {
                onPictureSelect!(picture.id);
            } else if (userId === picture.user.id) {
                onPictureSelect!(picture.id);
            }
        } else if (picture.is_normal) {
            onOpenNormal(picture.picture);
        } else {
            device === 'pc' && open360Modal(
                picture.id,
                picture.picture,
                picture.x_range,
                picture.y_range,
                picture.zoom_range
            )
        }
    }

    render() {
        const {
            recentlyDeleted,
            picture,
            onInfoOpen,
            selectedPictures,
        } = this.props;

        const {pictureReady} = this.state;

        const pictureName = this.props.picture.picture.split('/').pop();

        return (
            <div className="pr-30 pos-rel">
                {pictureReady ? picture.is_normal
                    ? (<>
                            <div
                                onClick={() => this.onPictureSelect(picture)}
                                className={`image-list-item full-height ${selectedPictures && selectedPictures!.includes(picture.id) ? "selected-item" : ""}`}
                                style={{backgroundImage: `url(${AWS + LITTLESIZE + '/' + pictureName })`}}
                            >
                                {
                                    recentlyDeleted && (
                                        <div className="image-list-item-action">
                                            <span
                                                className="color-white pull-right font-xs">{beautifyTime(picture.will_be_deleted)}
                                            </span>
                                        </div>

                                    )
                                }
                            </div>
                            <button
                                onClick={onInfoOpen.bind(null, picture)}
                                className="inline-block no-style info">
												<span className="icon-info icon green">
												<span className="path1"></span>
												<span className="path2"></span>
                                        		</span>
                            </button>
                        </>
                    )
                    : (<>
                            <div onClick={() => this.onPictureSelect(picture)}
                                 className={`image-list-item full-height ${selectedPictures && selectedPictures!.includes(picture.id) ? "selected-item" : ""}`}
                                 style={this.calculate360Styles()}
                            >

                                {
                                    <div className="image-list-item-action">
                                        <span className='icon icon-360'/>
                                        {recentlyDeleted && (
                                            picture.will_be_deleted ?
                                                <span className="color-white pull-right font-xs">
                                                    {beautifyTime(picture.will_be_deleted)}
                                                </span> : null)

                                        }
                                    </div>
                                }
                            </div>
                            <button
                                onClick={onInfoOpen.bind(null, picture)}
                                className="inline-block no-style info"
                            >
                                <span className="icon-info icon green">
                                    <span className="path1"> </span>
                                    <span className="path2"> </span>
                                </span>
                            </button>
                        </>

                    )
                    : <>
                        <div className="image-list-item full-height default-image">
                            <span className="icon-default"></span>
                        </div>
                        <span className="inline-block no-style info default-info"></span>
                    </>
                }

            </div>
        );
    }
}

