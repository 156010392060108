//Auth
export const SIGN_IN = '/sign-in';
export const SIGN_UP = '/activate_account/:token';
export const FORGOT_PASSWORD ='/forgot-password';
export const RESET_PASSWORD = '/reset_password/:token';
//upload
export const UPLOAD = '/upload';
export const UPLOAD_START = '/upload/:step?';
//users
export const USERS = '/users';
//gallery
export const GALLERY_START ='/gallery/:type?/:name?/:editing?/:step?';
export const SEARCH_IN_GALLERY='/search-in-gallery/:step?';
export const SEARCH_IN_GALLERY_START='/search-in-gallery';
//pending
export const PENDING = '/pending';
export const PENDING_START = '/pending/:step?';
//my-profile
export const MY_PROFILE = '/my-profile';
//recently-deleted
export const RECENTLY_DELETED ='/recently-deleted';
//clients
export const CLIENTS = '/clients';
//tags
export const TAGS = '/tags';
