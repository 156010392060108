import React from 'react';
import {withFormik, FormikProps} from 'formik';
// import { connect } from 'react-redux';

import { ProfileChangePasswordSchema} from '../../../../helpers';

export interface ChangePasswordFormValues {
	old_password: string,
	password: string,
	confirm_password: string,
}

interface OtherProps {
	onSubmit(old_password: string, new_password: string ): void,
	onCloseModal(): void
}

interface InnerFormProps extends FormikProps<ChangePasswordFormValues>, OtherProps {

}

interface InnerFormState {

}

class InnerForm extends React.Component<InnerFormProps, InnerFormState> {
	render() {
		const {
			values,
			errors,
			touched,
			handleChange,
			handleBlur,
			handleSubmit,
		} = this.props;


		return (
			<form onSubmit={handleSubmit} className="modal-sm">
                <h2 className="fw-500 mb-35">Change Password</h2>
				<div className={"textfield with-icon" + ((errors.old_password && touched.old_password) ? ' error-field' : '')}>
					<input
						type="password"
						placeholder="Current Password"
						value={values.old_password}
						onChange={handleChange}
						onBlur={handleBlur}
						name='old_password'
					/>
					<span className="icon-lock icon">
								<span className="path1"></span>
								<span className="path2"></span>
					</span>
					{errors.old_password && touched.old_password ? (
						<p className="error-text">{errors.old_password}</p>
					) : null}
				</div>
				<div className={"textfield with-icon" + ((errors.password && touched.password) ? ' error-field' : '')}>
					<input
						type="password"
						placeholder="New Password"
						value={values.password}
						onChange={handleChange}
						onBlur={handleBlur}
						name='password'
					/>
					<span className="icon-lock icon">
								<span className="path1"></span>
								<span className="path2"></span>
							</span>
					{errors.password && touched.password ? (
						<p className="error-text">{errors.password}</p>
					) : null}
				</div>
				<div className={"textfield with-icon" + ((errors.confirm_password && touched.confirm_password) ? ' error-field' : '')}>
					<input
						type="password"
						placeholder="Confirm New Password"
						value={values.confirm_password}
						onChange={handleChange}
						onBlur={handleBlur}
						name='confirm_password'
					/>
					<span className="icon-lock icon">
								<span className="path1"></span>
								<span className="path2"></span>
							</span>
					{errors.confirm_password && touched.confirm_password ? (
						<p className="error-text">{errors.confirm_password}</p>
					) : null}
				</div>
				<div className="flex justify-end">
					<button type='button' onClick={this.props.onCloseModal} className="btn outline text mr-15">Cancel</button>
					<button type='submit' className="btn filled primary ripple">
						<span>Confirm</span>
					</button>
				</div>
			</form>

		);
	}
};

interface MyFormProps {
	onSubmit(old_password: string, new_password: string ): void,
	onCloseModal(): void
}

export const ChangePasswordForm = withFormik<MyFormProps, ChangePasswordFormValues>({
	enableReinitialize: true,

	mapPropsToValues: props => {
		return {
			old_password: '',
			password: '',
			confirm_password: '',
		}
	},

	validationSchema: ProfileChangePasswordSchema,

	handleSubmit(
		{old_password,password}: ChangePasswordFormValues,
		{props, setSubmitting, setErrors}
	) {
		props.onSubmit(old_password,password)
	},

})(InnerForm);