import React from 'react';


interface SaveImagesBottomBarProps {
    handleEditClick(): void
    handleSave(): void
}


class SaveImagesBottomBar extends React.Component<SaveImagesBottomBarProps> {

    render() {
        return (

            <div className="bar-bottom grey-bar">
                <div className="container-sm flex justify-end">
                    <button onClick={this.props.handleEditClick} className="btn outline text">
                        <i className="icon icon-prev vertical-middle mr-5"></i>
                        <span className="vertical-middle">Back</span>
                    </button>
                    <button onClick={this.props.handleSave} className="btn filled primary ripple  ml-15 right-icon">
                        <span className="vertical-middle">Add</span>
                    </button>
                </div>
            </div>
        );
    }
}

export default SaveImagesBottomBar;