import React, {Component} from 'react';
import ShowImages from "./ShowImages";
import EmptyGallery from "../../../../assets/img/gallery/empty-gallery.svg";
import {PicturesState} from "../../../../store/pictures/types";
import BottomBar from "./BottomBar";
import AquaSecLoader from "../Loader";

interface InstallationOrTemoraryPicturesProps {
    loaded: boolean;
    pictures: PicturesState;
    pictureType: string;
    chosenFolderName: string;
    userId: number;
    userRole: string;
    nextValue: string;
    searchValue: string;
    filterQuery: string;
    filterOptionsCount: number;
    device: string;
    history: any;
    match: any;

    addSelectedPictures(pictures: any[]): void;

    handleEdit(picture: any): void;

    handleDeleteSelected(id: number): void;

    setSearchValue(searchParam: string): void;

    onRightSidebarOpen(): void;

    onInfoOpen(picture: any): void;

    goBack(): void;

    handleLoad(): void;

    redirectToGallery(): void;


    handleLoadMore(nextValue: string): void;

    bodyOverlay(): void;

    handleSavePreviewFor360(id: number, x: number, y: number, z: number): void;

}

interface InstallationOrTemoraryPicturesState {
    search: string;
    selectable: boolean;
    selectedPictures: any;
}

class InstallationOrTemoraryPictures extends Component<InstallationOrTemoraryPicturesProps, InstallationOrTemoraryPicturesState> {
    state = {
        search: this.props.searchValue,
        selectable: false,
        selectedPictures: [],
    };


    async componentDidMount() {
        if (this.props.pictures.count === 0 && this.props.searchValue === '' && this.props.filterQuery === '') {
            this.props.redirectToGallery();
        }
        if (this.props.chosenFolderName === '') {
            this.props.handleLoad();
        }
    }

    async componentWillUpdate(nextProps: Readonly<InstallationOrTemoraryPicturesProps>, nextState: Readonly<InstallationOrTemoraryPicturesState>, nextContext: any) {
        console.log(this.props.pictures.count, this.props.searchValue, this.props.filterQuery, nextProps.pictures.count, nextProps.searchValue, nextProps.filterQuery );
        if ((this.props.pictures.count !== 0
            || (this.props.pictures.count === 0 && (this.props.searchValue !== '' || this.props.filterQuery !== '')))
            && nextProps.pictures.count === 0
            && nextProps.searchValue === ''
            && nextProps.filterQuery === '') {
            this.props.redirectToGallery();
        }
        if (this.state.selectedPictures !== nextState.selectedPictures) {
            this.props.addSelectedPictures(nextState.selectedPictures);
        }
    }

    handleChange = (e: React.FormEvent<HTMLInputElement>) => {
        this.setState({
            search: e.currentTarget.value,
        })
    };

    searchPictures = (e: any) => {
        e.preventDefault();
        const {search} = this.state;
        this.props.setSearchValue(search);
    };

    handleSelectable = () => {
        if (this.state.selectable) {
            this.setState({selectedPictures: []})
            // this.clearAllSelectedPictures();
        }
        this.setState({selectable: !this.state.selectable})
    };

    onAllPictureSelect = () => {
        const pictures = this.props.pictures.results;
        // @ts-ignore

        const selectedPictures = pictures.filter(({user: {id}}) => {
                if (this.props.userRole === "admin") return true;
                return this.props.userId === id;
            }
        ).map(({id}) => id);
        this.setState({selectedPictures})
    };


    onPictureSelect = (id: number) => {
        const {selectedPictures} = this.state;
        let selectedPicturesArray = [];

        // @ts-ignore
        if (selectedPictures.includes(id)) {
            selectedPicturesArray = selectedPictures.filter((pictureId) => {
                // @ts-ignore
                return pictureId !== id;
            })
        } else {
            if (this.props.userRole !== "admin") {

            }

            selectedPicturesArray = [...selectedPictures, id]
        }
        this.setState({selectedPictures: selectedPicturesArray})
    };

    handleBulkTagEditing = () => {
        this.props.history.push({pathname: '/gallery/edit/bulk/tag', state: {from: this.props.match.params.type}});

    };

    render() {
        const {selectable, selectedPictures} = this.state;

        return (
            (this.props.pictures.count === 0)
                ? (<>
                        <div className="flex justify-between py-25">
                            <div onClick={this.props.goBack} className="flex align-center pointer">
                                <span className="icon-arrow-back icon mr-15"/>
                                <h3>{this.props.chosenFolderName}</h3>
                            </div>
                            <button onClick={() => {
                                this.props.onRightSidebarOpen();
                                this.props.bodyOverlay()
                            }} className="btn outline primary fw-700">
                                <span>Filter {this.props.filterOptionsCount !== 0 && '(' + this.props.filterOptionsCount + ')'}</span>
                            </button>
                        </div>
                        <form onSubmit={this.searchPictures} className="textfield with-btn">
                            <input
                                type="text"
                                placeholder="Search"
                                value={this.state.search}
                                onChange={this.handleChange}
                            />
                            <button type='submit' className="no-style">
                                <span className="icon-search font-lg"/>
                            </button>
                        </form>
                        <div className="text-center empty-page">
                            <div className="pb-100">
                                <img className="empty-img" src={EmptyGallery} alt=""/>
                                <p className="pt-10 font-md">There are no images to display with the mentioned
                                    parameter(s)</p>
                            </div>
                        </div>
                    </>
                )
                : (<>
                        <div className="cols-xs-2 cols-mb align-center justify-between align-center py-25">
                            <div onClick={this.props.goBack} className="col-sm-6 col-xs-5 flex align-center pointer">
                                <span className="icon-arrow-back icon mr-15"/>
                                <h3>{this.props.chosenFolderName}</h3>
                            </div>

                            <div className="text-right col-sm-6 col-xs-7">
                                {selectable ? (
                                    <>
                                        <button onClick={this.handleSelectable}
                                                className="link fw-700 no-style mr-15">Cancel
                                        </button>
                                        <button onClick={this.onAllPictureSelect}
                                                className="link fw-700 no-style mr-15">Select
                                            All
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        <button onClick={this.handleSelectable} className="link fw-700 no-style mr-15">Add
                                            Tags
                                        </button>
                                    </>
                                )
                                }
                                <button onClick={() => {
                                    this.props.onRightSidebarOpen();
                                    this.props.bodyOverlay()
                                }} className="btn outline primary fw-700">
                                    <span>Filter {this.props.filterOptionsCount !== 0 && '(' + this.props.filterOptionsCount + ')'}</span>
                                </button>
                            </div>


                        </div>
                        <form onSubmit={this.searchPictures} className="textfield with-btn">
                            <input
                                type="text"
                                placeholder="Search"
                                value={this.state.search}
                                onChange={this.handleChange}
                            />
                            <button type='submit' className="no-style">
                                <span className="icon-search font-lg"/>
                            </button>
                        </form>
                        <ShowImages
                            selectedPictures={selectedPictures}
                            selectable={selectable}
                            onInfoOpen={this.props.onInfoOpen}
                            device={this.props.device}
                            SavePreviewFor360={this.props.handleSavePreviewFor360}
                            handleDeleteSelected={this.props.handleDeleteSelected}
                            userRole={this.props.userRole}
                            userId={this.props.userId}
                            pictures={this.props.pictures.results}
                            openEdit={this.props.handleEdit}
                            onPictureSelect={this.onPictureSelect}
                            handleLoadMore={() => this.props.nextValue ? this.props.handleLoadMore(this.props.nextValue) : {}}
                        />
                        {selectedPictures.length > 0 &&
                        <BottomBar
                          goNext={this.handleBulkTagEditing}
                        />}

                    </>
                )
        );
    }
}

export default InstallationOrTemoraryPictures;
