import React from 'react'
import {User} from "../../../../store/users/types";
import SearchUsers from "./SearchUsers";
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';
import ScrollArea from 'react-scrollbar';
import ReactTooltip from 'react-tooltip'
import {beautifyRole} from "../../../../helpers/BeautifierForPm";

interface UserProps {
    searchParam: string,
    users: User[],
    total: number,
    page: number,
    adminID: number,

    openAddNewUser(): void

    onOpenModal(user: User): void

    openEdit(user: User): void,

    handlePageChange(page: number): void

    resendMail(email: string): void
}

interface UsersState {
    checkResize: boolean
}

class UsersTable extends React.Component<UserProps, UsersState> {

    state = {
        checkResize: false,
    };

    componentDidMount(): void {
        this.setState({
            checkResize: true,
        })
    }

    render() {
        const {users, openAddNewUser, onOpenModal, openEdit, adminID, resendMail} = this.props;
        return (
            <div className="page-wrapper">
                <div className="container">
                    <div className="flex align-center justify-between pt-40">
                        <h2 className="fw-500">Users</h2>
                        <button onClick={openAddNewUser} className="btn outline with-icon primary fw-600">
                            <span className="icon-add icon"/>
                            <span>Add New User</span>
                        </button>
                    </div>
                    {/*Search start*/}
                    {(this.props.total === 1 && this.props.users[0].id === this.props.adminID && this.props.searchParam === '')
                        ? <></>
                        : <SearchUsers/>}
                    {/*Search end*/}
                    {/*Table start*/}
                    {((this.props.total === 1 && this.props.users[0].id === this.props.adminID) || this.props.total === 0)
                        ? (<div className="text-center empty-page">
                                <div className="pb-100">
                            <span className="icon-group1 icon blue empty-img">
								<span className="path1"/>
								<span className="path2"/>
								<span className="path3"/>
								</span>
                                    <h3>No Users</h3>
                                    <p className="pt-10 font-sm color-text-70">{this.props.searchParam
                                        ? 'There are no users to display'
                                        : 'No users are created yet. Add new users to invite them to AquaSecurity.'
                                    }</p>
                                </div>
                            </div>
                        )
                        : (
                            <>
                                <div className="table-style user-table with-action">
                                    <ScrollArea
                                        speed={0.8}
                                        className="area"
                                        contentClassName="content"
                                        horizontal={true}
                                        swapWheelAxes={true}
                                    >
                                        <div>
                                            <table>
                                                <thead>
                                                <tr>
                                                    <th>Full Name</th>
                                                    <th>E-mail</th>
                                                    <th>Role</th>
                                                    <th>Date Added</th>
                                                    <th className="text-center">Status</th>
                                                    <th className="text-center">Actions</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {users.map((user, outerIndex) => {
                                                    const userVisibleInformation = [
                                                        user.first_name + ' ' + user.last_name,
                                                        user.email,
                                                        beautifyRole(user.role),
                                                        new Date(user.created).toDateString(),
                                                    ];
                                                    if (user.id === adminID) {
                                                        return;
                                                    }
                                                    return <tr key={outerIndex}>
                                                        {userVisibleInformation.map((info, index) => (
                                                            <td key={index}>
                                                                <div id={'wrapper' + outerIndex + index}
                                                                     className="ellipsis text-wrapper">
																	<span
                                                                        id={'child' + outerIndex + index}
                                                                        className="ellipsis"
                                                                        data-tip={true}
                                                                        data-for={'' + outerIndex + index}
                                                                    >
																		{info}
																	</span>
                                                                </div>
                                                                {/*<div className="ellipsis" >{ info }</div>*/}
                                                                <ReactTooltip
                                                                    //@ts-ignore
                                                                    disable={index === 2 || index === 3 ||
                                                                    ((document.getElementById('wrapper' + outerIndex + index) &&
                                                                        document.getElementById('child' + outerIndex + index)) &&
                                                                        //@ts-ignore
                                                                        (document.getElementById('wrapper' + outerIndex + index).offsetWidth - document.getElementById('child' + outerIndex + index).offsetWidth > 8))}
                                                                    id={'' + outerIndex + index}
                                                                    type='dark'
                                                                    effect='solid'
                                                                >
                                                                    <span>{info}</span>
                                                                </ReactTooltip>
                                                            </td>
                                                        ))}
                                                        <td className="text-center">
                                                            {user.is_active
                                                                ? (<button className="px-10 inline-block no-style">
                                                                    <span className="icon-done icon green"/>
                                                                </button>)
                                                                : (<>
                                                                        <button data-tip={true}
                                                                                data-for={'' + outerIndex}
                                                                                onClick={() => user.can_resend ? resendMail(user.email) : {}}
                                                                                className={"px-10 inline-block no-style " + (!user.can_resend ? "disabled" : "")}>
                                                                            <span className="icon-resend icon blue"/>
                                                                        </button>
                                                                        {!user.can_resend &&
                                                                        <ReactTooltip
                                                                            id={'' + outerIndex}
                                                                            type='dark'
                                                                            effect='solid'
                                                                        >
                                                                            <p style={{textAlign: "left"}}>Resending
                                                                                email will automatically become
                                                                                possible</p>
                                                                            <p style={{textAlign: "left"}}>after 30
                                                                                minutes from the last performed
                                                                                action</p>
                                                                        </ReactTooltip>}
                                                                    </>
                                                                )
                                                            }
                                                        </td>
                                                        <td className="text-center">
                                                            <div className="flex align-center">
                                                                <button
                                                                    onClick={() => openEdit(user)}
                                                                    className="px-10 inline-block no-style"
                                                                    disabled={user.is_deleted}
                                                                >
																	<span className="icon-edit icon blue">
																		<span className="path1"/>
																		<span className="path2"/>
																	</span>
                                                                </button>
                                                                <ReactTooltip
                                                                    id={'' + user.id}
                                                                    type='dark'
                                                                    effect='solid'
                                                                >
                                                                    {user.is_deleted ? <p>Reactivate user</p> :
                                                                        <p>Deactivate user</p>}

                                                                </ReactTooltip>
                                                                <label
                                                                    data-tip={true}
                                                                    data-for={'' + user.id}
                                                                    className="switch mx-10">
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={!user.is_deleted}
                                                                        disabled={!user.is_active}
                                                                        onChange={() => onOpenModal(user)}
                                                                    />
                                                                    <span className="slider round"/>
                                                                </label>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </ScrollArea>
                                </div>

                                {this.props.total >= 1 &&
                                <div className="py-30 text-center">
                                    <Pagination
                                        total={this.props.total - 1 !== 0 ? this.props.total - 1 : 1}
                                        pageSize={15}
                                        onChange={this.props.handlePageChange}
                                        current={this.props.page}
                                        className="inline-block"
                                    />
                                </div>}
                            </>
                        )}
                </div>
                {/*Table end*/}
            </div>
        );
    }
}

export default UsersTable;
