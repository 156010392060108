import React from 'react';

interface HeaderProps {
    goNext(): void
}


class RecentlyDeletedBottomBar extends React.Component<HeaderProps> {

    render() {
        const { goNext } = this.props;
        return (
            <div className="bar-bottom blue-bar text-right">
                <div className="container">
                    <ul className="inline-flex">
                        <li>
                            <button onClick={goNext} className="no-style cursor-pointer">
                                <span>Next</span>
                                <span className="icon-next icon ml-10"/>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default RecentlyDeletedBottomBar;