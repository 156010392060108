import { Store, createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk'

import {ApplicationInitialState, ApplicationState, rootReducer} from './store';
export const API_URL = process.env.REACT_APP_API_URL;

export default function configureStore(): Store<ApplicationState> {
  const composeEnhancers = composeWithDevTools({});
  let store;
  if(process.env.NODE_ENV === 'development') {
    store = createStore(
      //@ts-ignore
      rootReducer,
      ApplicationInitialState,
      composeEnhancers(applyMiddleware(thunk))
    );
  } else if(process.env.NODE_ENV === 'production') {
    store = createStore(
      //@ts-ignore
      rootReducer,
      ApplicationInitialState,
      applyMiddleware(thunk)
    );
  }

  return store;
}