import { Reducer } from 'redux';
import {UsersActionTypes, User} from './types';
import {Users} from "./actions";

export const UserInitialState: Users = {
  count: NaN,
  next: "null",
  previous: "null",
  results: []
};

export const initialUserState: User = {
  id: NaN,
  last_login: '',
  created: '',
  modified: '',
  is_deleted: false,
  email: '',
  role: '',
  first_name: '',
  last_name: '',
  is_active: true,
  can_resend: true,
};


const userReducer: Reducer<Users> = (state: Users = UserInitialState, action) => {
  switch (action.type) {
    case UsersActionTypes.FETCH_USERS:
      return action.data;
    case UsersActionTypes.UPDATE_EDITED_USER:
      return {
        ...state
      };
    default:
      return state;
  }
}

export default userReducer;
