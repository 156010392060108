export interface PicturesState {
	count: number,
	next: any,
	previous: any,
	results: []
}

export enum PicturesActionTypes {
	GET_PICTURES = '@@pictures/GET_PICTURES',
	CLEAR_PICTURES = '@@pictures/CLEAR_PICTURES',
	LOAD_MORE = '@@pictures/LOAD_MORE',
	UPDATE_PICTURE_COORDINATES = '@@pictures/UPDATE_PICTURE_COORDINATES'
}