import { Reducer } from 'redux';
import { SharedState, SharedActionTypes } from './types';
import {GlobalFormInitialValues} from "../../pages/layout/components/GlobalFilter";

export const SharedInitialState: SharedState = {
	searchValue: '',
	nextValue: '',
	loading: false,
	globalSearchValues: GlobalFormInitialValues,
	count: 0,
	device: 'pc',
};

const sharedReducer: Reducer<SharedState> = (state: SharedState = SharedInitialState, action) => {
	switch (action.type) {
		case SharedActionTypes.SEARCH_VALUE:
			return {
				...state,
				searchValue: action.data,
			};
		case SharedActionTypes.NEXT_VALUE:
			return {
				...state,
				nextValue: action.data
			};
		case SharedActionTypes.LOADING:
			return{
				...state,
				loading: action.data
			};
		case SharedActionTypes.CLEAR_VALUE:
			return {
				...state,
				searchValue: '',
			};
		case SharedActionTypes.SET_GLOBAL_SEARCH_VALUES:
			return {
				...state,
				globalSearchValues: {...action.data}
			};
		case SharedActionTypes.SET_COUNT:
			return {
				...state,
				count: action.data,
			};
		case SharedActionTypes.CLEAR_GLOBAL_SEARCH:
			return {
				...state,
				globalSearchValues: GlobalFormInitialValues,
				count: 0,
			};
		case SharedActionTypes.CHANGE_DEVICE:
			return {
				...state,
				device: 'mobile',
			};
		default:
			return state;
	}
};

export default sharedReducer;
