import React from 'react';
import Logo from '../../../../assets/img/logo.svg'


class CheckEmail extends React.Component {

	render() {
		return (
			<div className="sign-form sign-in">
				<div className="sign-header">
					<img src={Logo} alt="Aquasecurity"/>
				</div>
				<div className="sign-body">
					<p className="font-normal pb-30">Please check your e-mail to reset your password.</p>
				</div>
			</div>
		);
	}
}

export default CheckEmail;