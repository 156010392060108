import React from 'react';
import Logo from "../../../assets/img/logo.svg";
import {RouteComponentProps, withRouter} from "react-router";
import {Link} from "react-router-dom";
import * as ROUTES from "../../../helpers/routes";


interface SidebarProps extends RouteComponentProps<any> {
	role: string
	active: string

	oncloseSidebar(): void

	logOut(e: any): void
}


class Sidebar extends React.Component<SidebarProps> {
	render() {
		return (
			<div onMouseUp={() => {
				this.props.oncloseSidebar();
			}}>
				<button onMouseDown={() => {
					this.props.oncloseSidebar();
				}} className="no-style sidebar-left-btn drawer-btn">
					<span className="icon-close"/>
				</button>
				<div className="sidebar-logo">
					<img src={Logo} alt="Aquasecurity"/>
				</div>

				<div className="sidebar-content">
					<ul className="menu">
						{this.props.role === 'admin' && <li>
              <Link
                className={this.props.active === 'users' ? "active" : ''}
                to={ROUTES.USERS}
              >
							<span className="icon-group1 icon">
								<span className="path1"/>
								<span className="path2"/>
								<span className="path3"/>
								</span>
                <span>Users</span>
              </Link>
            </li>}
						{(this.props.role === 'admin' || this.props.role === 'back_officer') && <li>
              <Link
                className={this.props.active === 'clients' ? "active" : ''}
                to={ROUTES.CLIENTS}
              >
                				<span className="icon-business icon">
									<span className="path1"/>
									<span className="path2"/>
								</span>
                <span>Temporary IDs</span>
              </Link>
            </li>}
						<li>
							<Link
								className={this.props.active === 'gallery' ? "active" : ''}
								to={this.props.history.location.pathname.split('/')[2]
									? this.props.history.location.pathname.split('/')[2] !== 'edit'
										? `/gallery/${this.props.history.location.pathname.split('/')[2]}`
										: '/gallery/installation_number'
								    : '/gallery/installation_number'}
							>
								<span className="icon-photo icon">
									<span className="path1"/>
									<span className="path2"/>
								</span>
								<span>Gallery</span>
							</Link>
						</li>
						<li>
							<Link
								className={this.props.active === 'pending' ? "active" : ''}
								to={ROUTES.PENDING}
							>
                <span className="icon-time icon">
									<span className="path1"/>
									<span className="path2"/>
								</span>
								<span>Pending</span>
							</Link>
						</li>
						{this.props.role === 'admin' && <li>
              <Link
                className={this.props.active === 'recently-deleted' ? "active" : ''}
                to={ROUTES.RECENTLY_DELETED}
              >
								<span className="icon-delete icon">
									<span className="path1"/>
									<span className="path2"/>
                				</span>
                <span>Recently Deleted</span>
              </Link>
            </li>}
						{this.props.role === 'admin' && <li>
              <Link
                className={this.props.active === 'tags' ? "active" : ''}
                to={ROUTES.TAGS}
              >
                                <span className="icon-tag icon">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                    <span className="path3"></span>
                                </span>
                <span>Tags</span>
              </Link>
            </li>}
						<li>
							<Link
								className={this.props.active === 'my-profile' ? "active" : ''}
								to={ROUTES.MY_PROFILE}
							>
                <span className="icon-account-circle icon">
									<span className="path1"/>
									<span className="path2"/>
                </span>
								<span>My Profile</span>
							</Link>
						</li>
						<li className="logout">
							<a onClick={this.props.logOut} href="#" className="">
								<span className="icon-turn-off icon"/>
								<span>Logout</span>
							</a>
						</li>
					</ul>
				</div>
			</div>
		);
	}
}

export default withRouter(Sidebar);
