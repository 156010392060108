export interface SessionState {
  auth_token: string
  pk: number
  role: string
  email: string
  last_login: string
  name: string
  surname: string
}

export enum SessionActionTypes {
  LOG_IN_USER = '@@session/LOG_IN_USER',
  SIGN_UP_USER = '@@session/SIGN_UP_USER',
  GET_SESSION = "@@session/GET_SESSION",
  LOG_OUT = '@@session/LOG_OUT'
} 