import React from 'react';
import {withFormik, FormikProps} from 'formik';
import {ClientFormSchema} from "../../../../helpers";
// @ts-ignore
import AsyncSelect from 'react-select/async';

export interface ClientFormValues {
	name: string
	index: string,
	installation_number: { value: string, label: string },
}

export const ClientFormInitialValues: ClientFormValues = {
	name: '',
	index: '',
	installation_number: {value: '', label: ''}
};

interface OtherProps {
	title: string
	role: string
	disabled: boolean
	isOpen: boolean
	installation_number_options: { value: string, label: string } []
	next: string

	onSubmit(name: string, index: string, installation_number: object): void

	onCloseForm(): void

	loadOptions(search: string): void

	getMore(next: string): void
}

interface InnerFormProps extends OtherProps, FormikProps<ClientFormValues> {

}

class InnerForm extends React.Component<InnerFormProps> {

	// componentWillUpdate(nextProps: Readonly<InnerFormProps>, nextState: Readonly<{}>, nextContext: any): void {
	// 	if(nextProps.isOpen !== this.props.isOpen) {
	// 		if(!this.props.isOpen) {
	// 			this.props.resetForm();
	// 		}
	// 	}
	// }

	render() {
		const {
			values,
			errors,
			touched,
			handleChange,
			handleBlur,
			handleSubmit,
			onCloseForm,
			disabled,
			setFieldValue,
			setFieldTouched,
			resetForm,
			installation_number_options,
			getMore,
			title,
			next,
			loadOptions
		} = this.props;
		return (
			<form onSubmit={handleSubmit}>
				<h2 className="fw-500 mb-35">{title}</h2>
				<div className={"textfield" + ((errors.name && touched.name) ? ' error-field' : '')}>
					<input
						autoFocus={false}
						type="text"
						placeholder="Client Name"
						name='name'
						onChange={handleChange}
						onBlur={handleBlur}
						value={values.name}
					/>
					{errors.name && touched.name ? (
						<p className="error-text">{errors.name}</p>
					) : null}
				</div>

				<div className={"textfield" + ((errors.index && touched.index) ? ' error-field' : '')}>
					<input
						type="text"
						placeholder="Temporary ID"
						name='index'
						onChange={handleChange}
						onBlur={handleBlur}
						value={values.index}
					/>
					{errors.index && touched.index ? (
						<p className="error-text">{errors.index}</p>
					) : null}
				</div>
				{(!disabled && this.props.installation_number_options.length !== 0) &&
        <div className="textfield">
            <AsyncSelect
                loadOptions={loadOptions}
                name='installation_number'
                value={values.installation_number.value ? (values.installation_number) : null}
                onChange={(value: any) => setFieldValue('installation_number', value)}
                onBlur={() => setFieldTouched('installation_number', true)}
                defaultOptions={installation_number_options}
                placeholder="Select Installation Number"
                classNamePrefix="inner"
                onMenuScrollToBottom={() => getMore(next)}
            />
        </div>
				}
				<div className="flex justify-end">
					<button type='button' onClick={() => {
						onCloseForm();
						resetForm();
					}} className="btn outline text mr-15">Cancel
					</button>
					<button type='submit' className="btn filled primary ripple"><span>Save</span></button>
				</div>
			</form>
		)
	}
}

interface ClientsForm {
	initialValues: ClientFormValues,
	disabled: boolean
	isOpen: boolean
	installation_number_options: { value: string, label: string } []
	role: string
	title: string
	next: string

	onSubmit(name: string, index: string, installation_number: object): void

	onCloseForm(): void

	getMore(next: string): void

	loadOptions(search: string): void
}

export const ClientsForm = withFormik<ClientsForm, ClientFormValues>({
	enableReinitialize: true,

	mapPropsToValues: props => {
		const {initialValues} = props;
		return {
			name: initialValues.name,
			index: initialValues.index,
			installation_number: initialValues.installation_number
		}
	},

	validationSchema: ClientFormSchema,

	handleSubmit(
		{name, index, installation_number}: ClientFormValues,
		{props }
	) {
		props.onSubmit(name, index, installation_number);
	},

})(InnerForm);
