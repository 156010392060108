import {FormValues} from "../../pages/layout/components/GlobalFilter";

export interface SharedState {
	searchValue: string,
	nextValue: string,
	loading: boolean,
	globalSearchValues: FormValues,
	count: number,
	device: string
}

export enum SharedActionTypes {
	SEARCH_VALUE = '@@shared/SEARCH_VALUE',
	CLEAR_VALUE = '@@shared/CLEAR_VALUE',
	NEXT_VALUE = '@@shared/NEXT_VALUE',
	LOADING = '@@shared/LOADING',
	SET_GLOBAL_SEARCH_VALUES = '@@shared/SET_GLOBAL_SEARCH_VALUES',
	SET_COUNT = "@@shared/SET_COUNT",
	CLEAR_GLOBAL_SEARCH = '@@shared/CLEAR_GLOBAL_SEARCH',
	CHANGE_DEVICE = '@@shared/CHANGE_DEVICE',
}
