import React from 'react';


interface SaveImagesBottomBarProps {
	handleEditClick(): void
	handleSave(): void
}


class SaveImagesBottomBar extends React.Component<SaveImagesBottomBarProps> {

	render() {
		return (

			<div className="bar-bottom grey-bar">
				<div className="container-sm flex justify-end">
					<button onClick={this.props.handleEditClick} className="btn outline text">
              <span className="icon color-text icon-edit vertical-middle mr-5 text-left">
                <span className="path1"></span>
                <span className="path2"></span>
              </span>
						<span className="vertical-middle">Edit</span>
					</button>
					<button onClick={this.props.handleSave} className="btn filled primary ripple  ml-15 right-icon">
						<span className="vertical-middle">Save</span>
						<span className="icon-save icon white vertical-middle ml-5 text-right">
              <span className="path1"></span>
              <span className="path2"></span>
            </span>
					</button>
				</div>
			</div>
		);
	}
}

export default SaveImagesBottomBar;