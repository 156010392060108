import { Reducer } from 'redux';
import {PicturesActionTypes, PicturesState} from './types';

export const PicturesInitialState: PicturesState = {
	count: -1,
	next: null,
	previous: null,
	results: []
};

const picturesReducer: Reducer = (state = PicturesInitialState, action) => {
	switch (action.type) {
		case PicturesActionTypes.GET_PICTURES:
			return action.data;
		case PicturesActionTypes.LOAD_MORE:
			return {
				...state,
				next: action.data.next,
				results: [
					...state.results,
					...action.data.results
				]
			};
		case PicturesActionTypes.CLEAR_PICTURES:
			return PicturesInitialState;
		case PicturesActionTypes.UPDATE_PICTURE_COORDINATES:
			const newResults = state.results.map((val: any) => {
				if(val.id === action.data.id) {
					return {
						...val,
						x_range: action.data.x,
						y_range: action.data.y,
						zoom_range: action.data.z,
					};
				}
				return val;
			});
			return {
				...state,
				results: newResults
			};
		default:
			return state;
	}
};

export default picturesReducer;