import React from 'react';
import {withFormik, FormikProps} from 'formik';
import Logo from '../../../../assets/img/logo.svg';
import SignImg from "../../../../assets/img/sign-page/signup-img.svg";

import {SignUpSchema} from '../../../../helpers';


interface FormValues {
	first_name: string
	last_name: string
	password: string
	confirm_password: string
}

interface OtherProps {
	email: string
	disabled: boolean;
	onSubmit(first_name: string, last_name: string, password: string): void
}

const InnerForm = (props: OtherProps & FormikProps<FormValues>) => {
	const {
		values,
		errors,
		touched,
		handleChange,
		handleBlur,
		handleSubmit,
		disabled,
	} = props;

	return (
		<form onSubmit={handleSubmit} className="sign-form sign-up">
			<div className="sign-form-inner">
				<div className="sign-header">
					<img src={Logo} alt="Aquasecurity"/>
				</div>
				<div className="sign-body cols-sm-2">
					<div className="col-sm-5">
						<h2 className="fw-500 mb-20">Create your account</h2>
						<p className="italic mb-35 mb-sm-25 color-text-70">Register with <strong
							className="color-text">{props.email}</strong> email</p>
						<div className={"textfield with-icon" + ((errors.first_name && touched.first_name) ? ' error-field' : '')}>
							<input
								type="text"
								placeholder="First Name"
								name='first_name'
								onChange={handleChange}
								onBlur={handleBlur}
								value={values.first_name}
							/>
							<span className="icon-user icon">
              <span className="path1"/>
              <span className="path2"/>
              <span className="path3"/>
	          </span>
							{errors.first_name && touched.first_name ? (
								<p className="error-text">{errors.first_name}</p>
							) : null}
						</div>
						<div className={"textfield with-icon" + ((errors.last_name && touched.last_name) ? ' error-field' : '')}>
							<input
								type="text"
								placeholder="Last Name"
								name='last_name'
								onChange={handleChange}
								onBlur={handleBlur}
								value={values.last_name}
							/>
							<span className="icon-user icon">
              <span className="path1"/>
              <span className="path2"/>
              <span className="path3"/>
            </span>
							{errors.last_name && touched.last_name ? (
								<p className="error-text">{errors.last_name}</p>
							) : null}
						</div>
						<div className={"textfield with-icon" + ((errors.password && touched.password) ? ' error-field' : '')}>
							<input
								type="password"
								placeholder="Password"
								name='password'
								autoComplete='new-password'
								onChange={handleChange}
								onBlur={handleBlur}
								value={values.password}
							/>
							<span className="icon-lock icon">
	            <span className="path1"/>
	            <span className="path2"/>
            </span>
							{errors.password && touched.password ? (
								<p className="error-text">{errors.password}</p>
							) : null}
						</div>
						<div
							className={"textfield with-icon" + ((errors.confirm_password && touched.confirm_password) ? ' error-field' : '')}>
							<input
								type="password"
								placeholder="Confirm Password"
								name='confirm_password'
								onChange={handleChange}
								onBlur={handleBlur}
								value={values.confirm_password}
							/>
							<span className="icon-lock icon">
              <span className="path1"/>
              <span className="path2"/>
            </span>
							{errors.confirm_password && touched.confirm_password ? (
								<p className="error-text">{errors.confirm_password}</p>
							) : null}
						</div>
						<button
							type='submit'
							className="btn filled primary ripple full-width mt-25"
							disabled={disabled}
						>
							<span>Sign up</span>
						</button>
					</div>
					<div className="col-sm-7 align-end flex-center-x hide-sm">
						<div className="sign-img pb-45">
							<img src={SignImg} alt=""/>
						</div>
					</div>
				</div>
			</div>
		</form>
	);
};

interface MyFormProps {
	initialValues?: FormValues
	email: string;
	disabled: boolean;
	onSubmit(first_name: string, last_name: string, password: string): void
}

export const SignUpForm = withFormik<MyFormProps, FormValues>({
	mapPropsToValues: () => ({
		first_name: '',
		last_name: '',
		password: '',
		confirm_password: '',
	}),

	validationSchema: SignUpSchema,

	handleSubmit(
		{first_name, last_name, password}: FormValues,
		{props}
	) {
		props.onSubmit(first_name, last_name, password);
	},

})(InnerForm);