import React from 'react';
import UploadImage from "../../../../assets/img/upload.svg";
import SelectImagesBottomBar from "./SelectImagesBottomBar";
import {PicturePreview, UploadedFiles} from "../../pages/UploadImages";
//@ts-ignore
import {Pannellum} from 'pannellum-react'

import Modal from "react-responsive-modal";
import {DELETE_IMAGE, DELETE_IMAGES} from "../../../../helpers/modalMessages";
import { ImageSingle } from './ImageSingle';

const refSelectImages = React.createRef();

interface SelectImagesProps {
    selectable: boolean
    files: UploadedFiles[]
    selected_count: number
    device: string

    handleSelectPicture(id: number): void

    handleFileChange(files: FileList): void

    handleDeleteSelected(): void

    startAddingTags(): void

    handleSelectable(): void

    checkPictureDegree(picture: UploadedFiles): Promise<boolean>
}

interface SelectImagesState {
    open360: boolean
    openNormal: boolean
    picture_preview: string
    pannelum_ready: boolean
    openDelete: boolean
}

class SelectImages extends React.Component<SelectImagesProps, SelectImagesState> {

    state = {
        open360: false,
        openNormal: false,
        picture_preview: '',
        pannelum_ready: false,
        openDelete: false
    };

    openDelete = () => {
        this.setState({
            openDelete: true
        })
    };

    onCloseDelete = () => {
        this.setState({
            openDelete: false,
        })
    };

    onClose360Modal = () => {
        this.setState({
            open360: false,
            picture_preview: '',
            pannelum_ready: false
        })
    };

    onOpen360Modal = (index: number) => {
        this.setState({
            open360: true,
            picture_preview: this.props.files[index].blob_url
        })
    };

    onOpenNormalModal = (index: number) => {
        this.setState({
            openNormal: true,
            picture_preview: this.props.files[index].blob_url
        })
    };

    onCloseNormal = () => {
        this.setState({
            openNormal: false,
            picture_preview: '',
        })
    };


    render() {
        return (
            (this.props.files.length === 0)
                ? (
                    <div className="container-sm py-60 py-sm-0 flex justify-center direction-column">
                        <div className="box upload-img">
                            <img src={UploadImage} alt=""/>
                            <h3 className="pt-30">Upload Images</h3>
                            <p className="pt-10 font-sm color-text-70 pb-70">Upload images here</p>
                            <input
                                type='file'
                                multiple
                                accept='image/*'
                                style={{display: "none"}}
                                id='files1'
                                onChange={(event) => this.props.handleFileChange(event.target.files!)}
                            />
                            <label className="btn filled primary ripple full-width" htmlFor='files1'>
                                Upload
                            </label>
                        </div>

                    </div>
                ) : (
                    <>
                        <div className="container">
                            <div className="text-right py-25">
                                {!this.props.selectable
                                    ? (<button onClick={this.props.handleSelectable}
                                               className="link fw-700 no-style">Select</button>)
                                    : (<>
                                            <button onClick={this.props.handleSelectable}
                                                    className="link fw-700 no-style mr-30">Cancel
                                            </button>
                                            <button onClick={() => this.props.handleSelectPicture(-1)}
                                                    className="link fw-700 no-style">Select
                                                All
                                            </button>
                                        </>
                                    )}
                            </div>
                            <div className={"image-list cursor-pointer pb-70" + ( this.props.selectable? " selected" : "")}>
                                <label htmlFor="files2" className="image-list-item upload">
                                    <span className="icon icon-add"/>
                                    <p className="color-text-50 pt-5">Upload</p>
                                </label>
                                <input
                                    type='file'
                                    multiple
                                    accept='image/*'
                                    style={{display: "none"}}
                                    id='files2'
                                    onChange={(event) => this.props.handleFileChange(event.target.files!)}
                                />
                                {
                                    // this.props.pictures.map((picture: PicturePreview, index) => {
                                    //     return <div
                                    //         key={index + `${Math.round(Math.random() * 10)}`}
                                    //         onClick={
                                    //             async () => (this.props.selectable ? this.props.handleSelectPicture(index):
                                    //                 (picture.is_normal !== null || await this.props.checkPictureDegree(picture))
                                    //                     ? this.onOpenNormalModal(index)
                                    //                     : (this.props.device === 'mobile' && this.onOpen360Modal(index)))}
                                    //         className={'image-list-item' + (picture.selected ? ' selected-item' : '')}
                                    //         style={{backgroundImage: `url(${picture.url})`}}
                                    //     />
                                    // })
                                    this.props.files.map((file: UploadedFiles, index) => {
                                        return <ImageSingle
                                            key={index + `${Math.round(Math.random() * 10)}`}
                                            index={index}
                                            file={file}
                                            selectable={this.props.selectable}
                                            device={this.props.device}
                                            handleSelectPicture={this.props.handleSelectPicture}
                                            checkPictureDegree={this.props.checkPictureDegree}
                                            onOpenNormalModal={this.onOpenNormalModal}
                                            onOpen360Modal={this.onOpen360Modal}
                                        />
                                    })
                                }
                            </div>
                        </div>
                        {this.props.selected_count ?
                            <SelectImagesBottomBar
                                startAddingTags={this.props.startAddingTags}
                                handleDeleteSelected={this.openDelete}
                            /> : null
                        }
                        <Modal
                            open={this.state.open360}
                            onClose={this.onClose360Modal}
                            center
                            onEntered={() => this.setState({pannelum_ready: true})}
                        >
                            {this.state.pannelum_ready &&
                            <Pannellum
                                showControls={false}
                                pitch={10}
                                yaw={180}
                                hfov={110}
                                autoLoad
                                image={this.state.picture_preview}
                                id='containers'
                                width='600px'
                                height='480px'
                                maxHfov={100}
                            />}
                        </Modal>

                        {/*
  // @ts-ignore */}
                        <div className="modal modal-sm" ref={refSelectImages}>
                            <Modal
                                open={this.state.openDelete}
                                onClose={this.onCloseDelete}
                                center
                                container={refSelectImages.current}
                            >
                                <div>
                                    {
                                        this.props.selected_count === 1
                                            ? <p className="font-md pt-40">{DELETE_IMAGE}</p>
                                            : <p className="font-md pt-40">{DELETE_IMAGES}</p>
                                    }
                                    <div className="flex justify-end mt-50">
                                        <button className="btn outline text mr-15" onClick={this.onCloseDelete}>Cancel
                                        </button>
                                        <button className="btn filled warning ripple" onClick={() => {
                                            this.props.handleDeleteSelected();
                                            this.onCloseDelete();
                                        }}>
                                            <span>Delete</span>
                                        </button>
                                    </div>
                                </div>
                            </Modal>
                        </div>
                        <Modal
                            open={this.state.openNormal}
                            onClose={this.onCloseNormal}
                            center
                        >
                            <img alt={''} src={this.state.picture_preview}/>
                        </Modal>
                    </>
                )
        );
    }
}

export default SelectImages;
