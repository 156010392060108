import React, {Component} from 'react';
import EmptyGallery from "../../../../assets/img/gallery/empty-gallery.svg";
import ShowRecentlyDeletedImages from "./ShowRecentlyDeletedImages";
import RecentlyDeletedBottomBar from "./RecentlyDeletedBottomBar";
import Modal from "react-responsive-modal";
import {toast} from "react-toastify";
import {
    MULTIPLE_PICTURES_DELETED, MULTIPLE_PICTURES_RESTORED,
    SINGLE_PICTURE_DELETED, SINGLE_PICTURE_RESTORED
} from "../../../../helpers/toastMessages";
import {DELETE_IMAGE, DELETE_IMAGES} from "../../../../helpers/modalMessages";
import ShowImages from "../Gallery/ShowImages";
import {updateCoordinates} from "../../../../store/pictures/actions";

const refRecentlyDelete = React.createRef();

interface NoNumberProps {
    searchValue: string
    device: string
    pictureType: string;
    pictures: object[];
    picturesNext: string;
    filterOptionsCount: number
    loading: boolean
    pageSize: number,
    session: any,

    onInfoOpen(picture: any): void

    onRightSidebarOpen(): void;

    getRecDeletedPicturesBy(queryParams: string, searchParams: string, page: number, page_size: number): void;

    bodyOverlay(): void;

    clearFilterValues(): void;

    deleteRecDeletedPictures(picturesArray: Array<number>): void;

    restorePictures(picturesIdArray: Array<number>): void;

    LoadMore(queryParams: string): void;

    setSearchValue(searchParams: string): void;
}

interface NoNumberState {
    searchValue: string;
    selectedPictures: Array<number>;
    openDelete: boolean,
    selectable: boolean,

}

class RecentlyDeletedPictures extends Component<NoNumberProps, NoNumberState> {
    state = {
        searchValue: '',
        selectedPictures: [],
        openDelete: false,
        selectable: false,

    };


    handleSelectable = () => {
        if (this.state.selectable) {
            this.setState({selectedPictures: []})
        }
        this.setState({selectable: !this.state.selectable})
    };


    handleDeleteSelected = async () => {
        const {selectedPictures} = this.state;
        const {deleteRecDeletedPictures, getRecDeletedPicturesBy, clearFilterValues} = this.props;
        this.onToggleDelete();
        await deleteRecDeletedPictures(selectedPictures);
        getRecDeletedPicturesBy('', '', 1, this.props.pageSize);
        toast.dismiss();
        toast(() =>
            <div className="toast-style success">
                <div className="left-side">
							<span className="icon-success icon">
								<span className="path1"/>
								<span className="path2"/>
							</span>
                </div>
                <div className="right-side">
                    <p>{selectedPictures.length === 1
                        ? SINGLE_PICTURE_DELETED
                        : MULTIPLE_PICTURES_DELETED}</p>
                </div>
            </div>
        );
        clearFilterValues();
        this.setState({selectedPictures: []});
    };

    hangleSearchInputChange = (e: any) => {
        this.setState({searchValue: e.target.value});
    };

    onToggleDelete = () => {
        this.setState({openDelete: !this.state.openDelete})
    };

    onSearchButtonClick = async (e: any) => {
        const {getRecDeletedPicturesBy, setSearchValue} = this.props;
        const {searchValue} = this.state;
        e.preventDefault();
        await getRecDeletedPicturesBy('', searchValue, 1, this.props.pageSize);
        setSearchValue(searchValue);
    };

    onPictureSelect = (id: number) => {
        const {selectedPictures} = this.state;
        let selectedPicturesArray = [];

        // @ts-ignore
        if (selectedPictures.includes(id)) {
            selectedPicturesArray = selectedPictures.filter((pictureId) => {
                // @ts-ignore
                return pictureId !== id;
            })
        } else {
            selectedPicturesArray = [...selectedPictures, id]
        }
        this.setState({selectedPictures: selectedPicturesArray})
    };

    // unselectPicture = (id: number) => {
    //     let selectedPictures = this.state.selectedPictures.filter((pictureId) => {
    //         return pictureId !== id
    //     });
    //     this.setState({selectedPictures})
    // };

    // clearAllSelectedPictures = () => {
    //
    // };

    onAllPictureSelect = () => {
        // @ts-ignore
        let selectedPictures = this.props.pictures.map((picture) => picture.id);
        this.setState({selectedPictures})
    };

    restorePictures = async () => {
        const {selectedPictures} = this.state;
        const {restorePictures, clearFilterValues, getRecDeletedPicturesBy} = this.props;

        await restorePictures(selectedPictures);
        clearFilterValues();
        getRecDeletedPicturesBy('', '', 1, this.props.pageSize);
        toast.dismiss();
        toast(() =>
            <div className="toast-style success">
                <div className="left-side">
							<span className="icon-success icon">
								<span className="path1"/>
								<span className="path2"/>
							</span>
                </div>
                <div className="right-side">
                    <p>{selectedPictures.length === 1
                        ? SINGLE_PICTURE_RESTORED
                        : MULTIPLE_PICTURES_RESTORED
                    }</p>
                </div>
            </div>
        );
        this.setState({selectedPictures: []})

    };


    render() {

        const {selectedPictures, searchValue, openDelete, selectable} = this.state;
        const {onRightSidebarOpen, bodyOverlay, pictures, picturesNext, LoadMore} = this.props;
        return (
            <>
                {(pictures.length === 0) ?
                    <>
                        {(this.props.searchValue !== '') &&
                        <>
                            <div className="flex justify-between align-center py-25">
                                <p className="italic fw-700 pr-10 color-warning">* Images display days or hours
                                    remaining before deletion. They are permanently deleted after 31 days.</p>
                                <button onClick={() => {
                                    onRightSidebarOpen();
                                    bodyOverlay();
                                }} className="btn outline primary fw-700 pull-right">
                                    <span>Filter {this.props.filterOptionsCount !== 0 && '(' + this.props.filterOptionsCount + ')'}</span>
                                </button>
                            </div>
                            <form onSubmit={this.onSearchButtonClick} className="textfield with-btn">
                                <input
                                    type="text"
                                    placeholder="Search"
                                    value={searchValue}
                                    onChange={this.hangleSearchInputChange}
                                />
                                <button type='submit' className="no-style">
                                    <span className="icon-search font-lg"/>
                                </button>
                            </form>
                        </>
                        }
                        <div className="text-center empty-page-3">
                            <div className="pb-100">
                                <img className="empty-img" src={EmptyGallery} alt=""/>
                                <p className="pt-10 font-md">There are no images to display</p>
                            </div>
                        </div>
                    </> :
                    <>
                        <div className="cols-xs-2 cols-mb align-center justify-between align-center py-25">
                            <p className="col-sm-6 col-xs-5 italic fw-700 pr-10 color-warning">* Images display days or hours
                                remaining before deletion. They are permanently deleted after 31 days.</p>

                            <div className="text-right col-sm-6 col-xs-7">
                                {selectable ? (
                                    <>
                                        <button onClick={this.handleSelectable}
                                                className="link fw-700 no-style mr-15">Cancel
                                        </button>
                                        <button onClick={this.onAllPictureSelect} className="link fw-700 no-style mr-15">Select All
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        <button onClick={this.handleSelectable} className="link fw-700 no-style mr-15">Select
                                        </button>
                                    </>
                                )
                                }
                                <button onClick={() => {
                                    onRightSidebarOpen();
                                    bodyOverlay();
                                }} className="btn outline primary fw-700 pull-right">
                                    <span>Filter {this.props.filterOptionsCount !== 0 && '(' + this.props.filterOptionsCount + ')'}</span>
                                </button>
                            </div>
                        </div>
                        <form onSubmit={this.onSearchButtonClick} className="textfield with-btn">
                            <input
                                type="text"
                                placeholder="Search"
                                value={searchValue}
                                onChange={this.hangleSearchInputChange}
                            />
                            <button type='submit' className="no-style">
                                <span className="icon-search font-lg"/>
                            </button>
                        </form>

                        <ShowImages
                            selectable={selectable}
                            pictures={pictures}
                            userId={this.props.session.pk}
                            userRole={this.props.session.role}
                            device={this.props.device}
                            handleDeleteSelected={() => this.handleDeleteSelected}
                            onInfoOpen={this.props.onInfoOpen}
                            SavePreviewFor360={updateCoordinates}
                            handleLoadMore={() => this.props.picturesNext ? this.props.LoadMore(this.props.picturesNext) : {}}
                            selectedPictures={selectedPictures}
                            onPictureSelect={this.onPictureSelect}
                            recentlyDeleted={true}
                        />

                        {selectedPictures.length > 0 &&
                        <RecentlyDeletedBottomBar
                            handleDeleteSelected={this.onToggleDelete}
                            restorePictures={this.restorePictures}
                        />}

                    </>}
                {/*
  // @ts-ignore */}
                <div className="modal modal-sm" ref={refRecentlyDelete}>
                    <Modal
                        open={openDelete}
                        onClose={this.onToggleDelete}
                        center
                        container={refRecentlyDelete.current}
                    >
                        <div>
                            {
                                selectedPictures.length === 1
                                    ? <p className="font-md pt-40">{DELETE_IMAGE}</p>
                                    :
                                    <p className="font-md pt-40">{DELETE_IMAGES}</p>
                            }
                            <div className="flex justify-end mt-50">
                                <button className="btn outline text mr-15"
                                        onClick={this.onToggleDelete}>
                                    Cancel
                                </button>
                                <button className="btn filled warning ripple"
                                        onClick={this.handleDeleteSelected}
                                >
                                    <span>Delete</span>
                                </button>
                            </div>
                        </div>
                    </Modal>
                </div>
            </>
        );
    }
}

export default RecentlyDeletedPictures;
