import React from 'react';
import { withFormik, FormikProps } from "formik";

import Logo from '../../../../assets/img/logo.svg'
import {EmailSchema} from "../../../../helpers";

interface FormValues {
	email: string
}

interface OtherProps{
	onSubmit(email: string): void
	onCancel(): void
}

const InnerForm = (props: OtherProps & FormikProps<FormValues>) => {
	const {
		values,
		errors,
		touched,
		handleChange,
		handleBlur,
		handleSubmit,
	} = props;
	return(
		<form onSubmit={handleSubmit} className="sign-form sign-in">
			<div className="sign-header">
				<img src={Logo} alt="Aquasecurity"/>
			</div>
			<div className="sign-body">
				<p className="font-normal pb-30">Please enter your e-mail address to reset your password</p>
				<div className={"textfield with-icon" + ((errors.email && touched.email)?' error-field':'')}>
					<input
						name='email'
						type="text"
						placeholder="Email"
						value={values.email}
						onChange={handleChange}
						onBlur={handleBlur}
					/>
					<span className="icon-user icon">
                <span className="path1"/>
                <span className="path2"/>
                <span className="path3"/>
              </span>
					{errors.email && touched.email ? (
						<p className="error-text">{errors.email}</p>
					) : null}
				</div>
				<div className="flex justify-end mt-75">
					<button type='button' className="btn outline text mr-15" onClick={props.onCancel}>
						Cancel
					</button>
					<button className="btn filled primary ripple" type='submit'><span>Confirm</span></button>
				</div>
			</div>
		</form>
	)
};

interface MyFormProps{
	initialValues?: FormValues,
	onSubmit(email: string): void,
	onCancel(): void,
}

export const ForgotForm = withFormik<MyFormProps, FormValues>({
	mapPropsToValues: () => ({
		email: '',
		password: '',
	}),

	validationSchema: EmailSchema,

	handleSubmit(
		{ email }: FormValues,
		{ props }
	) {
		props.onSubmit(email);
	},

})(InnerForm);