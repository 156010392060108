import React from 'react';
import { NewPasswordForm } from '../components/NewPasswordForm';
import {compose} from 'recompose'
import {RouteComponentProps, withRouter} from "react-router";
import {connect} from "react-redux";
import {ApplicationState, ConnectedReduxProps} from "../../../../store";
import { getEmailByToken, signUpUser } from "../../../../store/session/actions";
import {toast} from "react-toastify";
import {PASSWORD_UPDATE, TOKEN_USED_OR_EXPIRED} from "../../../../helpers/toastMessages";
import {SIGN_IN} from "../../../../helpers/routes";

interface PropsFromDispatch {
	getEmailByToken(token: string): any
	signUpUser(token: string, password: string, first_name: string, last_name: string): void
}

interface PropsFromState {
	errorMessage: object
}


interface NewPasswordProps extends RouteComponentProps<any>, PropsFromDispatch, PropsFromState, ConnectedReduxProps {

}

interface NewPasswordState {
	first_name: string,
	last_name: string,
}

class NewPassword extends React.Component<NewPasswordProps, NewPasswordState> {

	state = {
		first_name: '',
		last_name: '',
	};

	async componentDidMount() {

		const token = this.props.match && this.props.match.params.token;
		const value = await this.props.getEmailByToken(token);

		console.log(this.props.errorMessage);

		if(Object.keys(this.props.errorMessage).length === 0) {
			this.setState({
				first_name: value.first_name,
				last_name: value.last_name
			})
		} else {
			toast.dismiss();
				toast(() =>
					<div className="toast-style error">
						<div className="left-side">
							<span className="icon-error icon">
								<span className="path1"/>
								<span className="path2"/>
							</span>
						</div>
						<div className="right-side">
							<p>{TOKEN_USED_OR_EXPIRED}</p>
						</div>
					</div>
				)
		}
	}

	changePassword = async (password: string) => {
		const token = this.props.match && this.props.match.params.token;
		try {
			await this.props.signUpUser(token, password, this.state.first_name, this.state.last_name);
			if(Object.keys(this.props.errorMessage).length === 0) {
				toast.dismiss();
				toast(() =>
					<div className="toast-style success">
						<div className="left-side">
							<span className="icon-success icon">
								<span className="path1"/>
								<span className="path2"/>
							</span>
						</div>
						<div className="right-side">
							<p>{PASSWORD_UPDATE}</p>
						</div>
					</div>
				);
				this.props.history.push(SIGN_IN)
			} else {
				Object.values(this.props.errorMessage).map(messages => {
					toast.dismiss();
					return messages.map((message: React.ReactNode) => toast(() => <div>{message}</div>))
				}
			)}
		} catch (e) {
		}
	};

	render() {
		const isDisabled = (Object.keys(this.props.errorMessage).length !== 0);
		return (
			<div className="sign-page">
				{/*change password*/}
					<NewPasswordForm
						disabled={isDisabled}
						onSubmit={this.changePassword}
					/>
				{/*change password*/}
			</div>
		);
	}
}

const mapStateToProps = (state: ApplicationState): PropsFromState => {
	return {
		errorMessage: state.errorMessage,
	}
};

const mapDispatchToProps: PropsFromDispatch = {
	getEmailByToken,
	signUpUser
};

export default compose<NewPasswordProps, {}>(
	withRouter,
	connect<PropsFromState, PropsFromDispatch, {}, ApplicationState>(
		mapStateToProps,
		mapDispatchToProps,
	)
)(NewPassword);
