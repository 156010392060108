import React from 'react';
import {compose} from 'recompose';
import {RouteComponentProps, withRouter, Route} from "react-router";
import {connect} from 'react-redux'
import {User} from '../../../store/users/types';
import {ConnectedReduxProps, ApplicationState} from '../../../store';
import {fetchUsers, addNewUser, editUserById, verifyToken, Users} from '../../../store/users/actions';
import {
	getPendingPicturesBy,
	LoadMore,
	deletePendingPictures,
	savePandingPictures,
	clearPictures
} from '../../../store/pictures/actions'
import {initialUserState} from '../../../store/users/reducer';
import {SessionState} from "../../../store/session/types";
import {AddingTagsStep1Form} from "../components/UploadImages_Components/AddingTagsStep1Form";
import PandingPage from "../components/Pending_Page/PandingPage";
import {ClientsState} from "../../../store/clients/types";
import {AddingTagsStep2Form} from "../components/UploadImages_Components/AddingTagsStep2Form";
import {AddingTagsStep3Form} from "../components/UploadImages_Components/AddingTagsStep3Form";
import SaveImages from "../components/UploadImages_Components/SaveImages";
import AquaSecLoader from "../components/Loader";
import {PendingFilterForm} from "../components/Pending_Page/PendingFilterForm";
import {getInstallationNumbers, getMoreOptions, getTemporaryIDs, loadOptions} from "../../../store/clients/actions";
import {TagsState} from "../../../store/tags/types";
import {getDefaultTags, getMoreTags} from "../../../store/tags/actions";


interface PropsFromState {
	users: Users
	errorMessage: any
	session: SessionState
	searchValue: string
	pandingPicturesData: any
	clients: ClientsState
	loading: boolean
	device: string
	defaultTags: TagsState
}

interface PropsFromDispatch {
	fetchUsers: (searchParam: string, page: number) => void
	addNewUser: (email: string, role: string, first_name: string, last_name: string) => void
	editUserById: (id: number, email: string, role: string, first_name: string, last_name: string) => void
	verifyToken: (token: string) => void
	getPendingPicturesBy: (queryParams: string) => void
	LoadMore: (queryParams: string) => void,
	deletePendingPictures: (picturesArray: any) => any,
	savePandingPictures: (files: any,
	                      tags: string,
	                      p_type: string,
	                      ins_num: string,
	                      tem_id: string,
	                      type: string,) => any

	clearPictures(): void
	getTemporaryIDs(page?: number, search?: string): void
	getInstallationNumbers(page?: number,search?: string): void
	loadOptions(search: string, type: string): void
	getMoreOptions(next: string,type: string): void
	getMoreTags(next:string): void
	getDefaultTags(page: number, search: string, limit?: number): void
}

interface PendingProps extends RouteComponentProps<any>, PropsFromState, PropsFromDispatch, ConnectedReduxProps {
	openRightSidebar: boolean,
	pandingPicturesData: any,

	openPandingFilter(): void,

	bodyOverlay(): void
}

interface PendingState {
	openGlobalFilter: boolean;
	dropdownOpen: boolean;
	openLeftSidebar: boolean;
	openRightSidebar: boolean;
	singleUser: User;
	pictureType: string,
	temporary_id: string,
	installation_number: string,
	installation_number_name: string,
	temporary_id_name: string,
	open: boolean;
	title: string;
	disabled: boolean;
	tags: string[],
	type: string;
	selectedPictures: any[];
	installation_number_options: { value: string, label: string }[],
	temporary_id_options: { value: string, label: string }[]
	filterQuery: string;
	defaultTags: string[];
	onceLoaded: boolean,
	filterOptionsCount: number
	pageSize: number
	showDefault: boolean
}

class Pending extends React.Component<PendingProps, PendingState> {

	state: PendingState = {
		openGlobalFilter: false,
		singleUser: initialUserState,
		openLeftSidebar: false,
		openRightSidebar: false,
		open: false,
		type: '',
		tags: [],
		pictureType: 'installation_number',
		temporary_id: '',
		installation_number: '',
		title: 'Add User',
		installation_number_name: '',
		defaultTags: [],
		temporary_id_name: '',
		dropdownOpen: false,
		disabled: false,
		selectedPictures: [],
		filterQuery: '',
		installation_number_options: [],
		temporary_id_options: [],
		onceLoaded: false,
		filterOptionsCount: 0,
		pageSize: 21,
		showDefault: false
	};

	async componentDidMount() {
		if(this.props.device === 'pc') {
			await this.setState({
				pageSize: 40,
			})
		}
		await this.props.getPendingPicturesBy(`page=1&page_size=${this.state.pageSize}`);
		this.setState({
			onceLoaded: true,
		})
	}

	getMoreOptions = async (next: string, type: string) => {
		if(next) {
			if(type === 'installation_number') {
				await this.props.getMoreOptions(next, 'installation_number');
				this.setState({
					installation_number_options: this.props.clients.installation_number_paginated.results.map((client: any) => ({
						value: client.id,
						label: client.name
					})),
				})
			} else {
				await this.props.getMoreOptions(next, 'temporary_id');
				this.setState({
					temporary_id_options: this.props.clients.temporary_id_paginated.results.map((client: any) => ({
						value: client.id,
						label: client.name
					})),
				})
			}
		}
	};

	getMoreTags = async (next: string) => {
		if(next) {
			await this.props.getMoreTags(next);
		}
	};

	selectPicture = (id: number) => {
		let selectedPictures = [...this.state.selectedPictures, id];
		this.setState({selectedPictures})
	};

	unselectPicture = (id: number) => {
		let selectedPictures = this.state.selectedPictures.filter((pictureId) => {
			return pictureId !== id
		});
		this.setState({selectedPictures})

	};

	selectAllPictures = () => {
		let selectedPictures = this.props.pandingPicturesData.results.map((pandingPicture: any) => {
			return pandingPicture.id;
		});
		this.setState({selectedPictures})
	};

	clearSelectedPicturesArray = () => {
		this.setState({selectedPictures: []})
	};

	onFilterSubmit = (normal: boolean, threeSixty: boolean, startDate: Date, endDate: Date) => {
		let filterQuery = `page=1&page_size=${this.state.pageSize}&`;
		let optionsCount = 0;
		if (normal) {
			++optionsCount;
			filterQuery += 'is_normal=True&';
		}
		if (threeSixty) {
			++optionsCount;
			filterQuery += 'is_normal=False&';
		}
		if (normal && threeSixty) {
			optionsCount = 2;
			filterQuery = '';
		}
		if (startDate) {
			++optionsCount;
			filterQuery += 'from_date=' + startDate.toLocaleDateString() + '&';
		}
		if (endDate) {
			++optionsCount;
			filterQuery += 'to_date=' + endDate.toLocaleDateString() + '&';
		}
		this.setState({
			filterQuery,
			filterOptionsCount: optionsCount
		});
		this.props.getPendingPicturesBy(filterQuery);
		this.clearSelectedPicturesArray();
	};

	onFilterButtonClick = () => {
		const {openPandingFilter, bodyOverlay} = this.props;

		openPandingFilter();
		bodyOverlay();
	};

	onStopPropagtion = (e: React.MouseEvent<HTMLElement>) => {
		e.stopPropagation();
	};
	startAddingTags = async () => {
		await this.props.getTemporaryIDs(1,'');
		await this.props.getInstallationNumbers(1,'');
		await this.props.getDefaultTags(1,'', 30);
		this.setState({
			defaultTags: [this.props.session.name + '  ' + this.props.session.surname, new Date().toDateString()],
			installation_number_options: this.props.clients.installation_number_paginated.results.map((client: any) => ({
				value: client.id,
				label: client.name
			})),
			temporary_id_options: this.props.clients.temporary_id_paginated.results.map((client: any) => ({
				value: client.id,
				label: client.name
			}))
		});
		this.props.history.push({
			pathname: '/pending/step1',
		})
	};

	stepTwo = (pictureType: string,
	           installation_number: string,
	           installation_number_name: string,
	           temporary_id: string,
	           temporary_id_name: string) => {
		this.setState({
			pictureType,
			installation_number,
			installation_number_name,
			temporary_id,
			temporary_id_name,
		});
		this.props.history.push({
			pathname: '/pending/step2',
		})
	};

	stepThree = (type: string) => {
		this.setState({
			type,
		});
		this.props.history.push({
			pathname: '/pending/step3',
		})
	};

	stepFour = (tags: string[]) => {
		this.setState({tags});
		this.props.history.push({
			pathname: '/pending/step4',
		})
	};
	onDropdownOpen = () => {
		this.setState((prevState: PendingState) => ({
			dropdownOpen: !prevState.dropdownOpen
		}));
	};

	deleteSelectedTag = (index: number) => {
		let val = this.state.tags;
		val.splice(index, 1);
		this.setState({
			tags: val
		})
	};

	toggleShowDefault = (tags: string[]) => {
		this.setState((prevState: PendingState) => ({
			showDefault: !prevState.showDefault,
			tags
		}))
	};

	filterSelectedPictures = () => {
		const pictures = this.props.pandingPicturesData.results;
		const selectedPictures = this.state.selectedPictures;
		return pictures.filter((picture: any) => {
			return selectedPictures.includes(picture.id)
		});

	};

	handleSave = async () => {
		const {pictureType, installation_number, temporary_id, type, tags} = this.state;
		const temp = tags.join(',');
		const files = this.filterSelectedPictures();
		this.setState({onceLoaded:true});
		// await this.props.clearPictures();
		await this.props.savePandingPictures(files, temp, pictureType, installation_number, temporary_id, type);
		if (this.state.installation_number_name) {
			this.props.history.replace(`/gallery/installation_number/${this.state.installation_number}`);
		} else if (this.state.temporary_id_name) {
			this.props.history.replace(`/gallery/temporary_id/${this.state.temporary_id}`);
		} else {
			this.props.history.replace('/gallery/no_number');
		}
	};

	render() {
		const {selectedPictures, filterQuery} = this.state;
		const {openRightSidebar, openPandingFilter, bodyOverlay} = this.props;
		const showClassName = openRightSidebar ? "show" : " ";

		return (
			(!this.state.onceLoaded)
				? (<AquaSecLoader/>)
				: (<>

					<div className='page-wrapper'>
						<aside onMouseDown={this.onStopPropagtion} className={"sidebar-right " + showClassName}>
                            <div className="sidebar-right-inner">
							{/*
                    // @ts-ignore*/}
							<PendingFilterForm
								isOpen={openRightSidebar}
								onRightSidebarOpen={openPandingFilter}
								bodyOverlay={bodyOverlay}
								onSubmit={this.onFilterSubmit}
								clearSelectedPicturesArray={this.clearSelectedPicturesArray}
							/>
							</div>
						</aside>
						<Route exact path={'/pending'} render={() => <PandingPage
							{...this.props}
							device={this.props.device}
						filterOptionsCount={this.state.filterOptionsCount}
							startAddingTags={this.startAddingTags}
							onStopPropagtion={this.onStopPropagtion}
							onFilterButtonClick={this.onFilterButtonClick}
							onFilterSubmit={this.onFilterSubmit}
							selectedPictures={selectedPictures}
							selectPicture={this.selectPicture}
							unselectPicture={this.unselectPicture}
							filterQuery={filterQuery}
							clearSelectedPicturesArray={this.clearSelectedPicturesArray}
							selectAllPictures={this.selectAllPictures}
							pageSize={this.state.pageSize}
						/>}/>
						<Route path={'/pending/step1'} render={() => <AddingTagsStep1Form
							getMore={this.getMoreOptions}
							nextInstallation={this.props.clients.installation_number_paginated.next}
							nextTemporary={this.props.clients.temporary_id_paginated.next}
							loadOptionsInstallation={(search: string) => this.props.loadOptions(search,'installation_number')}
							loadOptionsTemporary={(search: string) => this.props.loadOptions(search,'temporary_id')}
							nextStep={this.stepTwo}
							installation_number_options={this.state.installation_number_options}
							temporary_id_options={this.state.temporary_id_options}
							previousStep={() => this.props.history.goBack()}
							initialValues={{
								picture_type: this.state.pictureType,
								temporary_id: {value: this.state.temporary_id, label: this.state.temporary_id_name},
								installation_number: {
									value: this.state.installation_number,
									label: this.state.installation_number_name
								}
							}}
						/>}/>
						<Route path={'/pending/step2'} render={() => <AddingTagsStep2Form
							previousStep={() => this.props.history.goBack()}
							nextStep={this.stepThree}
							initialValues={{type: this.state.type}}
						/>}/>

						<Route path='/pending/step3' render={() => <AddingTagsStep3Form
							previousStep={() => this.props.history.goBack()}
							nextStep={this.stepFour}
							initialValues={{tags: this.state.tags, tagValue: ''}}
							showDefault={this.state.showDefault}
							toggleShowDefault={this.toggleShowDefault}
							defaultTags={this.props.defaultTags.results}
							next={this.props.defaultTags.next}
							loadMoreTags={this.getMoreTags}
						/>}/>
						{/*
						//@ts-ignore*/}
						<Route path='/pending/step4' component={() => <SaveImages
							pictures={this.filterSelectedPictures()}
							handleEditClick={() => this.props.history.go(-3)}
							handleSave={this.handleSave}
							defaultTags={this.state.defaultTags}
							tags={this.state.tags}
							type={this.state.type}
							picture_type={this.state.pictureType}
							installation_number={this.state.installation_number_name}
							temporary_id={this.state.temporary_id_name}
							deleteSelectedTag={this.deleteSelectedTag}
							dropdownOpen={this.state.dropdownOpen}
							onDropdownOpen={this.onDropdownOpen}
						/>}/>
					</div>
				</>)
		);
	}

}

const mapStateToProps = (state: ApplicationState): PropsFromState => {
	return {
		users: state.users,
		errorMessage: state.errorMessage,
		session: state.session,
		searchValue: state.shared.searchValue,
		pandingPicturesData: state.pictures,
		clients: state.clients,
		loading: state.shared.loading,
		device: state.shared.device,
		defaultTags: state.tags,
	}
};

const mapDispatchToProps: PropsFromDispatch = {
	fetchUsers,
	addNewUser,
	editUserById,
	verifyToken,
	getPendingPicturesBy,
	LoadMore,
	deletePendingPictures,
	savePandingPictures,
	clearPictures,
	getTemporaryIDs,
	getInstallationNumbers,
	loadOptions,
	getMoreOptions,
	getMoreTags,
	getDefaultTags
};

export default compose<PendingProps, {}>(
	withRouter,
	connect<PropsFromState, PropsFromDispatch, {}, ApplicationState>(
		mapStateToProps,
		mapDispatchToProps,
	)
)(Pending);
