import React from 'react';
import {withFormik, FormikProps} from 'formik';
import {TagsValidationSchema} from "../../../../helpers";
// @ts-ignore

export interface TagsFromValues {
	tag: string
}

export const TagsFormInitialValues: TagsFromValues = {
	tag: '',
};

interface OtherProps {
	title: string
	isOpen: boolean

	onSubmit(name: string): void

	onCloseForm(): void

}

interface InnerFormProps extends OtherProps, FormikProps<TagsFromValues> {

}

class InnerForm extends React.Component<InnerFormProps> {

	// componentWillUpdate(nextProps: Readonly<InnerFormProps>, nextState: Readonly<{}>, nextContext: any): void {
	// 	if(nextProps.isOpen !== this.props.isOpen) {
	// 		if(!this.props.isOpen) {
	// 			this.props.resetForm();
	// 		}
	// 	}
	// }

	render() {
		const {
			values,
			errors,
			touched,
			handleChange,
			handleBlur,
			handleSubmit,
			onCloseForm,
			resetForm,
			title,
		} = this.props;
		return (
			<form onSubmit={handleSubmit}>
				<h2 className="fw-500 mb-35">{title}</h2>
				<div className={"textfield" + ((errors.tag && touched.tag) ? ' error-field' : '')}>
					<input
						autoFocus={true}
						type="text"
						placeholder="Tag Name"
						name='tag'
						onChange={handleChange}
						onBlur={handleBlur}
						value={values.tag}
					/>
					{errors.tag && touched.tag ? (
						<p className="error-text">{errors.tag}</p>
					) : null}
				</div>
				<div className="flex justify-end">
					<button type='button' onClick={() => {
						onCloseForm();
						resetForm();
					}} className="btn outline text mr-15">Cancel
					</button>
					<button type='submit' className="btn filled primary ripple"><span>Save</span></button>
				</div>
			</form>
		)
	}
}

interface ClientsForm {
	initialValues: TagsFromValues,
	isOpen: boolean
	title: string

	onSubmit(name: string): void

	onCloseForm(): void

}

export const TagsForm = withFormik<ClientsForm, TagsFromValues>({
	enableReinitialize: true,

	mapPropsToValues: props => {
		const {initialValues} = props;
		return {
			tag: initialValues.tag,
		}
	},

	validationSchema: TagsValidationSchema,

	handleSubmit(
		{tag}: TagsFromValues,
		{props }
	) {
		props.onSubmit(tag);
	},

})(InnerForm);
