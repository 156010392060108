import {ThunkAction, ThunkDispatch} from "redux-thunk";
import {AnyAction, Dispatch} from "redux";
import {randomTokenName} from "../../App";
import {API_URL} from "../../configureStore";
import {AddingNewUserFailed, ClearErrorMessages, NoAccess} from "../errorMessage/actions";
import {TagsActionTypes} from "./types";
import request from "../../helpers/request";

export const defaultTags = (result: any) => (dispatch: Dispatch<any>) => {
	return dispatch({type: TagsActionTypes.GET_DEFAULT_TAGS, data: result});
};


export const getDefaultTags = (page?: number, searchValue?: string, limit?: number)
	: ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
		return new Promise<void>(async (res) => {
			const token = localStorage.getItem(randomTokenName) || sessionStorage.getItem(randomTokenName);
			if (page) {
				let page_size = 15;
				// dispatch(loading(true));
				await request(API_URL + `default-tags/?search=${searchValue}&page=${page}&page_size=${limit || page_size}`, {
					method: 'GET',
					headers: {
						'Authorization': 'JWT ' + token,
					}
				})
					.then(async res => {
						if (res.ok) {
							await res.json()
								.then(val => dispatch(defaultTags(val)))
						} else {
							dispatch(NoAccess({message: 'wrong token'}));
						}
					});
				// dispatch(loading(false));
			} else {
				await request(API_URL + 'default-tags/', {
					method: 'GET',
					headers: {
						'Authorization': 'JWT ' + token,
					}
				})
					.then(async res => {
						if (res.ok) {
							await res.json()
								.then(val => dispatch(defaultTags(val.results)))
						} else {
							dispatch(NoAccess({message: 'wrong token'}));
						}
					});
			}
			res();
		})
	}
};

export const addTag = (name: string)
	: ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
		return new Promise<void>(async (res) => {
			dispatch(ClearErrorMessages());
			const token = localStorage.getItem(randomTokenName) || sessionStorage.getItem(randomTokenName);
			await request(API_URL + 'default-tags/',{
				method: 'POST',
				headers: {
					'Authorization': 'JWT ' + token,
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({name})
			})
				.then(async response => {
					if (!response.ok) {
						await response.json()
							.then(error => dispatch(AddingNewUserFailed(error)))
					}
				});
			res();
		})
	}
};

export const editTag = (name: string, id: number)
	: ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
		return new Promise<void>(async (res) => {
			dispatch(ClearErrorMessages());
			const token = localStorage.getItem(randomTokenName) || sessionStorage.getItem(randomTokenName);
			await request(API_URL + `default-tags/${id}/`,{
				method: 'PATCH',
				headers: {
					'Authorization': 'JWT ' + token,
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({name})
			})
				.then(async response => {
					if (!response.ok) {
						await response.json()
							.then(error => dispatch(AddingNewUserFailed(error)))
					}
				});
			res();
		})
	}
};

export const deleteTag = (id: number)
	: ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
		return new Promise<void>(async (res) => {
			dispatch(ClearErrorMessages());
			const token = localStorage.getItem(randomTokenName) || sessionStorage.getItem(randomTokenName);
			await request(API_URL + `default-tags/${id}/`, {
				method: 'DELETE',
				headers: {
					'Authorization': 'JWT ' + token,
					'Content-Type': 'application/json',
				},
			})
				.then(async response => {
					if (!response.ok) {
						await response.json()
							.then(error => dispatch(AddingNewUserFailed(error)))
					}
				});
			res();
		})
	}
};

export const getMoreTags = (next: string)
	: ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
		return new Promise<void>(async (res) => {
			dispatch(ClearErrorMessages());
			const token = localStorage.getItem(randomTokenName) || sessionStorage.getItem(randomTokenName);
			await request(next, {
				method: 'GET',
				headers: {
					'Authorization': 'JWT ' + token,
				}
			})
				.then(res => res.json())
				.then(val => dispatch(loadMoreTags(val)));
			res();
		})
	}
};

export const clearTags = () => (dispatch: Dispatch<any>) => {
	return dispatch({type: TagsActionTypes.CLEAR_TAGS})
};

export const loadMoreTags = (val: any) => (dispatch: Dispatch<any>) => {
	return dispatch({type: TagsActionTypes.LOAD_MORE_TAGS, data: val})
};
