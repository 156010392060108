import React from 'react'
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';
import ScrollArea from 'react-scrollbar';
import ReactTooltip from 'react-tooltip'
import {Client} from "../../../../store/clients/types";

interface UserProps {
	clients: Client[],
	total: number,
	searchParam: string
	role: string
	page: number

	openAddNewClient(): void

	onOpenModal(client: Client): void

	openEdit(client: Client): void,

	handlePageChange(page: number): void,

	setSearchValue(search: string): void
}

interface UsersState {
	search: string
	checkResize: boolean
}

class ClientsTable extends React.Component<UserProps, UsersState> {

	state = {
		search: this.props.searchParam,
		checkResize: false,

	};

	componentDidMount(): void {
		this.setState({
			checkResize: true,
		});
	}

	componentWillReceiveProps(nextProps: Readonly<UserProps>, nextContext: any): void {
		if (nextProps.searchParam !== this.props.searchParam) {
			this.setState({
				search: nextProps.searchParam
			});
		}
	}

	handleChange = (e: React.FormEvent<HTMLInputElement>) => {
		this.setState({
			search: e.currentTarget.value,
		})
	};

	handleSearch = async (e: any) => {
		e.preventDefault();
		const {search} = this.state;
		await this.props.setSearchValue(search);
	};

	render() {
		const {clients, openAddNewClient, onOpenModal, openEdit} = this.props;
		return (
			<div className="page-wrapper">
				<div className="container">
					<div className="flex align-center justify-between pt-40">
						<h2 className="fw-500">Temporary IDs</h2>
						<button onClick={openAddNewClient} className="btn outline with-icon primary fw-600">
							<span className="icon-add icon"/>
							<span>Add New</span>
						</button>
					</div>
					{/*Search start*/}
					{(this.props.total !== 0 || this.props.searchParam !== '') &&
          <form onSubmit={this.handleSearch} className="textfield with-btn mt-15">
            <input
              type="text"
              placeholder="Search Temporary IDs"
              value={this.state.search}
              onChange={this.handleChange}
            />
            <button type='submit' className="no-style">
              <span className="icon-search font-lg"/>
            </button>
          </form>}
					{/*Search end*/}
					{/*Table start*/}
					{this.props.total === 0
						? (<div className="text-center empty-page">
								<div className="pb-100">
									<span className="icon-business icon blue empty-img">
										<span className="path1"/>
										<span className="path2"/>
									</span>
									<h3>No Temporary IDs</h3>
									<p className="pt-10 font-md">There are no Temporary IDs to display</p>
								</div>
							</div>
						)
						: (<>
							<div className="table-style user-table with-action">
								<ScrollArea
									speed={0.8}
									className="area"
									contentClassName="content"
									horizontal={true}
								>
									<div>
										<table>
											<thead>
											<tr>
												<th>Client Name</th>
												<th>Temporary ID</th>
												<th>Date Created</th>
												<th className="text-center">Actions</th>
											</tr>
											</thead>
											<tbody>
											{clients.map((client, outerIndex) => {
												const userVisibleInformation = [
													client.name,
													client.index,
													new Date(client.created).toDateString(),
												];
												return <tr key={outerIndex}>
													{userVisibleInformation.map((info, index) => (
														<td key={index}>
															<div id={'wrapper' + outerIndex + index} className="ellipsis text-wrapper">
																	<span
																		id={'child' + outerIndex + index}
																		className="ellipsis"
																		data-tip={true}
																		data-for={'' + outerIndex + index}
																	>
																		{info}
																	</span>
															</div>
															<ReactTooltip
																//@ts-ignore
																disable={index === 2 ||
																((document.getElementById('wrapper' + outerIndex + index) &&
																	document.getElementById('child' + outerIndex + index)) &&
																	//@ts-ignore
																	(document.getElementById('wrapper' + outerIndex + index).offsetWidth - document.getElementById('child' + outerIndex + index).offsetWidth > 5))}
																id={'' + outerIndex + index}
																effect='solid'
																type='dark'
															>
																<span>{info}</span>
															</ReactTooltip>
														</td>
													))}

													<td className="text-center">
														<div>
															<button onClick={() => openEdit(client)} className="px-10 inline-block no-style">
																<span className="icon-edit icon blue">
																	<span className="path1"/>
																	<span className="path2"/>
																</span>
															</button>
															{this.props.role === 'admin' &&
                              <button onClick={() => onOpenModal(client)} className="px-10 inline-block no-style">
																<span className="icon-delete icon red">
																	<span className="path1"/>
																	<span className="path2"/>
																</span>
                              </button>
															}
														</div>
													</td>
												</tr>
											})}
											</tbody>
										</table>
									</div>
								</ScrollArea>

							</div>

							{this.props.total !== 0 &&
              <div className="pt-30 text-center">
                <Pagination
                  total={this.props.total}
                  pageSize={15}
                  onChange={this.props.handlePageChange}
                  current={this.props.page}
                  className="inline-block"
                />
              </div>

							}
						</>)}
				</div>
				{/*Table end*/}
			</div>
		);
	}
}

export default ClientsTable;
