import React from 'react';


interface AddingTagsBottomBarProps {
	nextStep(): void
	previousStep(): void
}


class AddingTagsBottomBar extends React.Component<AddingTagsBottomBarProps> {

	render() {
		return (

			<div className="bar-bottom grey-bar">
				<div className="container-sm flex justify-end">
					<button onClick={this.props.previousStep} className="btn outline text text-left">
						<i className="icon icon-prev vertical-middle mr-5"></i>
						<span className="vertical-middle">Back</span>
					</button>
					<button onClick={this.props.nextStep} className="btn filled primary ripple  ml-15 right-icon text-right">
						<span className="vertical-middle">Next</span>
						<i className="icon icon-next vertical-middle ml-5"></i>
					</button>
				</div>
			</div>
	);
	}
}

export default AddingTagsBottomBar;