import React from 'react';


interface HeaderProps {
    startAddingTags(): void

    handleDeleteSelected(): void
}


class SelectImagesBottomBar extends React.Component<HeaderProps> {

    render() {
        return (
            <div className="bar-bottom blue-bar text-right">
                <div className="container ">
                    <ul className="inline-flex">
                        <li>
                            <button onClick={this.props.handleDeleteSelected} className="no-style cursor-pointer">
                                <span className="icon-delete icon mr-10">
                                  <span className="path1"/>
                                  <span className="path2"/>
                                </span>
                                <span>Delete</span>
                            </button>
                        </li>
                        <li>
                            <button onClick={this.props.startAddingTags} className="no-style cursor-pointer">
                                <span>Next</span>
                                <span className="icon-next icon ml-10"/>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default SelectImagesBottomBar;