import React, {Component} from 'react';
import {SessionState} from "../../../store/session/types";
import {Route, RouteComponentProps, withRouter} from "react-router";
import {ApplicationState, ConnectedReduxProps} from "../../../store";
import {compose} from "recompose";
import {connect} from "react-redux";
import {AddingTagsStep1Form} from "../components/UploadImages_Components/AddingTagsStep1Form";
import SelectImages from "../components/UploadImages_Components/SelectImages";
import {AddingTagsStep2Form} from "../components/UploadImages_Components/AddingTagsStep2Form";
import {AddingTagsStep3Form} from "../components/UploadImages_Components/AddingTagsStep3Form";
import SaveImages from "../components/UploadImages_Components/SaveImages";
import {
    clearClients,
    getInstallationNumbers,
    getMoreOptions,
    getTemporaryIDs,
    loadOptions
} from "../../../store/clients/actions";
import {ClientsState} from "../../../store/clients/types";
import {checkPictureDegree, uploadPictures} from "../../../store/pictures/actions";
import AquaSecLoader from "../components/Loader";
import {getDefaultTags, getMoreTags} from "../../../store/tags/actions";
import {TagsState} from "../../../store/tags/types";
import {toast} from "react-toastify";
import {IMAGE_LIMITATION, IMAGE_VALIDATION} from "../../../helpers/toastMessages";
import {beautifyTime} from "../../../helpers/BeautifierForPm";

interface PropsFromState {
    session: SessionState
    clients: ClientsState
    loading: boolean
    device: string
    defaultTags: TagsState
}

interface PropsFromDispatch {

    uploadPictures(files: UploadedFiles[],
                   tags: string,
                   p_type: string,
                   ins_num: string,
                   tem_id: string,
                   type: string,
                   unmount: boolean): void

    checkPictureDegree(picture: any): any

    clearClients(): void

    getTemporaryIDs(page?: number, search?: string): void

    getInstallationNumbers(page?: number, search?: string): void

    loadOptions(search: string, type: string): any

    getMoreOptions(next: string, type: string): void

    getMoreTags(next: string): void

    getDefaultTags(page: number, search: string, limit?: number): void

}

interface UploadImagesProps extends RouteComponentProps<any>, PropsFromState, PropsFromDispatch, ConnectedReduxProps {
    bodyOverlay(): void;

    selectedPictures: any
}

interface UploadImagesState {
    dropdownOpen: boolean
    openRightSidebar: boolean,
    openLeftSidebar: boolean,
    files: UploadedFiles[],
    pictureType: string,
    temporary_id: string,
    installation_number: string,
    type: string,
    tags: string[],
    defaultTags: string[],
    selectable: boolean,
    installation_number_options: { value: string, label: string }[],
    temporary_id_options: { value: string, label: string }[]
    installation_number_name: string,
    temporary_id_name: string,
    selected_count: number,
    save_button_clicked: boolean
    openGlobalFilter: boolean
    searchTypes: number
    loading: boolean
    showDefault: boolean
}

export type PicturePreview = {
    url: string,
    selected: boolean,
    id: number,
    is_normal: boolean | null,
    picture?: string,
    file: File,
}

export type UploadedFiles = {
    file: File,
    selected: boolean,
    id: number,
    is_normal: null | boolean;
    blob_url: string;
    picture?: string;
}

class UploadImages extends Component<UploadImagesProps, UploadImagesState> {

    state = {
        openRightSidebar: false,
        openLeftSidebar: false,
        dropdownOpen: false,
        files: [],
        pictureType: 'installation_number',
        temporary_id: '',
        installation_number: '',
        type: '',
        defaultTags: [],
        tags: [],
        selectable: false,
        installation_number_options: [],
        temporary_id_options: [],
        installation_number_name: '',
        temporary_id_name: '',
        selected_count: 0,
        save_button_clicked: false,
        openGlobalFilter: false,
        searchTypes: 0,
        loading: true,
        showDefault: false,
    };

    onUnload = async () => {
        if (this.state.save_button_clicked) {
            this.props.history.push(this.props.history.location.pathname, {
                save_was_clicked: true,
            })
        } else {
            const {files, pictureType, installation_number, temporary_id, type, tags} = this.state;
            const temp = tags.join(',');
            await this.props.uploadPictures(files, temp, pictureType, installation_number, temporary_id, type, true);
            return this.props.history.push(this.props.history.location.pathname, {
                save_was_clicked: false,
            })
        }
    };

    async componentDidMount() {
        if (this.props.location.state) {
            if (this.props.location.state.save_was_clicked === true) {
                this.props.history.push('/gallery');
            } else if (this.props.location.state.save_was_clicked === false) {
                this.props.history.push('/gallery');
            } else if (this.props.location.state === "selectedPictures") {
                window.addEventListener("beforeunload", this.onUnload);
                this.handleFileChange(this.props.selectedPictures);
            }
        } else {
            window.addEventListener("beforeunload", this.onUnload);
            this.setState({
                loading: false,
            });
        }

        await this.props.getInstallationNumbers(1, '');
        await this.props.getTemporaryIDs(1, '');
        await this.props.getDefaultTags(1, '', 30);
        this.setState({
            defaultTags: [this.props.session.name + '  ' + this.props.session.surname, new Date().toDateString().split(' ').slice(1).join(' ')],
            installation_number_options: this.props.clients.installation_number_paginated.results.map((client: any) => ({
                value: client.id,
                label: client.name
            })),
            temporary_id_options: this.props.clients.temporary_id_paginated.results.map((client: any) => ({
                value: client.id,
                label: client.name
            }))
        });

    }

    getMoreOptions = async (next: string, type: string) => {
        if (next) {
            if (type === 'installation_number') {
                await this.props.getMoreOptions(next, 'installation_number');
                this.setState({
                    installation_number_options: this.props.clients.installation_number_paginated.results.map((client: any) => ({
                        value: client.id,
                        label: client.name
                    })),
                })
            } else {
                await this.props.getMoreOptions(next, 'temporary_id');
                this.setState({
                    temporary_id_options: this.props.clients.temporary_id_paginated.results.map((client: any) => ({
                        value: client.id,
                        label: client.name
                    })),
                })
            }
        }
    };

    getMoreTags = async (next: string) => {
        if (next) {
            await this.props.getMoreTags(next);
        }
    };

    async componentWillUnmount() {
        window.removeEventListener("beforeunload", this.onUnload);
        await this.props.clearClients();
        if (this.state.save_button_clicked) {
        } else {
            const {files, pictureType, installation_number, temporary_id, type, tags} = this.state;
            const temp = tags.join(',');
            await this.props.uploadPictures(files, temp, pictureType, installation_number, temporary_id, type, true);
        }
    }

    handleSave = async () => {
        const {files, pictureType, installation_number, temporary_id, type, tags} = this.state;
        const temp = tags.join(',');
        this.setState({
            save_button_clicked: true,
        });
        await this.props.uploadPictures(files, temp, pictureType, installation_number, temporary_id, type, false);
        if (this.state.pictureType === "installation_number") {
            this.props.history.replace(`/gallery/installation_number/${escape(this.state.installation_number)}`);
        } else if (this.state.pictureType === "temporary_id") {
            this.props.history.replace(`/gallery/temporary_id/${escape(this.state.temporary_id)}`);
        } else {
            this.props.history.replace('/gallery/no_number');
        }
    };

    onDropdownOpen = () => {
        this.setState((prevState: UploadImagesState) => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    };


    //@ts-ignore
    checkPictureDegree = async (picture: UploadedFiles): Promise<boolean> => {
        if (picture.is_normal === null) {
            const check = await this.props.checkPictureDegree(picture.file);
            this.setState((prevState: UploadImagesState) => {
                pictures: prevState.files.map(file => {
                    if (file.id === picture.id) {
                        file.is_normal = check;
                    }
                    return file;
                })
            });
            return check;
        } else {
            return picture.is_normal;
        }
    };

    resizeMe(img: HTMLImageElement, type: string): Promise<string> {

        return new Promise((res) => {
            const canvas = document.createElement('canvas');
            const max_width = 800;
            const max_height = 800;

            let width = img.width;
            let height = img.height;

            if (width > height) {
                if (width > max_width) {
                    height = Math.round(height *= max_width / width);
                    width = max_width;
                }
            } else {
                if (height > max_height) {
                    width = Math.round(width *= max_height / height);
                    height = max_height;
                }
            }

            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext("2d");
            ctx!.drawImage(img, 0, 0, width, height);
            // canvas.toBlob((blob) => {
            // 	const urlCreator = window.URL || (window as any).webkitURL;
            // 	const blob_url = urlCreator.createObjectURL(blob);
            // 	res(blob_url);
            // }, type, 0.7);
            res(canvas.toDataURL(type, 0.7))
        });
    }

    processfile(blob_url: string, type: string): Promise<string> {
        return new Promise(res => {
            var image = new Image();
            image.onload = () => {
                const resized = this.resizeMe(image, type);
                res(resized);
            }
            image.src = blob_url;
        });
    }

    handleFileChange = (chosenFiles: FileList) => {
        const files: UploadedFiles[] = [];
        for (let i = 0; i < chosenFiles.length; i++) {
            files.push({
                file: chosenFiles[i],
                selected: false,
                id: i,
                is_normal: null,
                blob_url: ''
            });
        }
        const selectedFilesCount = this.state.files.length + files.length;
        if (selectedFilesCount > 20) {
            toast.dismiss();
            toast(() =>
                <div className="toast-style error">
                    <div className="left-side">
							<span className="icon-error icon">
								<span className="path1"/>
								<span className="path2"/>
							</span>
                    </div>
                    <div className="right-side">
                        <p>{IMAGE_LIMITATION}</p>
                    </div>
                </div>
            );
            this.setState({loading: false});
            return;
        }
        const invalid = files.find(files => {
            return !files.file.type.includes("image")
        });
        if (invalid) {
            toast.dismiss();
            toast(() =>
                <div className="toast-style error">
                    <div className="left-side">
							<span className="icon-error icon">
								<span className="path1"/>
								<span className="path2"/>
							</span>
                    </div>
                    <div className="right-side">
                        <p>{IMAGE_VALIDATION}</p>
                    </div>
                </div>
            );
            this.setState({loading: false});
            return;
        }
        const promises = files.map((file: UploadedFiles): Promise<void> => (
            new Promise(res => {
                const reader = new FileReader();
                reader.readAsArrayBuffer(file.file);
                reader.onloadend = async () => {
                    const blob = new Blob([reader.result as ArrayBuffer]);
                    const urlCreator = window.URL || (window as any).webkitURL;
                    const blob_url = urlCreator.createObjectURL(blob);
                    const url = await this.processfile(blob_url, file.file.type);
                    file.blob_url = url;
                    res();
                };
            })
        ));
        Promise.all(promises).then(() => {
            this.setState((prevState: UploadImagesState) => ({
                files: [...prevState.files, ...files],
                loading: false,
            }))
        })

    };


    handleSelectPicture = (id: number) => {
        if (id === -1) {
            this.setState((prevState: UploadImagesState) => {
                const files: UploadedFiles[] = prevState.files.map(file => {
                    file.selected = true;
                    return file;
                });
                return {
                    files,
                    selected_count: files.length
                }
            });
        } else {
            this.setState((prevState: UploadImagesState) => {
                const files: UploadedFiles[] = [...prevState.files];
                let incOrder = !files[id].selected ? 1 : -1;
                const count = prevState.selected_count + incOrder;
                files[id].selected = !files[id].selected;
                return {
                    files,
                    selected_count: count,
                }
            });
        }
    };

    handleSelectable = () => {
        this.setState((prevState: UploadImagesState) => ({
            selectable: !prevState.selectable,
            selected_count: 0,
            files: prevState.files.map(file => {
                file.selected = false;
                return file
            })
        }))
    };

    handleDeleteSelected = () => {
        this.setState((prevState: UploadImagesState) => ({
            files: prevState.files.filter(item => !item.selected),
            selected_count: 0,
        }), () => {
            if (this.state.files.length === 0) {
                this.setState({
                    pictureType: "installation_number",
                    installation_number: '',
                    installation_number_name: "",
                    temporary_id: "",
                    temporary_id_name: "",
                    type: '',
                    tags: [],
                });
            }
        });
    };

    deleteSelectedTag = (index: number) => {
        let val = this.state.tags;
        val.splice(index, 1);
        this.setState({
            tags: val
        })
    };

    bodyOverlay = () => {
        document.body.classList.toggle('overlay');
        // const element = document.getElementById('main');
        // if (element) {
        // 	element.classList.toggle('overlay');
        // }
    };

    toggleShowDefault = (tags: string[]) => {
        this.setState((prevState: UploadImagesState) => ({
            showDefault: !prevState.showDefault,
            tags,
        }));
    };

    startAddingTags = () => {
        this.props.history.replace('/upload/step1')
    };

    stepTwo = (pictureType: string,
               installation_number: string,
               installation_number_name: string,
               temporary_id: string,
               temporary_id_name: string) => {
        this.setState({
            pictureType,
            installation_number,
            installation_number_name,
            temporary_id,
            temporary_id_name,
        });
        this.props.history.replace('/upload/step2');
    };

    initialStep = (pictureType: string,
                   installation_number: string,
                   installation_number_name: string,
                   temporary_id: string,
                   temporary_id_name: string) => {
        this.setState({
            pictureType,
            installation_number,
            installation_number_name,
            temporary_id,
            temporary_id_name,
        });
        this.props.history.replace('/upload');
    };

    stepThree = (type: string) => {
        this.setState({
            type,
        });
        this.props.history.replace('/upload/step3');
    };

    stepOne = (type: string) => {
        this.setState({
            type,
        });
        this.props.history.replace('/upload/step1');
    };

    stepFour = async (tags: string[]) => {
        this.setState({tags});
        this.props.history.replace('/upload/step4');
    };

    stepTreeToTwo = async (tags: string[]) => {
        this.setState({tags});
        this.props.history.replace('/upload/step2');
    };

    render() {

        return (
            this.state.loading
                ? <AquaSecLoader/>
                : (
                    <>
                        {/*Select images page*/}
                        <div className='page-wrapper'>
                            <Route exact path={'/upload'} render={() => <SelectImages
                                checkPictureDegree={this.checkPictureDegree}
                                files={this.state.files}
                                handleSelectable={this.handleSelectable}
                                handleFileChange={this.handleFileChange}
                                handleSelectPicture={this.handleSelectPicture}
                                handleDeleteSelected={this.handleDeleteSelected}
                                startAddingTags={this.startAddingTags}
                                selectable={this.state.selectable}
                                selected_count={this.state.selected_count}
                                device={this.props.device}
                            />}/>
                            {/*Select images page*/}

                            {/*first page*/}
                            <Route path='/upload/step1' render={() => <AddingTagsStep1Form
                                nextInstallation={this.props.clients.installation_number_paginated.next}
                                nextTemporary={this.props.clients.temporary_id_paginated.next}
                                loadOptionsInstallation={(search: string) => this.props.loadOptions(search, 'installation_number')}
                                loadOptionsTemporary={(search: string) => this.props.loadOptions(search, 'temporary_id')}
                                installation_number_options={this.state.installation_number_options}
                                temporary_id_options={this.state.temporary_id_options}
                                previousStep={this.initialStep}
                                nextStep={this.stepTwo}
                                getMore={this.getMoreOptions}
                                initialValues={{
                                    picture_type: this.state.pictureType,
                                    temporary_id: {value: this.state.temporary_id, label: this.state.temporary_id_name},
                                    installation_number: {
                                        value: this.state.installation_number,
                                        label: this.state.installation_number_name
                                    }
                                }}
                            />}/>
                            {/*first page*/}
                            {/*second page*/}
                            <Route path='/upload/step2' component={() => <AddingTagsStep2Form
                                previousStep={this.stepOne}
                                nextStep={this.stepThree}
                                initialValues={{type: this.state.type}}
                            />}/>
                            {/*second page*/}

                            {/*third page*/}
                            <Route path='/upload/step3' component={() => <AddingTagsStep3Form
                                previousStep={this.stepTreeToTwo}
                                nextStep={this.stepFour}
                                initialValues={{tags: this.state.tags, tagValue: ''}}
                                showDefault={this.state.showDefault}
                                toggleShowDefault={this.toggleShowDefault}
                                defaultTags={this.props.defaultTags.results}
                                next={this.props.defaultTags.next}
                                loadMoreTags={this.getMoreTags}
                            />}/>
                            {/*third page*/}

                            {/*forth page*/}
                            {/*
						//@ts-ignore*/}
                            <Route path='/upload/step4' render={() => <SaveImages
                                pictures={this.state.files.filter((pic: UploadedFiles) => pic.selected)}
                                handleEditClick={() => this.props.history.push("/upload/step1")}
                                handleSave={this.handleSave}
                                defaultTags={this.state.defaultTags}
                                tags={this.state.tags}
                                type={this.state.type}
                                picture_type={this.state.pictureType}
                                installation_number={this.state.installation_number_name}
                                temporary_id={this.state.temporary_id_name}
                                deleteSelectedTag={this.deleteSelectedTag}
                                dropdownOpen={this.state.dropdownOpen}
                                onDropdownOpen={this.onDropdownOpen}
                            />}/>
                            {/*forth page*/}
                        </div>
                    </>)
        );
    }
}

const mapStateToProps = (state: ApplicationState): PropsFromState => {
    return {
        loading: state.shared.loading,
        session: state.session,
        clients: state.clients,
        device: state.shared.device,
        defaultTags: state.tags,
    }
};

const mapDispatchToProps: PropsFromDispatch = {
    uploadPictures,
    checkPictureDegree,
    clearClients,
    getInstallationNumbers,
    getTemporaryIDs,
    loadOptions,
    getMoreOptions,
    getDefaultTags,
    getMoreTags,
};

export default compose<UploadImagesProps, {}>(
    withRouter,
    connect<PropsFromState, PropsFromDispatch, {}, ApplicationState>(
        mapStateToProps,
        mapDispatchToProps,
    )
)(UploadImages);
