import { Reducer } from 'redux';
import { ClientsState, ClientsActionTypes } from './types';

export const ClientsInitialState: ClientsState = {
	installation_number:[],
	temporary_id_paginated: {
		count: 0,
		next: null,
		previous: null,
		results: []
	},
	temporary_id: [],
	installation_number_with_pictures: [],
	temporary_id_with_pictures: [],
	installation_number_paginated: {
		count: 0,
		next: null,
		previous: null,
		results: []
	},
};

const clientReducer: Reducer<ClientsState> = (state: ClientsState = ClientsInitialState, action) => {
	switch (action.type) {
		case ClientsActionTypes.GET_TEMPORARY_CLIENTS:
			return {
				...state,
				temporary_id: action.data,
			};
		case ClientsActionTypes.GET_INSTALLATION_CLIENTS:
			return {
				...state,
				installation_number_paginated: action.data,
			};
		case ClientsActionTypes.GET_INSTALLATION_WITH_PICTURES:
			return {
				...state,
				installation_number_with_pictures: action.data
			};
		case ClientsActionTypes.GET_TEMPORARY_WITH_PICTURES:
			return {
				...state,
				temporary_id_with_pictures: action.data
			};
		case ClientsActionTypes.GET_TEMPORARY_CLIENTS_PAGINATED:
			return  {
				...state,
				temporary_id_paginated: action.data
			};
		case ClientsActionTypes.GET_INSTALLATION_CLIENTS_PAGINATED:
			return {
				...state,
				installation_number_paginated: {
					...action.data,
					results: [...state.installation_number_paginated.results, ...action.data.results]
				}
			};
		case ClientsActionTypes.GET_MORE_TEMPORARY:
			return {
				...state,
				temporary_id_paginated: {
					...action.data,
					results: [...state.temporary_id_paginated.results,...action.data.results]
				}
			};
		case ClientsActionTypes.CLEAR_INSTALLATION_NUMBERS:
			return {
				...state,
				installation_number_paginated: {
					count: 0,
					next: null,
					previous: null,
					results: []
				},
			};
		case ClientsActionTypes.CLEAR_CLIENTS:
			return ClientsInitialState;
		default:
			return state;
	}
}

export default clientReducer;