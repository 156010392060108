import React from 'react';
import {withFormik, FormikProps} from 'formik';

import {Step3Schema} from "../../../../helpers";
import SaveTagBottomBar from "./SaveTagBottomBar";
import PerfectScrollbar from "react-perfect-scrollbar";

export interface FormValues {
    tags: string[]
    tagValue: string
}

interface OtherProps {
    defaultTags: any[],
    next: string,
    showDefault: boolean

    handleEditClick(): void,

    handleSave(tags: string[], from: any): void

    toggleShowDefault(tags: string[]): void

    loadMoreTags(next: string): void
}

const InnerForm = (props: OtherProps & FormikProps<FormValues>) => {
    const {
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        setValues,
        handleEditClick,
        handleSave,
        showDefault,
        next,
        loadMoreTags,
        defaultTags,
        toggleShowDefault,
    } = props;


    // @ts-ignore
    // @ts-ignore
    return (
        <div onClick={() => {
            if (showDefault) {
                toggleShowDefault(values.tags);
            }
        }}>
            <form onSubmit={handleSubmit} className="container-sm py-100 p-sm-0">
                <div className="box">
                    <div className="stepper">
                        <div className="stepper-header">
                            <div className="stepper-header-bottom">
                                <h3>Add Tags</h3>
                            </div>
                        </div>
                        <div className="stepper-body pb-100">
                            <div className="px-80 px-sm-15">
                                <div className="tag-dropdown">
                                    <div className="textfield with-btn with-dropdown">
                                        <input
                                            type="text"
                                            id="tags"
                                            name="tagValue"
                                            value={values.tagValue}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder="Enter tag here"
                                        />
                                        <span onClick={() => {
                                            if (!showDefault) {
                                                toggleShowDefault(values.tags);
                                            }
                                        }}
                                              className="icon-arrow-dropdown icon"/>
                                        <button type='submit' className="no-style">
                                            <span className="icon-add icon"/>
                                        </button>
                                    </div>
                                    {showDefault &&
                                    <div onClick={e => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }}>
                                      <PerfectScrollbar
                                        onYReachEnd={() => {
                                            if (next) {
                                                loadMoreTags(next);
                                            }
                                        }}
                                        className="dropdown-content"
                                          // contentClassName="content"
                                      >
                                        <h5 className="fw-500 color-text-70 mb-20">Default Tags</h5>
                                        <ul className="tag-group pb-30">
                                            {defaultTags.map((tag, index) => {
                                                return (<li onClick={() => {
                                                    setValues({
                                                        tagValue: values.tagValue,
                                                        tags: [...values.tags, tag.name]
                                                    })
                                                }}
                                                            key={index}
                                                            className={"tag-item" + (values.tags.includes(tag.name) ? " selected" : '')}>
														<span
                                                            className="tag-text">{tag.name}</span>
                                                </li>)
                                            })}
                                        </ul>
                                      </PerfectScrollbar>
                                    </div>}
                                </div>
                                <ul className="tag-group">
                                    {values.tags.map((tag, index) => (
                                        <li key={index} className="tag-item with-close">
                                            <span className="tag-text">{tag}</span>
                                            <span onClick={() => {
                                                values.tags.splice(index, 1);
                                                setValues({
                                                    tagValue: values.tagValue, tags: values.tags
                                                })
                                            }} className="icon-close icon"/></li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </form>
            <SaveTagBottomBar
                handleEditClick={handleEditClick}
                handleSave={() => handleSave(values.tags, "this.props.match.params.type")}
            />
        </div>
    )
};

interface EditPicturesStep3Form {
    initialValues: FormValues,

    showDefault: boolean

    defaultTags: any[],
    next: string,

    handleEditClick(): void,

    handleSave(tags: string[], from: any): void

    toggleShowDefault(tags: string[]): void

    loadMoreTags(next: string): void
}

export const AddTags = withFormik<EditPicturesStep3Form, FormValues>({

    mapPropsToValues: props => {
        const {initialValues} = props;
        return {
            tags: initialValues.tags,
            tagValue: initialValues.tagValue
        }
    },

    validationSchema: Step3Schema,

    handleSubmit(
        {tags, tagValue}: FormValues,
        {props, setValues, setSubmitting, setErrors}
    ) {
        setValues({tagValue: '', tags: [...tags, tagValue]});
    },

})(InnerForm);
