import React from 'react';
import {withFormik, FormikProps} from 'formik';
import Logo from '../../../../assets/img/logo.svg'

import {SignInSchema} from '../../../../helpers';
import {Link} from "react-router-dom";
import {FORGOT_PASSWORD} from "../../../../helpers/routes";

interface FormValues {
    email: string,
    password: string,
    remember: boolean
}

interface OtherProps {
    onSubmit(email: string, password: string, remember: boolean): void;
}

const InnerForm = (props: OtherProps & FormikProps<FormValues>) => {
    const {
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
    } = props;

    return (
        <form onSubmit={handleSubmit} className="sign-form sign-in">
            <div className="sign-form-inner">
                <div className="sign-header">
                    <img src={Logo} alt="Aquasecurity"/>
                </div>
                <div className="sign-body">
                    <h2 className="fw-500 mb-40 mb-sm-35">Sign In</h2>
                    <div className={"textfield with-icon" + ((errors.email && touched.email) ? ' error-field' : '')}>
                        <input
                            type="text"
                            placeholder="Email"
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                        />
                        <span className="icon-user icon">
							<span className="path1"/>
							<span className="path2"/>
							<span className="path3"/>
                        </span>
                        {errors.email && touched.email ? (
                            <p className="error-text">{errors.email}</p>
                        ) : null}
                    </div>
                    <div
                        className={"textfield with-icon" + ((errors.password && touched.password) ? ' error-field' : '')}>
                        <input
                            type="password"
                            placeholder="Password"
                            name='password'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                        />
                        <span className="icon-lock icon">
            <span className="path1"/>
            <span className="path2"/>
          </span>
                        {errors.password && touched.password ? (
                            <p className="error-text">{errors.password}</p>
                        ) : null}
                    </div>
                    <div className="flex align-center justify-between">
                        <div className="checkbox">
                            <label htmlFor="remember">
                                <input
                                    name='remember'
                                    id='remember'
                                    type="checkbox"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    checked={values.remember}
                                />
                                <span className="checkmark vertical-middle"/>
                                <span className=" ml-15 vertical-middle color-text-70">Remember Credentials</span>
                            </label>
                        </div>
                        <div>
                            <Link to={FORGOT_PASSWORD} className="link">Reset Password</Link>
                            {/*<a href="#" className="link">Reset Password</a>*/}
                        </div>
                    </div>
                    <button type='submit' className="btn filled primary ripple full-width mt-60"><span>Log In</span>
                    </button>
                </div>
            </div>
        </form>
    );
};

interface MyFormProps {
    initialValues?: FormValues,
    onSubmit(email: string, password: string, remember: boolean): void,
}

export const SignInForm = withFormik<MyFormProps, FormValues>({
    mapPropsToValues: () => ({
        email: '',
        password: '',
        remember: false,
    }),

    validationSchema: SignInSchema,

    handleSubmit(
        {email, password, remember}: FormValues,
        {props}
    ) {
        props.onSubmit(email, password, remember);
    },

})(InnerForm);
