import React from 'react';
import {withFormik, FormikProps} from 'formik';
import AddingTagsBottomBar from "../UploadImages_Components/AddingTagsBottomBar";

export interface FormValues {
    type: string
}

interface OtherProps {
    previousStep(): void,

    nextStep(type: string): void
}

interface InnerFormProps extends OtherProps, FormikProps<FormValues> {

}

class InnerForm extends React.Component<InnerFormProps> {
    state = {
        initialType: this.props.values.type
    };

    render() {
        const {
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            previousStep,
            nextStep
        } = this.props;
        return (
			<>
                <form onSubmit={handleSubmit} className="container-sm py-100 p-sm-0">
                    <div className="box">
                        <div className="stepper">
                            <div className="stepper-header">
                                <ul className="stepper-header-top">
                                    <li className="active"><span className="icon icon-checked"/></li>
                                    <li className="active"><span>2</span></li>
                                    <li><span>3</span></li>
                                </ul>
                                <div className="stepper-header-bottom">
                                    <h4 className="fw-400 text-uppercase">step 2</h4>
                                    <h3>Select Purpose</h3>
                                </div>
                            </div>
                            <div className="stepper-body pb-100">
                                <ul className="list-item">
                                    <li className={"radio-btn" + (values.type === 'for_future_information' ? ' active' : '')}>
                                        <label htmlFor="future_info">
                                            <div className="align-center flex">
                                                <input
                                                    type="radio"
                                                    id="future_info"
                                                    name="type"
                                                    value="for_future_information"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    checked={values.type === 'for_future_information'}
                                                />
                                                <span className="checkmark"/>
                                                <span className="pl-15">Future Information</span>
                                            </div>

                                        </label>
                                    </li>
                                    <li className={"radio-btn" + (values.type === 'intervention' ? ' active' : '')}>
                                        <label htmlFor="surbhogi">
                                            <div className="align-center flex">
                                                <input
                                                    type="radio"
                                                    id="surbhogi"
                                                    name="type"
                                                    value="intervention"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    checked={values.type === 'intervention'}
                                                />
                                                <span className="checkmark"/>
                                                <span className="pl-15">Intervention</span>
                                            </div>

                                        </label>
                                    </li>
                                    <li className={"radio-btn" + (values.type === 'quotation' ? ' active' : '')}>
                                        <label htmlFor="installation">
                                            <div className="align-center flex">
                                                <input
                                                    type="radio"
                                                    id="installation"
                                                    name="type"
                                                    value="quotation"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    checked={values.type === 'quotation'}
                                                />
                                                <span className="checkmark"/>
                                                <span className="pl-15">Quotation</span>
                                            </div>
                                        </label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </form>
                <AddingTagsBottomBar
                    previousStep={previousStep}
                    nextStep={() => {
                        if (values.type) {
                            nextStep(values.type)
                        }
                    }}
                />
			</>
        )
    }
}

interface EditPicturesStep2Form {
    initialValues: FormValues,

    nextStep(type: string): void,

    previousStep(): void,
}

export const EditPicturesStep2Form = withFormik<EditPicturesStep2Form, FormValues>({
    enableReinitialize: true,

    mapPropsToValues: props => {
        const {initialValues} = props;
        return {
            type: initialValues.type,
        }
    },

    // validationSchema: UserFormSchema,

    handleSubmit(
    ) {

    },

})(InnerForm);
