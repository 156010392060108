import {combineReducers, Dispatch, Action, AnyAction} from 'redux';

import usersReducer, {UserInitialState} from './users/reducer';
import { SessionState } from './session/types';
import sessionReducer, {SessionInitialState} from './session/reducer';
import errorMessageReducer from './errorMessage/reducer';
import {Users} from "./users/actions";
import {SharedState} from "./shared/types";
import sharedReducer, {SharedInitialState} from "./shared/reducer";
import {ClientsState} from "./clients/types";
import clientReducer, {ClientsInitialState} from "./clients/reducer";
import picturesReducer, {PicturesInitialState} from "./pictures/reducer";
import {PicturesState} from "./pictures/types";
import tagsReducer, {TagsInitialState} from "./tags/reducer";
import {TagsState} from "./tags/types";



export const ApplicationInitialState: ApplicationState = {
  users: UserInitialState,
  session: SessionInitialState,
  errorMessage: {},
  shared: SharedInitialState,
  clients: ClientsInitialState,
  pictures: PicturesInitialState,
  tags: TagsInitialState,
};

export interface ApplicationState {
  users: Users,
  session: SessionState,
  errorMessage: object,
  shared: SharedState,
  clients: ClientsState,
  pictures: PicturesState,
  tags: TagsState
}

export interface ConnectedReduxProps<A extends Action = AnyAction> {
  dispatch: Dispatch<A>
}


export const appReducer = combineReducers({
  users: usersReducer,
  session: sessionReducer,
  errorMessage: errorMessageReducer,
  shared: sharedReducer,
  clients: clientReducer,
  pictures: picturesReducer,
  tags: tagsReducer,
});

export const rootReducer = (state: ApplicationState, action: Action<any>) => {
  if(action.type === '@@session/LOG_OUT') {
    state = ApplicationInitialState;
  }
  return appReducer(state, action);
};
