import React from 'react';
import EmptyGallery from "../../../../assets/img/gallery/empty-gallery.svg";
import {beautifyPictureType} from "../../../../helpers/BeautifierForPm";
import {AWS, BIGSIZE} from "../../../../helpers/image-resize-endpoints";

interface ImageInfoProps {
    userRole: string;
    userId: number;
    picture: any;
    recentlyDeleted?:boolean;
    device: string;

    handleEdit(picture: any): void

    handleDeleteSelected(): void;

    onCloseSideBar(): void;
}

interface ImageInfoState {
    openDelete: boolean
}

class ImageInfo extends React.Component<ImageInfoProps, ImageInfoState> {
    state = {
      openDelete: false,
    };


    render() {
        const {handleEdit, handleDeleteSelected, picture, userId, userRole, recentlyDeleted, device } = this.props;
        const pictureName = picture && picture.picture && picture.picture.split('/').pop();

        return  picture.user? (
            <div>
                <img className="sidebar-img" src={AWS + BIGSIZE + '/' + pictureName} alt=""/>
                <div className="pt-25">
                    <p className="mb-5">Author</p>
                    <h4>{`${picture.user.first_name} ${picture.user.last_name}`}</h4>
                </div>
                <div className="pt-15">
                    <p className="mb-5">Image type</p>
                    <h4>{beautifyPictureType(picture.type)}</h4>
                </div>
                {(userRole !== 'worker' || userId === picture.user.id) && !recentlyDeleted && (
                    <div className="pt-25">
                        <button
                            onClick={() => handleEdit(picture)}
                            className="link no-style with-icon primary fw-600 mr-35"
                        >
                            <span className="icon-edit icon blue">
								<span className="path1"/>
								<span className="path2"/>
							</span>
                            <span className="vertical-middle">Edit</span>
                        </button>
                        <button
                            onClick={handleDeleteSelected}
                            className="link no-style color-warning with-icon primary fw-600"
                        >
                            <span className="icon-delete icon red">
								<span className="path1"/>
								<span className="path2"/>
							</span>
                            <span className="vertical-middle">Delete</span>
                        </button>
                    </div>
                )}

                            <h3 className="fw-500 pt-25">Tags</h3>
                            <ul className="tag-group pt-15">
                                <li className="tag-item">
                                    <span>{picture.user.first_name + " " + picture.user.last_name}</span>
                                </li>
                                <li className="tag-item">
                                    <span>{new Date(picture.created).toDateString().split(' ').slice(1).join(' ')}</span>
                                </li>
                                <li className="tag-item">
                                    <span>{picture.is_normal ? 'Normal' : '360'}</span>
                                </li>
                                {
                                    picture.tags.length > 0 && (
                                        picture.tags.map( (tag: any, index: number) => (
                                            <li key={index} className="tag-item">
                                                <span className="tag-text">{tag.name}</span>
                                            </li>
                                        ))
                                    )
                                }
                            </ul>

                <button className="no-style sidebar-right-btn drawer-btn" onClick={this.props.onCloseSideBar}>
                    <span className="icon-close"></span>
                </button>
            </div>

        ): null
    }
}

export default ImageInfo;
