import React, {Component} from 'react';
import {Route, RouteComponentProps, withRouter, Redirect} from "react-router";
import UploadImages from "./pages/layout/pages/UploadImages";
import AdminUsers from "./pages/layout/pages/Admin_Users";
import Gallery from "./pages/layout/pages/Gallery";
import Pending from "./pages/layout/pages/Pending";
import MyProfile from "./pages/layout/pages/MyProfile";
import Recently_Deleted from "./pages/layout/pages/Recently_Deleted";
import Clients from "./pages/layout/pages/Clients";
import {ApplicationState, ConnectedReduxProps} from "./store";
import {compose} from "recompose";
import {connect} from "react-redux";
import {SessionState} from "./store/session/types";
import {verifyToken} from "./store/users/actions";
import Header from "./pages/layout/components/Header";
import Sidebar from "./pages/layout/components/Sidebar";
import {GlobalFilter, GlobalFormInitialValues} from "./pages/layout/components/GlobalFilter";
import {ClientFormInitialValues, ClientFormValues} from "./pages/layout/components/Clients/ClientsForm";
import {getUser, logOut} from "./store/session/actions";
import {clearPictures, getPicturesBy} from "./store/pictures/actions";
import {randomTokenName} from "./App";
import {changeDevice, setCount, setGlobalSearchValues, setSearchValue} from "./store/shared/actions";
import * as ROUTES from './helpers/routes';
import Search_In_Gallery from "./pages/layout/pages/Search_In_Gallery";
import AquaSecLoader from "./pages/layout/components/Loader";
import Tags from "./pages/layout/pages/Tags";
import {toast} from "react-toastify";
import {IMAGE_LIMITATION} from "./helpers/toastMessages";


interface PropsFromState {
    session: SessionState
    errorMessage: any
    searchValue: string
    loading: boolean
    count: number
    device: string
}

interface PropsFromDispatch {
    verifyToken(token: string): void

    getUser(): any

    getPicturesBy(queryParams: string): void

    logOut(): void

    clearPictures(): void

    setSearchValue(searchParam: string): void

    setGlobalSearchValues(search: string,
                          normal: boolean,
                          threeSixty: boolean,
                          no_number_boolean: boolean,
                          temporary_id_boolean: boolean,
                          installation_number_boolean: boolean,
                          for_future_information: boolean,
                          quotation: boolean,
                          intervention: boolean,
                          startDate: Date,
                          endDate: Date): void

    setCount(count: number): void

    changeDevice(): void
}

interface RoutesProps extends RouteComponentProps<any>, PropsFromState, PropsFromDispatch, ConnectedReduxProps {

}

interface RoutesState {
    openLeftSidebar: boolean,
    openRightSidebar: boolean,
    openInfoBar: boolean,
    initialValues: ClientFormValues,
    folders: [],
    searchParam: string,
    openGlobalFilter: boolean
    filterParams: string
    searchTypes: number,
    type: string,
    singlePicture: any,
    installation_number_options: { value: string, label: string }[],
    temporary_id_options: { value: string, label: string }[]
    temporary_id: number,
    installation_number: string,
    installation_number_name: string,
    temporary_id_name: string,
    pictureType: string
    searchInstallationFolderValue: string
    searchTemporaryFolderValue: string
    disabled: boolean,
    open: boolean
    openForm: boolean
    title: string
    deleteClicked: boolean
    deletionToken: string
    page: number
    activeSidebar: string
    pageSize: number,
    selectedFiles: any,
}

class PrivateRoutes extends Component<RoutesProps, RoutesState> {

    state: RoutesState = {
        openLeftSidebar: false,
        openRightSidebar: false,
        openInfoBar: false,
        openGlobalFilter: false,
        initialValues: ClientFormInitialValues,
        folders: [],
        searchParam: '',
        filterParams: '',
        searchTypes: 0,
        type: '',
        singlePicture: {tags: [], type: ''},
        installation_number_options: [],
        temporary_id_options: [],
        temporary_id: NaN,
        installation_number: '',
        installation_number_name: '',
        temporary_id_name: '',
        pictureType: 'no_number',
        searchInstallationFolderValue: '',
        searchTemporaryFolderValue: '',
        disabled: true,
        open: false,
        openForm: false,
        title: '',
        deleteClicked: false,
        deletionToken: '',
        page: 1,
        activeSidebar: '',
        pageSize: 40,
        selectedFiles: [],
    };

    async componentDidMount() {
        if (localStorage.getItem(randomTokenName) || sessionStorage.getItem(randomTokenName)) {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
                this.props.changeDevice();
                await this.setState({
                    pageSize: 21,
                })
            }
            const user = await this.props.getUser();

            if (this.props.errorMessage.token || user.is_deleted) {
                await localStorage.clear();
                await sessionStorage.clear();
                this.props.history.push(ROUTES.SIGN_IN);
            }
        }
    }

    handleGlobalSearchAndFilter = async (
        search: string,
        normal: boolean,
        threeSixty: boolean,
        no_number_boolean: boolean,
        temporary_id_boolean: boolean,
        installation_number_boolean: boolean,
        for_future_information: boolean,
        quotation: boolean,
        intervention: boolean,
        startDate: Date,
        endDate: Date
    ) => {
        const searchParam = search;
        let filterParams = `page=1&page_size=${this.state.pageSize}&`;
        let optionsCount = 0;
        if (normal) {
            ++optionsCount;
            filterParams += 'is_normal=True&';
        }
        if (threeSixty) {
            ++optionsCount;
            filterParams += 'is_normal=False&';
        }
        if (normal && threeSixty) {
            optionsCount = 2;
            filterParams = '';
        }
        if (for_future_information) {
            ++optionsCount;
            filterParams += 'for_future_information_boolean=True&';
        }
        if (quotation) {
            ++optionsCount;
            filterParams += 'quotation_boolean=True&';
        }
        if (intervention) {
            ++optionsCount;
            filterParams += 'intervention_boolean=True&';
        }
        if (no_number_boolean) {
            ++optionsCount;
            filterParams += 'no_number_boolean=True&'
        }
        if (temporary_id_boolean) {
            ++optionsCount;
            filterParams += 'temporary_id_boolean=True&'
        }
        if (installation_number_boolean) {
            ++optionsCount;
            filterParams += 'installation_number_boolean=True&'
        }
        if (startDate) {
            ++optionsCount;
            filterParams += 'from_date=' + startDate.toLocaleDateString() + '&';
        }
        if (endDate) {
            ++optionsCount;
            filterParams += 'to_date=' + endDate.toLocaleDateString() + '&';
        }
        const queryParams = `is_deleted=False&search=${searchParam}&${filterParams}`;
        // await this.props.setSearchValue(queryParams);
        this.props.clearPictures();
        this.props.setCount(optionsCount);
        this.props.setGlobalSearchValues(
            search,
            normal,
            threeSixty,
            no_number_boolean,
            temporary_id_boolean,
            installation_number_boolean,
            for_future_information,
            quotation,
            intervention,
            startDate,
            endDate
        );
        await this.props.getPicturesBy(queryParams);
        this.props.history.push({
            pathname: ROUTES.SEARCH_IN_GALLERY_START,
            state: {optionsCount}
        });
    };

    onLeftSidebarOpen = () => {
        this.setState(prevState => ({
            openLeftSidebar: !prevState.openLeftSidebar,
        }));
    };

    bodyOverlay = () => {
        const element = document.getElementById('mainDiv');
        if (element) {
        document.body.classList.toggle('overlay');
        element.classList.toggle('overlayDiv');
        }
    };

    onRightSidebarOpen = () => {
        this.setState(prevState => ({
            openRightSidebar: !prevState.openRightSidebar
        }));
    };

    onInfoBarOpen = () => {
        this.setState(prevState => ({
            openInfoBar: !prevState.openInfoBar
        }));
        this.bodyOverlay();
    };

    openGlobalFilter = () => {
        this.setState(prevState => ({
            openGlobalFilter: !prevState.openGlobalFilter
        }));
    };

    onStopPropagtion = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
    };

    handleLogOut = async (e: any) => {
        e.preventDefault();
        await this.props.logOut();
        localStorage.clear();
        sessionStorage.clear();
        this.props.history.push(ROUTES.SIGN_IN);
    };

    oncloseSidebar = () => {
        if (this.state.openRightSidebar || this.state.openLeftSidebar || this.state.openGlobalFilter || this.state.openInfoBar) {
            const elem = document.getElementById('mainDiv');
            if (elem) {
                elem.classList.remove('overlayDiv');
            document.body.classList.remove('overlay');
            this.setState({
                openGlobalFilter: false,
                openRightSidebar: false,
                openLeftSidebar: false,
                openInfoBar: false,
            });
            }
        }
    };

    handlFileChange = async (input: HTMLInputElement) => {
        if (input.files!.length > 20) {
            toast.dismiss();
            toast(() =>
                <div className="toast-style error">
                    <div className="left-side">
							<span className="icon-error icon">
								<span className="path1"/>
								<span className="path2"/>
							</span>
                    </div>
                    <div className="right-side">
                        <p>{IMAGE_LIMITATION}</p>
                    </div>
                </div>
            );
            return;
        }
        if (input.files!.length) {
            this.setState({selectedFiles: input.files});

            await this.props.history.push({
                pathname: '/upload',
                state: 'selectedPictures'
            })
        }
        input.value = '';
    };

    render() {
        if (!localStorage.getItem(randomTokenName) && !sessionStorage.getItem(randomTokenName)) {
            return <Redirect to={ROUTES.SIGN_IN}/>
        }

        const openClassName = this.state.openLeftSidebar ? "open" : " ";
        const openGlobalFilter = this.state.openGlobalFilter ? "show" : " ";

        return (
            <div onMouseDown={this.oncloseSidebar} id='mainDiv'>
                {this.props.loading && <AquaSecLoader/>}
                {/*header*/}
                <Header
                    logOut={this.handleLogOut}
                    openGlobalFilter={this.openGlobalFilter}
                    active={this.props.history.location.pathname.split('/')[1]}
                    handleFileChange={this.handlFileChange}
                    getUser={this.props.getUser}
                    bodyOverlay={this.bodyOverlay}
                    onLeftSidebarOpen={this.onLeftSidebarOpen}
                    session={this.props.session}
                />
                {/*sidebar*/}
                <aside onMouseDown={this.onStopPropagtion} className={"sidebar " + openClassName}>
                    <Sidebar
                        logOut={this.handleLogOut}
                        active={this.props.history.location.pathname.split('/')[1]}
                        role={this.props.session.role}
                        oncloseSidebar={this.oncloseSidebar}
                    />
                </aside>
                {/*sidebar*/}

                {/*GlobalFilter*/}
                {this.props.history.location.pathname.split('/')[1] !== 'search-in-gallery' &&
                <aside onMouseDown={this.onStopPropagtion} className={"sidebar-right " + openGlobalFilter}>
                    <div className="sidebar-right-inner">
                        <GlobalFilter
                            clearValues={() => {
                            }}
                            isOpen={this.state.openGlobalFilter}
                            initialValues={GlobalFormInitialValues}
                            onSubmit={this.handleGlobalSearchAndFilter}
                            openGlobalFilter={this.openGlobalFilter}
                            bodyOverlay={this.bodyOverlay}
                        />
                    </div>
                </aside>}
                {/*GlobalFilter*/}
                {/*Routes*/}
                {/*
				//@ts-ignore*/}
                <Route exact path={ROUTES.UPLOAD_START} render={() => <UploadImages
                    selectedPictures={this.state.selectedFiles}
                    bodyOverlay={this.bodyOverlay}
                />}/>
                {this.props.session.role === 'admin' &&
                //@ts-ignore
                <Route exact path={ROUTES.USERS} render={() => <AdminUsers
                    openRightSidebar={this.state.openRightSidebar}
                    openAdminUserRightSidebar={this.onRightSidebarOpen}
                    bodyOverlay={this.bodyOverlay}
                />}/>}
                {/*
				//@ts-ignore*/}
                <Route exact path={ROUTES.GALLERY_START} render={() => <Gallery
                    oncloseSidebar={this.oncloseSidebar}
                    openRightSidebar={this.state.openRightSidebar}
                    openInfoBar={this.state.openInfoBar}
                    openFilter={this.onRightSidebarOpen}
                    openInfoHandler={this.onInfoBarOpen}
                    bodyOverlay={this.bodyOverlay}
                />}/>
                {/*
				//@ts-ignore*/}
                <Route exact path={ROUTES.SEARCH_IN_GALLERY} render={() => <Search_In_Gallery
                    runGlobalFilter={this.handleGlobalSearchAndFilter}
                    handleGlobalSearchAndFilter={this.handleGlobalSearchAndFilter}
                    openGlobalFilter={this.onRightSidebarOpen}
                    openRightSidebar={this.state.openRightSidebar}
                    openInfoBar={this.state.openInfoBar}
                    openInfoHandler={this.onInfoBarOpen}
                    bodyOverlay={this.bodyOverlay}
                    count={this.props.count}
                />}/>
                {/*
				//@ts-ignore*/}
                <Route exact path={ROUTES.PENDING_START} render={() => <Pending
                    openRightSidebar={this.state.openRightSidebar}
                    openPandingFilter={this.onRightSidebarOpen}
                    bodyOverlay={this.bodyOverlay}
                />}/>
                {this.props.session.role === 'admin' &&
                //@ts-ignore
                <Route exact path={ROUTES.TAGS} render={() => <Tags
                    openRightSidebar={this.state.openRightSidebar}
                    openGlobalFilter={this.onRightSidebarOpen}
                    bodyOverlay={this.bodyOverlay}
                />}/>
                }
                <Route exact path={ROUTES.MY_PROFILE} component={MyProfile}/>
                {this.props.session.role === 'admin' &&
                //@ts-ignore
                <Route exact path={ROUTES.RECENTLY_DELETED} render={() => <Recently_Deleted
                    openInfoBar={this.state.openInfoBar}
                    openInfoHandler={this.onInfoBarOpen}
                    openRightSidebar={this.state.openRightSidebar}
                    openRecentlyDeletedFilter={this.onRightSidebarOpen}
                    bodyOverlay={this.bodyOverlay}
                />}/>}
                {(this.props.session.role === 'admin' || this.props.session.role === 'back_officer') &&
                <Route exact path={ROUTES.CLIENTS} component={Clients}/>}
                {/*Routes*/}
            </div>
        );
    }
}

const mapStateToProps = (state: ApplicationState): PropsFromState => {
    return {
        session: state.session,
        errorMessage: state.errorMessage,
        searchValue: state.shared.searchValue,
        loading: state.shared.loading,
        count: state.shared.count,
        device: state.shared.device,
    }
};

const mapDispatchToProps = {
    verifyToken,
    getUser,
    getPicturesBy,
    logOut,
    setSearchValue,
    clearPictures,
    setGlobalSearchValues,
    setCount,
    changeDevice
};

export default compose<RoutesProps, {}>(
    withRouter,
    connect<PropsFromState, PropsFromDispatch, {}, ApplicationState>(
        mapStateToProps,
        mapDispatchToProps,
    )
)(PrivateRoutes);
