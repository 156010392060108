import { Reducer } from 'redux';
import { SessionState, SessionActionTypes } from './types';

let session: SessionState = {
    auth_token: '',
    pk: NaN,
    role: '',
    email: '',
    last_login: '',
    name: '',
    surname: ''
};

export const SessionInitialState: SessionState = {
  auth_token: session.auth_token,
  pk: session.pk,
  role: session.role,
  email: session.email,
  last_login: session.last_login,
  name: session.name,
  surname: session.surname
};

const sessionReducer: Reducer<SessionState> = (state: SessionState = SessionInitialState, action) => {
  switch (action.type) {
    case SessionActionTypes.LOG_IN_USER:
      return action.data;
    case SessionActionTypes.GET_SESSION:
      return {
        ...action.data
      };

    default:
      return state;
  }
};

export default sessionReducer;