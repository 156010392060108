import React, {Component} from 'react';
import {PendingFilterForm} from "./PendingFilterForm";
import {ConnectedReduxProps} from "../../../../store";
import SelectImagesBottomBar from "../UploadImages_Components/SelectImagesBottomBar";
import Modal from "react-responsive-modal";
// @ts-ignore
import {Pannellum} from 'pannellum-react';
import EmptyGallery from "../../../../assets/img/gallery/empty-gallery.svg";
import {DELETE_IMAGE, DELETE_IMAGES} from "../../../../helpers/modalMessages";
import {toast} from "react-toastify";
import {MULTIPLE_PICTURES_DELETED, SINGLE_PICTURE_DELETED} from "../../../../helpers/toastMessages";
import BottomScrollListener from "react-bottom-scroll-listener/dist";
import {AWS, LITTLESIZE, BIGSIZE} from "../../../../helpers/image-resize-endpoints";

const refPendingImages = React.createRef();

interface PendingProps extends ConnectedReduxProps {
    openRightSidebar: boolean,
    pandingPicturesData: any,
    selectedPictures: any[],
    filterQuery: string;
    LoadMore: (queryParams: string) => void
    history: any,
    filterOptionsCount: number
    pageSize: number
    device: string

    startAddingTags(): void,

    selectPicture(id: number): void,

    unselectPicture(id: number): void,

    openPandingFilter(): void,

    bodyOverlay(): void,

    selectAllPictures(): void,

    openPandingFilter(): void,

    bodyOverlay(): void,

    clearSelectedPicturesArray(): void;

    onFilterSubmit(normal: boolean, threeSixty: boolean, startDate: Date, endDate: Date): void,

    onStopPropagtion(e: React.MouseEvent<HTMLElement>): void,

    onFilterButtonClick(): void,

    deletePendingPictures(picturesArray: any): any

    getPendingPicturesBy(queryParams: string): any,
}

interface PendingState {
    openDelete: boolean,
    selectable: boolean,
    open360: boolean
    openNormal: boolean
    picture_preview: string
    pannelum_ready: boolean

}

class PandingPage extends Component<PendingProps, PendingState> {

    state = {
        openDelete: false,
        selectable: false,
        open360: false,
        openNormal: false,
        picture_preview: '',
        pannelum_ready: false,
    };

    onToggleDelete = () => {
        this.setState({openDelete: !this.state.openDelete})
    };

    handleSelectable = () => {
        if (this.state.selectable) {
            this.props.clearSelectedPicturesArray()
        }
        this.setState({selectable: !this.state.selectable})
    };

    pictureSelectToggle = (id: number) => {
        const {unselectPicture, selectPicture, selectedPictures} = this.props;
        if (selectedPictures.includes(id)) {
            unselectPicture(id);
        } else {
            selectPicture(id);
        }
    };

    onClose360Modal = () => {
        this.setState({
            open360: false,
            picture_preview: '',
            pannelum_ready: false
        })
    };

    onOpen360Modal = (index: number) => {
        this.setState({
            open360: true,
            picture_preview: this.props.pandingPicturesData.results[index].picture + '?type=cors'
        })
    };

    onOpenNormalModal = (index: number) => {
        const pictureName = this.props.pandingPicturesData.results[index].picture.split('/').pop();
        const picture_preview = AWS + BIGSIZE + '/' + pictureName;
        this.setState({
            openNormal: true,
            picture_preview,
        })
    };

    onCloseNormal = () => {
        this.setState({
            openNormal: false,
            picture_preview: '',
        })
    };

    render() {
        const {
            pandingPicturesData,
            LoadMore,
            onFilterButtonClick,
            selectedPictures,
            filterOptionsCount,
            getPendingPicturesBy,
            clearSelectedPicturesArray,
            selectAllPictures,
            filterQuery,
        } = this.props;

        const {openDelete, selectable} = this.state;
        const selected_count = selectAllPictures && selectedPictures.length;

        return (
            <>
                {(pandingPicturesData.results.length === 0) ?
                    (<>
                        <div className="container">
                            <div className="flex align-center justify-end py-25">
                                {
                                    filterOptionsCount !==0 && (
                                        <button className="btn outline primary fw-700" onClick={onFilterButtonClick}>
                                            <span>Filter {this.props.filterOptionsCount !== 0 && '(' + this.props.filterOptionsCount + ')'}</span>
                                        </button>
                                    )
                                }
                            </div>
                            <div className="text-center empty-page-3">
                                <div className="pb-100">
                                    <img className="empty-img" src={EmptyGallery} alt=""/>
                                    <p className="pt-10 font-md">There are no images to display</p>
                                </div>
                            </div>
                        </div>
                    </>)
                    : (<>
                        <div className="container">
                            <div className="flex align-center justify-end py-25">
                                {!selectable
                                    ? (<button onClick={this.handleSelectable}
                                               className="link fw-700 no-style mr-30">Select</button>)
                                    : (<>
                                            <button onClick={this.handleSelectable}
                                                    className="link fw-700 no-style mr-30">Cancel
                                            </button>
                                            <button
                                                onClick={() => selectAllPictures()}
                                                className="link fw-700 no-style mr-30">Select
                                                All
                                            </button>
                                        </>
                                    )}
                                <button className="btn outline primary fw-700" onClick={onFilterButtonClick}>
                                    <span>Filter {this.props.filterOptionsCount !== 0 && '(' + this.props.filterOptionsCount + ')'}</span>
                                </button>
                            </div>

                            <div className={"image-list pb-70" + (selectable ? " selected" : "")}>
                                {pandingPicturesData.results.map((pictureData: any, i: number) => {
                                    const pictureName = pictureData.picture.split('/').pop();
                                    return (
                                        <div key={i}
                                             className={"image-list-item" + (selectedPictures.includes(pictureData.id)
                                                 ? ' selected-item' : '')}
                                            //@ts-ignore
                                             onClick={() => {
                                                 if (selectable) {
                                                     this.pictureSelectToggle(pictureData.id)
                                                 } else if (pictureData.is_normal) {
                                                     this.onOpenNormalModal(i)
                                                 } else if (this.props.device === 'pc') {
                                                     this.onOpen360Modal(i)
                                                 }
                                             }}
                                             style={{backgroundImage: `url(${AWS + LITTLESIZE + '/' + pictureName })`}}>
                                            {!pictureData.is_normal &&
                                            <div className="image-list-item-action">
                                                {<span className='icon icon-360'/>}
                                            </div>
                                            }
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        {(selected_count !== 0 && selectable) &&
                        <SelectImagesBottomBar
                            startAddingTags={this.props.startAddingTags}
                            handleDeleteSelected={this.onToggleDelete}
                        />
                        }
                        {/*
  // @ts-ignore */}
                        <div className="modal modal-sm" ref={refPendingImages}>
                            <Modal
                                open={openDelete}
                                onClose={this.onToggleDelete}
                                center
                                container={refPendingImages.current}
                            >
                                <div>
                                    {
                                        selected_count === 1
                                            ? <p className="font-md">{DELETE_IMAGE}</p>
                                            :
                                            <p className="font-md">{DELETE_IMAGES}</p>
                                    }
                                    <div className="flex justify-end mt-50">
                                        <button className="btn outline text mr-15"
                                                onClick={this.onToggleDelete}>
                                            Cancel
                                        </button>
                                        <button className="btn filled warning ripple"
                                                onClick={() => {
                                                    this.props.deletePendingPictures(selectedPictures)
                                                        .then(() => {
                                                            this.onToggleDelete();
                                                            if (selected_count === 1) {
                                                                toast.dismiss();
                                                                toast(() =>
                                                                    <div className="toast-style success">
                                                                        <div className="left-side">
																				<span className="icon-success icon">
																					<span className="path1"/>
																					<span className="path2"/>
																				</span>
                                                                        </div>
                                                                        <div className="right-side">
                                                                            <p>{SINGLE_PICTURE_DELETED}</p>
                                                                        </div>
                                                                    </div>)
                                                            } else {
                                                                toast.dismiss();
                                                                toast(() =>
                                                                    <div className="toast-style success">
                                                                        <div className="left-side">
																				<span className="icon-success icon">
																					<span className="path1"/>
																					<span className="path2"/>
																				</span>
                                                                        </div>
                                                                        <div className="right-side">
                                                                            <p>{MULTIPLE_PICTURES_DELETED}</p>
                                                                        </div>
                                                                    </div>)
                                                            }
                                                            getPendingPicturesBy(filterQuery + `page=1&page_size=${this.props.pageSize}`);
                                                            clearSelectedPicturesArray();
                                                        })
                                                }}
                                        >
                                            <span>Delete</span>
                                        </button>
                                    </div>
                                </div>
                            </Modal>
                        </div>
                        <Modal
                            open={this.state.openNormal}
                            onClose={this.onCloseNormal}
                            center
                        >
                            <img alt={'picture'} src={this.state.picture_preview}/>
                        </Modal>
                        <Modal
                            open={this.state.open360}
                            onClose={this.onClose360Modal}
                            center
                            onEntered={() => this.setState({pannelum_ready: true})}
                        >
                            {this.state.pannelum_ready &&
                            <Pannellum
                                showControls={false}
                                pitch={10}
                                yaw={180}
                                hfov={110}
                                autoLoad
                                image={this.state.picture_preview}
                                id='containers'
                                width='600px'
                                height='480px'
                                maxHfov={100}
                            />}
                        </Modal>
                        <BottomScrollListener
                            onBottom={() => pandingPicturesData.next ? LoadMore(pandingPicturesData.next) : {}}/>
                    </>)}
            </>
        );
    }
}

export default PandingPage;
