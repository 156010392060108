import React from 'react';
import {withFormik, FormikProps} from 'formik';
// @ts-ignore
import AsyncSelect from 'react-select/async';


import AddingTagsBottomBar from "./AddingTagsBottomBar";

export interface FormValues {
	picture_type: string
	installation_number: { value: string, label: string }
	temporary_id: { value: string, label: string }
}

interface OtherProps {
	nextInstallation: string
	nextTemporary: string
	loadOptionsInstallation(search: string): void
	loadOptionsTemporary(search: string): void
	temporary_id_options: { value: string, label: string }[]
	installation_number_options: { value: string, label: string }[]
	nextStep(numberId: string,
	         installation_number_id: string,
	         installation_number_name: string,
	         temporary_id: string,
	         temporary_id_name: string): void,

	previousStep(picture_type: string,
				 installation_number_id: string,
				 installation_number_name: string,
				 temporary_id: string,
				 temporary_id_name: string,): void,

	getMore(next: string, type: string): void
}

const InnerForm = (props: OtherProps & FormikProps<FormValues>) => {
	const {
		values,
		setFieldValue,
		setFieldTouched,
		handleChange,
		handleBlur,
		handleSubmit,
		nextStep,
		previousStep,
		temporary_id_options,
		installation_number_options,
		loadOptionsInstallation,
		loadOptionsTemporary,
		nextInstallation,
		nextTemporary,
		getMore
	} = props;
	return (
		<>
			<form onSubmit={handleSubmit} className="container-sm py-100 p-sm-0">
				<div className="box">
					{/*step 1*/}
					<div className="stepper">
						<div className="stepper-header">
							<ul className="stepper-header-top">
								<li className="active"><span>1</span></li>
								<li><span>2</span></li>
								<li><span>3</span></li>
							</ul>
							<div className="stepper-header-bottom">
								<h4 className="fw-400 text-uppercase">step 1</h4>
								{values.picture_type === 'installation_number'
									? (<h3>Select Installation Number</h3>)
									: (values.picture_type === 'temporary_id'
										&& (<h3>Select Temporary ID</h3>)
									)}
							</div>
						</div>
						<div className="stepper-body pb-100">
							<ul className="list-item">
								<li className={"radio-btn" + (values.picture_type === 'installation_number' ? ' active' : '')}>
									<label htmlFor="installation_number">
										<div className="align-center flex">
											<input
												type="radio"
												id="installation_number"
												name="picture_type"
												value="installation_number"
												onChange={(value: any) => {
													setFieldValue('picture_type', value.target.value);
													setFieldValue('temporary_id', {value: "", label: null});
												}}
												onBlur={handleBlur}
												checked={values.picture_type === 'installation_number'}
											/>
											<span className="checkmark"></span>
											<span className="pl-15">Installation Number</span>
										</div>
									</label>
									{values.picture_type === 'installation_number' &&
									(
										<div className="px-20 pb-20">
											<AsyncSelect
												loadOptions={loadOptionsInstallation}
												name='installation_number'
												value={values.installation_number.value === ''? null:values.installation_number}
												onChange={(value: any) => setFieldValue('installation_number', value)}
												onBlur={() => setFieldTouched('installation_number', true)}
												defaultOptions={installation_number_options}
												placeholder="Select Installation Number"
												className="select-style"
												classNamePrefix="inner"
												onMenuScrollToBottom={(e: any) => getMore(nextInstallation,'installation_number')}
											/>
										</div>
									)}
								</li>
								<li className={"radio-btn" + (values.picture_type === 'temporary_id' ? ' active' : '')}>
									<label htmlFor="temporary_id">
										<div className="align-center flex">
											<input
												type="radio"
												id="temporary_id"
												name="picture_type"
												value="temporary_id"
												onChange={(value: any) => {
													setFieldValue('picture_type', value.target.value);
													setFieldValue('installation_number', {value: "", label: null});
												}}
												onBlur={handleBlur}
												checked={values.picture_type === 'temporary_id'}
											/>
											<span className="checkmark"></span>
											<span className="pl-15">Temporary ID</span>
										</div>
									</label>

									{values.picture_type === 'temporary_id' &&
									(
										<div className="px-20 pb-20">
											<AsyncSelect
												loadOptions={loadOptionsTemporary}
												name='temporary_id'
												value={values.temporary_id.value === ''?null:values.temporary_id}
												onChange={(value: any) => setFieldValue('temporary_id', value)}
												onBlur={() => setFieldTouched('temporary_id', true)}
												defaultOptions={temporary_id_options}
												placeholder="Select Temporary ID"
												className="select-style"
												classNamePrefix="inner"
												onMenuScrollToBottom={(e: any) => getMore(nextTemporary,'temporary_id')}
											/>
										</div>
									)
									}
								</li>
								<li className={"radio-btn" + (values.picture_type === 'no_number' ? ' active' : '')}>
									<label htmlFor="no_number">
										<div className="align-center flex">
											<input
												type="radio"
												id="no_number"
												name="picture_type"
												value="no_number"
												onChange={(value: any) => {
													setFieldValue('picture_type', value.target.value);
													setFieldValue('installation_number', {value: "", label: null});
													setFieldValue('temporary_id', {value: "", label: null});
												}}
												onBlur={handleBlur}
												checked={values.picture_type === 'no_number'}
											/>
											<span className="checkmark"></span>
											<span className="pl-15">No Number or ID</span>
										</div>
									</label>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</form>
			<AddingTagsBottomBar
				previousStep={() => {
						previousStep(values.picture_type,
							values.installation_number.value,
							values.installation_number.label,
							values.temporary_id.value,
							values.temporary_id.label)
				}}
				nextStep={() => {
					if (values.temporary_id.value
						|| values.installation_number.value
						|| values.picture_type === 'no_number') {
						nextStep(values.picture_type,
							values.installation_number.value,
							values.installation_number.label,
							values.temporary_id.value,
							values.temporary_id.label)
					}
				}}
			/>
		</>
	)
};

interface AddingTagsStep1Form {
	nextInstallation: string
	nextTemporary: string
	loadOptionsInstallation(search: string): void
	loadOptionsTemporary(search: string): void
	initialValues: FormValues,
	temporary_id_options: { value: string, label: string }[],
	installation_number_options: { value: string, label: string }[],

	nextStep(picture_type: string,
	         installation_number_id: string,
	         installation_number_name: string,
	         temporary_id: string,
	         temporary_id_name: string,): void,

	previousStep(picture_type: string,
				 installation_number_id: string,
				 installation_number_name: string,
				 temporary_id: string,
				 temporary_id_name: string,): void,

	getMore(next: string, type: string): void

}

export const AddingTagsStep1Form = withFormik<AddingTagsStep1Form, FormValues>({
	enableReinitialize: true,

	mapPropsToValues: props => {
		const {initialValues} = props;
		return {
			picture_type: initialValues.picture_type,
			installation_number: initialValues.installation_number,
			temporary_id: initialValues.temporary_id,
		}
	},

	handleSubmit(
		{picture_type, installation_number, temporary_id}: FormValues,
		{props, setSubmitting, setErrors}
	) {

	},

})(InnerForm);