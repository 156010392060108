import React from 'react';
import {compose} from 'recompose';
import {RouteComponentProps, withRouter} from "react-router";
import {connect} from 'react-redux'
import {ConnectedReduxProps, ApplicationState} from '../../../store';
import {SessionState} from "../../../store/session/types";
import {toast} from "react-toastify";
import {Client, ClientsState} from "../../../store/clients/types";
import Modal from "react-responsive-modal";
import AquaSecLoader from "../components/Loader";
import {
	TAG_CREATED, TAG_DELETED, TAG_EDITED, TAG_EXISTS, TAG_FAILED,
	TEMPORARY_ID_EDITED,
} from "../../../helpers/toastMessages";
import {
	TAG_DELETION_WARNING,
} from "../../../helpers/modalMessages";
import TagsTable from "../components/Tags/TagsTable";
import {TagsForm, TagsFormInitialValues, TagsFromValues} from "../components/Tags/TagsForm";
import {clearSearchValue, setSearchValue} from "../../../store/shared/actions";
import {addTag, clearTags, deleteTag, editTag, getDefaultTags} from "../../../store/tags/actions";


const refClientsForm = React.createRef();
const refClientsDelete = React.createRef();

interface PropsFromState {
	errorMessage: any,
	session: SessionState,
	searchValue: string,
	loading: boolean
	tags: any[],
	tagsCount: number
}

interface PropsFromDispatch {

	setSearchValue(searchParam: string): void

	getDefaultTags(page: number,searchValue: string ): void

	clearSearchValue(): void

	addTag(name:string): void

	editTag(name: string,id: number): void

	deleteTag(id: number): void

	clearTags(): void

}

interface GalleryProps extends RouteComponentProps<any>, PropsFromState, PropsFromDispatch, ConnectedReduxProps {

}

interface GalleryState {
	openLeftSidebar: boolean,
	openRightSidebar: boolean,
	initialValues: TagsFromValues,
	searchParam: string,
	openGlobalFilter: boolean
	filterParams: string
	searchTypes: number,
	type: string,
	installation_number_options: { value: string, label: string }[],
	temporary_id: number,
	installation_number: string,
	installation_number_name: string,
	temporary_id_name: string,
	pictureType: string
	disabled: boolean,
	open: boolean
	openForm: boolean
	title: string
	deleteClicked: boolean
	deletionToken: string
	page: number
	onceLoaded: boolean,
}

class Tags extends React.Component<GalleryProps, GalleryState> {

	state: GalleryState = {
		openLeftSidebar: false,
		openRightSidebar: false,
		openGlobalFilter: false,
		initialValues: TagsFormInitialValues,
		searchParam: '',
		filterParams: '',
		searchTypes: 0,
		type: '',
		installation_number_options: [],
		temporary_id: NaN,
		installation_number: '',
		installation_number_name: '',
		temporary_id_name: '',
		pictureType: 'no_number',
		disabled: true,
		open: false,
		openForm: false,
		title: '',
		deleteClicked: false,
		deletionToken: '',
		page: 1,
		onceLoaded: false,
	};

	async componentDidMount() {
		try {
			await this.props.getDefaultTags(1,'');
			//Fetch tags;
			this.setState({
				onceLoaded: true
			});
			if (Object.keys(this.props.errorMessage).length !== 0) {
			}
		} catch (e) {
		}
	}

	async componentWillUnmount() {
		await this.props.clearTags();
	}

	handlePageChange = async (page: number) => {
		await this.props.getDefaultTags(page, this.props.searchValue);
		this.setState({
			page
		})
	};

	setSearchValue = async (search: string) => {
		// await this.props.getTemporaryIDs(1, search);
		await this.props.getDefaultTags(1, search);
		await this.props.setSearchValue(search);
		this.setState({
			page: 1,
		})
	};

	onOpenModal = (client: Client) => {
		this.setState({
			temporary_id: client.id,
			open: true,
		});
	};

	onCloseFormModal = () => {
		this.setState({
			installation_number_options: [],
			openForm: false,
			disabled: true,
		});
	};

	onCloseModal = () => {
		this.setState({
			open: false,
			deleteClicked: false,
			deletionToken: ''
		});
	};

	handleSubmitForm = async (name: string) => {
		//Add tag logic
		await this.props.addTag(name);
		if (Object.keys(this.props.errorMessage).length === 0) {
			toast.dismiss();
			toast(() =>
				<div className="toast-style success">
					<div className="left-side">
							<span className="icon-success icon">
								<span className="path1"/>
								<span className="path2"/>
							</span>
					</div>
					<div className="right-side">
						<p>{TAG_CREATED}</p>
					</div>
				</div>
			);
			await this.props.clearSearchValue();
			this.setState({
				searchParam: ''
			});
			// await this.props.getTemporaryIDs(this.state.page, this.props.searchValue);
			await this.props.getDefaultTags(this.state.page, this.props.searchValue);
		} else {
			toast.dismiss();
			toast(() =>
				<div className="toast-style error">
					<div className="left-side">
							<span className="icon-error icon">
								<span className="path1"/>
								<span className="path2"/>
							</span>
					</div>
					<div className="right-side">
						<p>{TAG_EXISTS}</p>
					</div>
				</div>
			);
		}
		this.onCloseFormModal();
	};

	handleEditForm = async (name: string) => {
		//EditTagLogic
		await this.props.editTag(name,this.state.temporary_id);
		if (Object.keys(this.props.errorMessage).length === 0) {
			toast.dismiss();
			toast(() =>
				<div className="toast-style success">
					<div className="left-side">
							<span className="icon-success icon">
								<span className="path1"/>
								<span className="path2"/>
							</span>
					</div>
					<div className="right-side">
						<p>{TAG_EDITED}</p>
					</div>
				</div>
			);
			await this.props.getDefaultTags(this.state.page, this.props.searchValue);
		} else {
			toast.dismiss();
			toast(() =>
				<div className="toast-style error">
					<div className="left-side">
							<span className="icon-error icon">
								<span className="path1"/>
								<span className="path2"/>
							</span>
					</div>
					<div className="right-side">
						<p>{TAG_EXISTS}</p>
					</div>
				</div>
			);
		}
		this.onCloseFormModal();
	};

	handleDeleteTag = async (id: number) => {
		await this.props.deleteTag(id);
		if (Object.keys(this.props.errorMessage).length === 0) {
			await this.props.getDefaultTags(this.state.page, this.props.searchValue);
			this.onCloseModal();
			toast.dismiss();
			toast(() =>
				<div className="toast-style success">
					<div className="left-side">
							<span className="icon-success icon">
								<span className="path1"/>
								<span className="path2"/>
							</span>
					</div>
					<div className="right-side">
						<p>{TAG_DELETED}</p>
					</div>
				</div>
			);
		}
	};

	openAddNewClient = () => {
		this.setState(() => ({
			title: 'Add Tag',
			initialValues: TagsFormInitialValues,
			openForm: true,
		}));
	};

	openEditNewClient = async (client: Client) => {
		await this.setState(() => ({
			initialValues: {
				tag: client.name,
			},
			title: 'Edit Tag',
			temporary_id: client.id,
			openForm: true,
		}));
	};


	render() {
		return (
			(!this.state.onceLoaded)
				? (<AquaSecLoader/>)
				: (<>
					{/*header*/}
					{/*Table*/}
					<TagsTable
						searchParam={this.props.searchValue}
						setSearchValue={this.setSearchValue}
						page={this.state.page}
						role={this.props.session.role}
						clients={this.props.tags}
						total={this.props.tagsCount}
						openAddNewClient={this.openAddNewClient}
						onOpenModal={this.onOpenModal}
						openEdit={this.openEditNewClient}
						handlePageChange={this.handlePageChange}
					/>
					{/*Table*/}
					{/*Delete Modal*/}
					{/*
				//@ts-ignore*/}
					<div className="modal modal-md" ref={refClientsDelete}>
						<Modal
							open={this.state.open}
							onClose={this.onCloseModal}
							center
							container={refClientsDelete.current}
						>
							<div>
								<p className="font-md">{TAG_DELETION_WARNING}</p>
								<div className="flex justify-end mt-50">
									<button className="btn outline text mr-15" onClick={this.onCloseModal}>No</button>
									<button className="btn filled warning ripple" onClick={() => this.handleDeleteTag(this.state.temporary_id)}>
										<span>Yes</span>
									</button>
								</div>
							</div>
						</Modal>
					</div>
					{/*Delete Modal*/}
					{/*Add Client*/}
					{/*
					//@ts-ignore*/}
					<div className="modal modal-md" ref={refClientsForm}>
						<Modal
							open={this.state.openForm}
							onClose={this.onCloseFormModal}
							center
							container={refClientsForm.current}
						>
							<TagsForm
								title={this.state.title}
								onSubmit={this.state.title === 'Add Tag' ? this.handleSubmitForm : this.handleEditForm}
								initialValues={this.state.initialValues}
								onCloseForm={this.onCloseFormModal}
								isOpen={this.state.openForm}
							/>
						</Modal>
					</div>
					{/*Add Client*/}
				</>)
		);
	}

}

const mapStateToProps = (state: ApplicationState): PropsFromState => {
	return {
		errorMessage: state.errorMessage,
		session: state.session,
		searchValue: state.shared.searchValue,
		loading: state.shared.loading,
		tags: state.tags.results,
		tagsCount: state.tags.count,
	}
};

const mapDispatchToProps: PropsFromDispatch = {
	setSearchValue,
	getDefaultTags,
	clearSearchValue,
	addTag,
	editTag,
	deleteTag,
	clearTags
};

export default compose<GalleryProps, {}>(
	withRouter,
	connect<PropsFromState, PropsFromDispatch, {}, ApplicationState>(
		mapStateToProps,
		mapDispatchToProps,
	)
)(Tags);
