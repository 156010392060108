import React, {Component} from 'react';
import EmptyGallery from "../../../../assets/img/gallery/empty-gallery.svg";

//@ts-ignore
import Navigation from "./Navigation";
import ShowImages from "./ShowImages";
import BottomBar from "./BottomBar";

interface NoNumberProps {
    pictureType: string
    userId: number
    pictures: any
    userRole: string
    nextValue: string
    loaded: boolean
    searchValue: string
    filterParameters: string
    filterOptionsCount: number
    device: string;
    history: any;
    match: any;

    handleSavePreviewFor360(id: number, x: number, y: number, z: number): void

    addSelectedPictures(pictures: any[]): void;

    handleEdit(picture: any): void

    onInfoOpen(picture: any): void

    setSearchValue(searchParam: string): void

    redirectToGallery(): void

    onRightSidebarOpen(): void

    handleLoadMore(nextValue: string): void

    handlePictureTypeChange(type: string): void

    handleDeleteSelected(id: number): void

    handleLoadNoNumber(): void

    bodyOverlay(): void
}

interface NoNumberState {
    search: string;
    selectable: boolean;
    selectedPictures: any;
}

class NoNumber extends Component<NoNumberProps, NoNumberState> {

    state = {
        search: this.props.searchValue,
        selectable: false,
        selectedPictures: [],
    };

    async componentDidMount() {
        if (this.props.pictures.count === -1) {
            this.props.handleLoadNoNumber()
        }
    }

    componentWillUpdate(nextProps: Readonly<NoNumberProps>, nextState: Readonly<NoNumberState>, nextContext: any): void {
        if (this.state.selectedPictures !== nextState.selectedPictures) {
            this.props.addSelectedPictures(nextState.selectedPictures);
        }
    }

    handleChange = (e: React.FormEvent<HTMLInputElement>) => {
        this.setState({
            search: e.currentTarget.value,
        });
    };

    searchPictures = (e: any) => {
        e.preventDefault();
        const {search} = this.state;
        this.props.setSearchValue(search);
    };

    handleSelectable = () => {
        const {selectable} = this.state;
        if (selectable) {
            this.setState({selectedPictures: []})
        }
        this.setState({selectable: !selectable})
    };

    onAllPictureSelect = () => {
        const pictures = this.props.pictures.results;
        // @ts-ignore
        const selectedPictures = pictures.filter(({user: {id}}) => {
                if (this.props.userRole === "admin") return true;
                return this.props.userId === id;
            }
        ).map(({id}: { id: any }) => id);
        this.setState({selectedPictures})
    };

    onPictureSelect = (id: number) => {
        const {selectedPictures} = this.state;
        let selectedPicturesArray = [];

        // @ts-ignore
        if (selectedPictures.includes(id)) {
            selectedPicturesArray = selectedPictures.filter((pictureId) => {
                // @ts-ignore
                return pictureId !== id;
            })
        } else {
            if (this.props.userRole !== "admin") {

            }
            selectedPicturesArray = [...selectedPictures, id]
        }
        this.setState({selectedPictures: selectedPicturesArray})
    };

    handleBulkTagEditing = () => {
        this.props.history.push({pathname: '/gallery/edit/bulk/tag', state: {from: this.props.match.params.type}});

    };

    render() {
        const {selectable, selectedPictures} = this.state;

        return (
            <>
                <Navigation
                    handlePictureTypeChange={this.props.handlePictureTypeChange}
                    pictureType={this.props.pictureType}
                />
                {(this.props.pictures.count === 0)
                    ? ((this.props.searchValue !== '' || this.props.filterParameters !== '') ? (
                            <>
                                <div className="flex justify-between align-center py-25">
                                    <p className="italic color-text-70 fw-700 pr-10">Images with no number or
                                        ID</p>
                                    <button onClick={() => {
                                        this.props.onRightSidebarOpen();
                                        this.props.bodyOverlay()
                                    }} className="btn outline primary fw-700 pull-right">
                                        <span>Filter {this.props.filterOptionsCount !== 0 && '(' + this.props.filterOptionsCount + ')'}</span>
                                    </button>
                                </div>
                                <form onSubmit={this.searchPictures} className="textfield with-btn">
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        value={this.state.search}
                                        onChange={this.handleChange}
                                    />
                                    <button type='submit' className="no-style">
                                        <span className="icon-search font-lg"/>
                                    </button>
                                </form>
                                <div className="text-center empty-page empty-page-3">
                                    <div className="pb-100">
                                        <img className="empty-img" src={EmptyGallery} alt=""/>
                                        <p className="pt-10 font-md">There are no images to display with the
                                            mentioned parameter(s)</p>
                                    </div>
                                </div>
                            </>
                        ) :
                        (
                            <div className="text-center empty-page empty-page-3">
                                <div className="pb-100">
                                    <img className="empty-img" src={EmptyGallery} alt=""/>
                                    <p className="pt-10 font-md">There are no images to display</p>
                                </div>
                            </div>
                        ))
                    : (
                        <>
                            <div className="cols-xs-2 cols-mb align-center justify-between py-25">
                                <p className="col-sm-6 col-xs-5 italic color-text-70 fw-700 pr-10">Images
                                    with no number or ID</p>

                                <div className="text-right col-sm-6 col-xs-7">
                                    {selectable ? (
                                        <>
                                            <button onClick={this.handleSelectable}
                                                    className="link fw-700 no-style mr-15">Cancel
                                            </button>
                                            <button onClick={this.onAllPictureSelect}
                                                    className="link fw-700 no-style mr-15">Select All
                                            </button>
                                        </>
                                    ) : (
                                        <>
                                            <button onClick={this.handleSelectable}
                                                    className="link fw-700 no-style mr-15">Add Tags
                                            </button>
                                        </>
                                    )
                                    }
                                    <button onClick={() => {
                                        this.props.onRightSidebarOpen();
                                        this.props.bodyOverlay()
                                    }} className="btn outline primary fw-700 pull-right">
                                        <span>Filter {this.props.filterOptionsCount !== 0 && '(' + this.props.filterOptionsCount + ')'}</span>
                                    </button>
                                </div>

                            </div>
                            <form onSubmit={this.searchPictures} className="textfield with-btn">
                                <input
                                    type="text"
                                    placeholder="Search"
                                    value={this.state.search}
                                    onChange={this.handleChange}
                                />
                                <button type='submit' className="no-style">
                                    <span className="icon-search font-lg"/>
                                </button>
                            </form>
                            <ShowImages
                                selectedPictures={selectedPictures}
                                selectable={selectable}
                                onInfoOpen={this.props.onInfoOpen}
                                device={this.props.device}
                                SavePreviewFor360={this.props.handleSavePreviewFor360}
                                handleDeleteSelected={this.props.handleDeleteSelected}
                                userRole={this.props.userRole}
                                userId={this.props.userId}
                                pictures={this.props.pictures.results}
                                openEdit={this.props.handleEdit}
                                onPictureSelect={this.onPictureSelect}
                                handleLoadMore={() => this.props.nextValue ? this.props.handleLoadMore(this.props.nextValue) : {}}
                            />
                            {selectedPictures.length > 0 &&
                            <BottomBar
                                goNext={this.handleBulkTagEditing}
                            />}
                        </>
                    )
                }

            </>
        );
    }
}

export default NoNumber;
