import {ErrorMessageActionTypes} from './types'

export interface SignInFailure {
  type: ErrorMessageActionTypes.SIGN_IN_FAILURE,
  data: object,
}

export interface AddingNewUserFailed {
  type: ErrorMessageActionTypes.ADDING_NEW_USER_FAILED,
  data: object,
}

export interface ClearError {
  type: ErrorMessageActionTypes.CLEAR_ERROR_MESSAGES,
}

export interface ResetingPasswordFailed {
  type: ErrorMessageActionTypes.RESETING_PASSWORD_FAILED,
  data: object,
}

export interface NoAccess {
  type: ErrorMessageActionTypes.NO_ACCESS,
  data: object,
}

export  interface CantDeleteAdmin {
  type: ErrorMessageActionTypes.CANT_DELETE_ADMIN,
  data: object,
}

export interface SignUpFailed {
  type: ErrorMessageActionTypes.SIGN_UP_FAILURE,
  data: object,
}

export const SignInFailedMessage = (errorMessage: object): SignInFailure => {
  return { type: ErrorMessageActionTypes.SIGN_IN_FAILURE, data: errorMessage || 'PleaseActivateUser'}
};

export const ClearErrorMessages = (): ClearError => {
  return { type: ErrorMessageActionTypes.CLEAR_ERROR_MESSAGES }
};

export const AddingNewUserFailed = (errorMessage: object): AddingNewUserFailed => {
  return { type: ErrorMessageActionTypes.ADDING_NEW_USER_FAILED, data: errorMessage }
};

export const ResetingPasswordFailed = (errorMessage: object): ResetingPasswordFailed => {
  return { type: ErrorMessageActionTypes.RESETING_PASSWORD_FAILED, data: errorMessage}
};

export const NoAccess = (errorMessage: object): NoAccess => {
  return { type: ErrorMessageActionTypes.NO_ACCESS, data: errorMessage }
};

export const cantDeleteAdmin = (errorMessage: object): CantDeleteAdmin => {
  return { type: ErrorMessageActionTypes.CANT_DELETE_ADMIN, data: errorMessage}
};

export const SignUpFailedMessage = (errorMessage: object): SignUpFailed => {
  return { type: ErrorMessageActionTypes.SIGN_UP_FAILURE, data: errorMessage }
};
export const emailAlreadyExist = (errorMessage: object) => {
  return { type: ErrorMessageActionTypes.EMAIL_ALREADY_EXIST, data: errorMessage }
};