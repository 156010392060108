import {SessionActionTypes, SessionState} from './types'
import {ThunkAction, ThunkDispatch} from 'redux-thunk';
import {AnyAction, Dispatch} from 'redux'
import {SignInFailedMessage, ClearErrorMessages, SignUpFailedMessage, NoAccess} from '../errorMessage/actions';
import {verifyToken} from "../users/actions";
import decode from 'jwt-decode';
import {randomTokenName} from "../../App";
import {API_URL} from "../../configureStore";
import request from "../../helpers/request";



export interface LogInUser {
	type: SessionActionTypes.LOG_IN_USER
	data: SessionState
}

export interface GetSession {
	type: SessionActionTypes.GET_SESSION
	data: {
		pk: number,
		role: string,
		name: string,
		surname: string,
		email: string,
		last_login: string,
		auth_token: string,
	}
}


export const GetSession = (pk: number,
                           role: string,
                           name: string,
                           surname: string,
                           email: string,
                           last_login: string,
                           auth_token: string): GetSession => {
	return {type: SessionActionTypes.GET_SESSION, data: {pk ,role, name, surname, email, last_login,auth_token}}
};

export const getUser = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
		return new Promise<void>(async (res) => {
			dispatch(ClearErrorMessages());
			try {
				const token = localStorage.getItem(randomTokenName) || sessionStorage.getItem(randomTokenName);
				if(token) {
					try {
						const {user_id} = decode(token!);
						const isTokenActive = await verifyToken(token!);
						if(isTokenActive) {
							const user = await request(API_URL + `users/${user_id}/`, {
								method: 'GET',
								headers: {
									'Authorization': 'JWT ' + token
								}
							})
								.then(res => res.json());
							dispatch(GetSession(user.id,user.role, user.first_name, user.last_name, user.email, user.last_login,user.auth_token));
							res(user);
						} else {
							dispatch(NoAccess({token: 'Wrong token'}))
							res()
						}
					} catch (e) {
						dispatch(NoAccess({token: 'Wrong token'}));
						res();
					}

				} else {
					dispatch(NoAccess({token: 'No token'}))
					res();
				}
			} catch (e) {
				dispatch(NoAccess({token: 'Wrong token'}));
				res();
			}
		})
	}
};

export const LogInUser = (session: SessionState): LogInUser => {
	return {type: SessionActionTypes.LOG_IN_USER, data: session}
};

export const getEmailByToken = (token: string): ThunkAction<Promise<object>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<object> => {
		return new Promise<object>(async (res) => {
			const values = {};
			request(API_URL + `users/get-email-from-activate-account/${token}`)
				.then(async response => {
					if (response.status === 400) {
						await response.json()
							.then(error => {
								dispatch(SignInFailedMessage(error))
								res();
							})
					} else if (response.ok) {
						await response.json()
							.then(values => {
								res(values)})
					}
				});
		})
	}
};


export const logInUser = (email: string, password: string, remember: boolean): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
		return new Promise<void>(async (res) => {
			dispatch(ClearErrorMessages());
			await request(API_URL + 'users/login/', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({email, password})
			})
				.then(async response => {
					if (response.status === 500) {
						return dispatch(SignInFailedMessage({message: 'Please, activate your account to log in'}))
					} else if (!response.ok) {
						await response.json()
							.then(error => {
								return dispatch(SignInFailedMessage(error))
							})
					} else if (response.status === 200) {
						await response.json()
							.then(async session => {
								if(remember) {
									await localStorage.setItem(randomTokenName, session.auth_token);
								} else {
									await sessionStorage.setItem(randomTokenName, session.auth_token);
								}
								return dispatch(LogInUser(session))
							})
					}
				});
			res();
		})
	}
};


export const signUpUser = (token: string, password: string, first_name: string, last_name: string)
	: ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
		return new Promise<void>(async (res) => {
			dispatch(ClearErrorMessages());
			await request(API_URL + 'users/activate-account/', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					activation_token: token,
					password,
					first_name,
					last_name
				})
			})
				.then(async response => {
					if (response.status === 400) {
						await response.json()
							.then(error => dispatch(SignUpFailedMessage(error)))
					} else if (response.ok) {
						await response.json()
					}
				});
			res();
		})
	}
};

export const logOut = () => (dispatch: Dispatch<any>) => {
	return dispatch({type: SessionActionTypes.LOG_OUT});
};

