import React from 'react';
import Folder from '../../../../assets/img/gallery/folder.svg';
import Navigation from "./Navigation";
import ReactTooltip from 'react-tooltip'
import ReactResizeDetector from "react-resize-detector";

interface NavigationProps {
	pictureType: string
	folders: []
	searchInstallationFolderValue: string

	handleChosenFolder(id: number): void

	handlePictureTypeChange(type: string): void

	handleSearchFolder(search: string, type: string): void
}

interface NavigationState {
	search: string
	checkResize: boolean
}


class Installation extends React.Component<NavigationProps, NavigationState> {

	state = {
		search: this.props.searchInstallationFolderValue,
		checkResize: false
	};

	componentDidMount(): void {

	}

	handleChange = (e: React.FormEvent<HTMLInputElement>) => {
		this.setState({
			search: e.currentTarget.value,
		})
	};

	onResize = () => {
		this.setState((prevState: NavigationState) => ({
			checkResize: !prevState.checkResize,
		}))
	};

	render() {
		return (
			<>
				<Navigation
					pictureType={this.props.pictureType}
					handlePictureTypeChange={this.props.handlePictureTypeChange}
				/>
				{(this.props.folders.length === 0)
					? (this.props.searchInstallationFolderValue !== '') ? (
						<>
							<form onSubmit={(e) => {
								e.preventDefault();
								this.props.handleSearchFolder(this.state.search, this.props.pictureType)
							}}
							      className="textfield with-btn mb-15">
								<input
									type="text"
									placeholder="Search"
									value={this.state.search}
									onChange={this.handleChange}
								/>
								<button type='submit' className="no-style">
									<span className="icon-search font-lg"/>
								</button>
							</form>
							<div className="text-center empty-page empty-page-3">
								<div className="pb-100">
									<img className="empty-img" src={Folder} alt=""/>
									<p className="pt-10 font-md">There are no folders to display</p>
								</div>
							</div>
						</>
					) : (<div className="text-center empty-page empty-page-3">
						<div className="pb-100">
							<img className="empty-img" src={Folder} alt=""/>
							<p className="pt-10 font-md">There are no folders to display</p>
						</div>
					</div>)
					: (
						<>
							<form onSubmit={(e) => {
								e.preventDefault();
								this.props.handleSearchFolder(this.state.search, this.props.pictureType)
							}}
							      className="textfield with-btn mb-15">
								<input
									type="text"
									placeholder="Search"
									value={this.state.search}
									onChange={this.handleChange}
								/>
								<button type='submit' className="no-style">
									<span className="icon-search font-lg"/>
								</button>
							</form>
							<div className="folder-group pb-20">
								{this.props.folders.map((folder: any, index: number) => (
									<div key={index} className="folder" onClick={() => this.props.handleChosenFolder(folder.id)}>
										<span className="icon-folder-open icon blue">
										  <span className="path1"/>
										  <span className="path2"/>
										</span>
										<span id={'wrapper' + index} className="folder-name-wrapper">
											<ReactResizeDetector
												handleWidth
												onResize={this.onResize}
												render={({width, height}) => (
													<span id={'child' + index} data-tip={true} data-for={index} className="folder-name">
														{folder.name}
													</span>
												)}
											/>
										</span>
										<ReactTooltip
											//@ts-ignore
											disable={(document.getElementById('wrapper' + index) && document.getElementById('child' + index)) &&
											//@ts-ignore
											(document.getElementById('child' + index).offsetWidth - document.getElementById('wrapper' + index).offsetWidth < 1)}
											id={'' + index}
											effect='solid'
											type='dark'>
											<span>{folder.name}</span>
										</ReactTooltip>
									</div>
								))}
							</div>
						</>
					)}
			</>
		);
	}
}

export default Installation;