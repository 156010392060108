import React from 'react';
import {SignUpForm} from "./components/SignUpForm";

import { compose } from 'recompose'
import {RouteComponentProps, withRouter} from 'react-router-dom'
import { connect } from "react-redux";
import {ApplicationState, ConnectedReduxProps} from "../../../store";
import { signUpUser, getEmailByToken } from "../../../store/session/actions";
import {ToastContainer, toast} from "react-toastify";
import {SIGN_IN} from "../../../helpers/routes";
import {TOKEN_USED_OR_EXPIRED, USER_ACTIVATED} from "../../../helpers/toastMessages";

interface PropsFromState {
	errorMessage: object
}

interface PropsFromDispatch {
	signUpUser(token: string, password:string, first_name: string, last_name: string): void
	getEmailByToken(token: string): any
}

interface SignUpProps extends RouteComponentProps<any>,PropsFromState,PropsFromDispatch,ConnectedReduxProps{

}

interface SignUpState {
	email: string,
}

class Signup extends React.Component<SignUpProps,SignUpState> {

	state = {
		email: '',
	};

	async componentDidMount() {
		const token = this.props.match && this.props.match.params.token;
		const values = await this.props.getEmailByToken(token);

		if(Object.keys(this.props.errorMessage).length === 0) {
			this.setState({
				email: values.email,
			})
		} else {
			toast.dismiss();

			Object.values(this.props.errorMessage).map(() =>
				toast(() =>
					<div className="toast-style error">
						<div className="left-side">
							<span className="icon-error icon">
								<span className="path1"/>
								<span className="path2"/>
							</span>
						</div>
						<div className="right-side">
							<p>{TOKEN_USED_OR_EXPIRED}</p>
						</div>
					</div>
				))
		}
	}

	handleSubmit = async (first_name: string, last_name: string, password: string) => {
		const token = this.props.match && this.props.match.params.token;
		try {
			await this.props.signUpUser(token, password, first_name, last_name);
			if(Object.keys(this.props.errorMessage).length === 0) {
				toast.dismiss();
				toast(() =>
					<div className="toast-style error">
						<div className="left-side">
							<span className="icon-error icon">
								<span className="path1"/>
								<span className="path2"/>
							</span>
						</div>
						<div className="right-side">
							<p>{USER_ACTIVATED}</p>
						</div>
					</div>
				);
				this.props.history.push(SIGN_IN)
			} else {
				toast.dismiss();
				Object.values(this.props.errorMessage).map(messages =>
				toast(() =>
					<div className="toast-style error">
						<div className="left-side">
							<span className="icon-error icon">
								<span className="path1"/>
								<span className="path2"/>
							</span>
						</div>
						<div className="right-side">
							<p>{messages}</p>
						</div>
					</div>
				))
			}
		} catch (e) {
		}
	};

	render() {
		const isDisabled = Object.keys(this.props.errorMessage).length !== 0;
		return (
			<div className="sign-page">
				{/*sign up*/}
                <ToastContainer />
				<SignUpForm
					disabled={isDisabled}
					onSubmit={this.handleSubmit}
					email={this.state.email}
				/>
				{/*end sign up*/}
			</div>
		);
	}
}

const mapStateToProps = (state:ApplicationState):PropsFromState => {
	return {
		errorMessage: state.errorMessage,
	}
};

const mapDispatchToProps = {
	signUpUser,
	getEmailByToken
};

export default compose<SignUpProps,any>(
	withRouter,
	connect(
		mapStateToProps,
		mapDispatchToProps,
	)
)(Signup);
