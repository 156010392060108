//Images
export const DELETE_IMAGE = "Are you sure you want to delete selected image?";
export const DELETE_IMAGES = "Are you sure you want to delete selected images?";
//User
export const DEACTIVATE_USER = "Do you want to deactivate the user?";
export const ACTIVATE_USER = "Do you want to activate the user?";
//TemporaryID
export const TEMPORARY_ID_CONFIRMATION_CODE = "To delete Temporary ID enter confirmation code sent to your email address";
export const TEMPORARY_ID_DELETION_WARNING = "Deleting this Temporary ID will delete also the folder and images in it. Are you sure you want to delete it?";
//Tags
export const TAG_DELETION_WARNING = "Images with this tag will loose it after deleting. Do you still want to delete it?";
