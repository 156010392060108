import {PicturesActionTypes} from "./types";
import {AnyAction, Dispatch} from "redux";
import {ThunkAction, ThunkDispatch} from "redux-thunk";
import {UploadedFiles} from "../../pages/layout/pages/UploadImages";
import {randomTokenName} from "../../App";
import {API_URL} from "../../configureStore";
import {loading} from "../shared/actions";
import request from "../../helpers/request";

export const getPictures = (pictures: object) => (dispatch: Dispatch<any>) => {
	return dispatch({type: PicturesActionTypes.GET_PICTURES, data: pictures});
};

export const loadMore = (pictures: object) => (dispatch: Dispatch<any>) => {
	return dispatch({type: PicturesActionTypes.LOAD_MORE, data: pictures});
};

export const clearPictures = () => (dispatch: Dispatch<any>) => {
	return dispatch({type: PicturesActionTypes.CLEAR_PICTURES})
};

export const updatePictureCoordinates = (id: number, x: number, y: number, z: number) => (dispatch: Dispatch<any>) => {
	return dispatch({type: PicturesActionTypes.UPDATE_PICTURE_COORDINATES, data: {id, x, y, z}})
};


export const getPicturesBy = (queryParams: string): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
		return new Promise<void>(async (res) => {
			const token = localStorage.getItem(randomTokenName) || sessionStorage.getItem(randomTokenName);
			await request(API_URL + `pictures/?${queryParams}`, {
				method: 'GET',
				headers: {
					'Authorization': 'JWT ' + token,
				}
			})
				.then(res => res.json())
				.then(val => {
					dispatch(getPictures(val));
				});
			res();
		})
	}
};

export const getRecDeletedPicturesBy = (queryParams: string, searchParams: string, page: number, page_size: number): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
		return new Promise<void>(async (res) => {
			dispatch(loading(true));
			const token = localStorage.getItem(randomTokenName) || sessionStorage.getItem(randomTokenName);
			await request(API_URL + `pictures/?is_deleted=True&page_size=${page_size}&${
				(queryParams ? queryParams : '')
				+ (searchParams ? 'search=' + searchParams + '&' : '')
				+ (page ? 'page=' + page + '&' : '')}`, {
				method: 'GET',
				headers: {
					'Authorization': 'JWT ' + token,
				}
			})
				.then(res => res.json())
				.then(val => {
					dispatch(getPictures(val));
				});
			dispatch(loading(false));
			res();
		})
	}
};

export const LoadMore = (queryParams: string): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
		return new Promise<void>(async (res) => {
			const token = localStorage.getItem(randomTokenName) || sessionStorage.getItem(randomTokenName);
			await request(queryParams, {
				method: 'GET',
				headers: {
					'Authorization': 'JWT ' + token,
				}
			})
				.then(res => res.json())
				.then(val => {
					dispatch(loadMore(val));
				});
			res();
		})
	}
};

export const deletePendingPictures = (picturesArray: any): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
		return new Promise<void>(async (res) => {
			dispatch(loading(true));
			const token = localStorage.getItem(randomTokenName) || sessionStorage.getItem(randomTokenName);
			await request(API_URL + 'pending-pictures/multiple-delete-pending-pictures/', {
				method: 'POST',
				headers: {
					'Authorization': 'JWT ' + token,
					'Content-Type': 'application/json'

				},
				body: JSON.stringify({pending_picture: picturesArray})
			});
			dispatch(loading(false));
			res();
		})
	}
};

export const deleteRecDeletedPictures = (picturesArray: any): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
		return new Promise<void>(async (res) => {
			dispatch(loading(true));
			const token = localStorage.getItem(randomTokenName) || sessionStorage.getItem(randomTokenName);
			await request(API_URL + 'pictures/multiple-delete-recently-deleted-pictures/', {
				method: 'POST',
				headers: {
					'Authorization': 'JWT ' + token,
					'Content-Type': 'application/json'

				},
				body: JSON.stringify({picture: picturesArray})
			});
			dispatch(loading(true));
			res();
		})
	}
};

export const getPendingPicturesBy = (queryParams: string): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
		return new Promise<void>(async (res) => {
			dispatch(loading(true));
			const token = localStorage.getItem(randomTokenName) || sessionStorage.getItem(randomTokenName);
			await request(API_URL + `pending-pictures/?${queryParams}`, {
				method: 'GET',
				headers: {
					'Authorization': 'JWT ' + token,
				}
			})
				.then(res => res.json())
				.then(val => {

					dispatch(getPictures(val));
				})
			dispatch(loading(false));
			res();
		})
	}
};

export const deleteSelected = (id: number): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
		return new Promise<void>(async (res) => {
			dispatch(loading(true));
			const token = localStorage.getItem(randomTokenName) || sessionStorage.getItem(randomTokenName);
			await request(API_URL + `pictures/${id}/`, {
				method: 'DELETE',
				headers: {
					'Authorization': 'JWT ' + token,
				}
			});
			dispatch(loading(false));
			res();
		})
	}
};

export const editPicture = (id: number,
                            tags: string[],
                            p_type: string,
                            ins_num: string,
                            tem_id: string,
                            type: string,
                            oldType: string): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (): Promise<void> => {
		return new Promise<void>(async (res) => {
			let valueP_type: any;
			switch (p_type) {
				case 'installation_number':
					valueP_type = +ins_num;
					break;
				case 'temporary_id':
					valueP_type = +tem_id;
					break;
				case 'no_number':
					valueP_type = true;
					break;
				default:
					break;
			}
			const token = localStorage.getItem(randomTokenName) || sessionStorage.getItem(randomTokenName);
			if (oldType === 'no_number' && p_type !== 'no_number') {
				await request(API_URL + 'pictures/update-no-number-picture/', {
					method: 'POST',
					headers: {
						'Authorization': 'JWT ' + token,
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({client: valueP_type, picture_id: id})
				})
					.then(res => res.json())
					.then(() => {

					});
			}
			await request(API_URL + 'pictures/update-picture-type/', {
				method: 'POST',
				headers: {
					'Authorization': 'JWT ' + token,
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({picture_type: type, picture_id: id})
			})
				.then(res => res.json())
				.then(() => {

				});
			await request(API_URL + 'pictures/update-picture-tags/', {
				method: 'POST',
				headers: {
					'Authorization': 'JWT ' + token,
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({tags, picture_id: id})
			})
				.then(res => res.json())
				.then(() => {

				});
			res();
		})
	}
};


export const uploadPictures = (files: UploadedFiles[],
                               tags: string,
                               p_type: string,
                               ins_num: string,
                               tem_id: string,
                               type: string,
                               unmount: boolean): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
		return new Promise<void>(async (res) => {
			dispatch(clearPictures());
			dispatch(loading(true));
			let valueP_type: any;
			switch (p_type) {
				case 'installation_number':
					valueP_type = +ins_num;
					break;
				case 'temporary_id':
					valueP_type = +tem_id;
					break;
				case 'no_number':
					valueP_type = true;
					break;
				default:
					break;
			}
			const token = localStorage.getItem(randomTokenName) || sessionStorage.getItem(randomTokenName);
			const promises = files.map(file => (
				new Promise(async res => {
					const formData = new FormData();
					formData.append('picture', file.file);
					if (unmount) {
						request(API_URL + 'pending-pictures/create-pending-pictures/', {
							method: 'POST',
							headers: {
								'Authorization': 'JWT ' + token,
							},
							body: formData
						})
							.then(res => res.json())
						res()
					} else if (file.selected) {
						formData.append('tags', tags);
						formData.append(p_type, valueP_type);
						formData.append('type', type);
						await request(API_URL + 'pictures/save-picture/', {
							method: 'POST',
							headers: {
								'Authorization': 'JWT ' + token,
							},
							body: formData
						})
							.then(res => res.json())
							.then(() => {
								return res()
							})
					} else {
						await request(API_URL + 'pending-pictures/create-pending-pictures/', {
							method: 'POST',
							headers: {
								'Authorization': 'JWT ' + token,
							},
							body: formData
						})
							.then(res => res.json())
							.then(val => {
								return res()
							})
					}
				})
			));
			Promise.all(promises)
				.then(() => {
					dispatch(loading(false));
					res();
				});
		})
	}
};

export const checkPictureDegree = (picture: any): ThunkAction<Promise<boolean>, {}, {}, AnyAction> => {
	return async (): Promise<boolean> => {
		return new Promise<boolean>(async (res) => {
			const formData = new FormData();
			formData.append('picture', picture);
			const token = localStorage.getItem(randomTokenName) || sessionStorage.getItem(randomTokenName);
			const response = await request(API_URL + 'pictures/check-picture-degree/', {
				method: 'POST',
				headers: {
					'Authorization': 'JWT ' + token,
				},
				body: formData
			})
				.then(res => res.json())
				.then(val => val.message);
			res(response === '180 degree');
		})
	}
};

export const updateCoordinates = (id: number, x: number, y: number, z: number): ThunkAction<Promise<boolean>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<boolean> => {
		return new Promise<boolean>(async (res) => {
			const token = localStorage.getItem(randomTokenName) || sessionStorage.getItem(randomTokenName);
			await request(API_URL + 'pictures/update-picture-coordinates/', {
				method: 'POST',
				headers: {
					'Authorization': 'JWT ' + token,
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					x_range: Math.round(x),
					y_range: Math.round(y),
					zoom_range: Math.round(z),
					picture_id: id
				})
			})
				.then(res => res.json())
			dispatch(updatePictureCoordinates(id, x, y, z));
			res();
		})
	}
};

export const savePandingPictures = (files: any,
                                    tags: string,
                                    p_type: string,
                                    ins_num: string,
                                    tem_id: string,
                                    type: string,
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
		dispatch(loading(true));
		return new Promise<void>(async (res) => {
			let valueP_type: any;
			switch (p_type) {
				case 'installation_number':
					valueP_type = +ins_num;
					break;
				case 'temporary_id':
					valueP_type = +tem_id;
					break;
				case 'no_number':
					valueP_type = true;
					break;
				default:
					break;
			}

			const token = localStorage.getItem(randomTokenName) || sessionStorage.getItem(randomTokenName);
			const promises = files.map((file: any) => (
				new Promise(async res => {
					const formData = new FormData();
					formData.append('pending_picture', file.id);
					formData.append(p_type, valueP_type);
					formData.append('tags', tags);
					formData.append('type', type);

					await request(API_URL + 'pending-pictures/save-pending-pictures-to-client/', {
						method: 'POST',
						headers: {
							'Authorization': 'JWT ' + token,
						},
						body: formData
					})
						.then(res => res.json())
						.then(val => {
							return res()
						});
				})
			));
			Promise.all(promises)
				.then(() => {
					dispatch(loading(true));
					res();
				});
		})
	}
};

export const restorePictures = (picturesIdArray: Array<number>): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (): Promise<void> => {
		return new Promise<void>(async (res) => {

			const promises = picturesIdArray.map(id => (
				new Promise(async res => {
					const formData = new FormData();
					formData.append('picture_id', id + '');
					const token = localStorage.getItem(randomTokenName) || sessionStorage.getItem(randomTokenName);
					await request(API_URL + '/pictures/restore-picture/', {
						method: 'POST',
						headers: {
							'Authorization': 'JWT ' + token,
						},
						body: formData
					})
						.then(res => res.json())
						.then(() => {
							return res()
						})

				})
			));
			Promise.all(promises)
				.then(() => res());
		})
	}
};

export const saveBulkTags = (tags: any[], pictures: any[]): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (): Promise<void> => {
		return new Promise<void>(async (res) => {
			const token = localStorage.getItem(randomTokenName) || sessionStorage.getItem(randomTokenName);
			await request(API_URL + 'pictures/bulk-add-pictures-tags/', {
				method: 'POST',
				headers: {
					'Authorization': 'JWT ' + token,
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({pictures, tags})
			});
			res();
		})
	}
};
