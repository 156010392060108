import React, {Component} from 'react';
//@ts-ignore
import {Pannellum} from 'pannellum-react'
//@ts-ignore
import Modal from "react-responsive-modal";
import {beautifyPictureType} from "../../../../helpers/BeautifierForPm";
import {DELETE_IMAGE} from "../../../../helpers/modalMessages";
import BottomScrollListener from "react-bottom-scroll-listener/dist";
import axios from 'axios';

import Picture from "./picture";
import {Simulate} from "react-dom/test-utils";
import {AWS, BIGSIZE} from "../../../../helpers/image-resize-endpoints";

const refShowImages = React.createRef();

interface ShowImagesProps {
    pictures: object[];
    userId: number;
    userRole: string;
    device: string;
    selectable?: boolean;
    recentlyDeleted? : boolean;
    selectedPictures?: Array<number>;
    onPictureSelect?: (id: number) => void;

    onInfoOpen( picture: any ): void;

    handleDeleteSelected(id: number): void;

    openEdit?(picture: any): void;

    unselectPicture?(id: number): void;

    SavePreviewFor360(id: number, x: number, y: number, z: number): void;

    handleLoadMore(): void;
}

interface ShowImagesState {
    show: boolean[];
    openDelete: boolean
    open360: boolean
    openNormal: boolean
    selectedPictureId: number
    picturePreview: string;
    originalUrl: string;
    pannelum_ready: boolean
    pictureId: number
    x: number
    y: number
    z: number
    pictureReady: Map<number, boolean>
}

const ref = React.createRef();

class ShowImages extends Component<ShowImagesProps, ShowImagesState> {

    state = {
        show: this.props.pictures.map(pic => false),
        openDelete: false,
        selectedPictureId: NaN,
        open360: false,
        openNormal: false,
        picturePreview: '',
        originalUrl: '',
        pannelum_ready: false,
        pictureId: NaN,
        x: NaN,
        y: NaN,
        z: NaN,
        pictureReady: new Map<number, boolean>()
    };

    onClose360Modal = () => {
        this.setState({
            picturePreview: '',
            open360: false,
            pannelum_ready: false,
            pictureId: NaN,
            x: NaN,
            y: NaN,
            z: NaN,
        })
    };

    downloadByURL = (url: any) => {
        const corsUrl: any = url + '?type=cors';
        const fileName: string = corsUrl.split('/').pop().split('?').shift();
        axios({
            method: 'get',
            url: corsUrl,
            responseType: 'blob',
        }).then((data: any) => {
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                navigator.msSaveOrOpenBlob(data.data, fileName);
            } else {
                const link = document.createElement('a');
                link.setAttribute('download', fileName);
                const fileUrl = URL.createObjectURL(data.data);
                link.href = fileUrl;
                link.click();
                link.remove();
                URL.revokeObjectURL(fileUrl);
            }
        });
    };

    onCloseNormal = () => {
        this.setState({
            picturePreview: '',
            originalUrl: '',
            openNormal: false,
        })
    };

    onCloseDelete = (id: number) => {
        this.setState({
            selectedPictureId: id,
            openDelete: false
        })
    };



    open360Modal = async (id: number, url: string, x: number, y: number, z: number) => {
        this.setState({
            pictureId: id,
            picturePreview: url + '?type=cors',
            x,
            y,
            z,
            open360: true,
        })
    };

    onOpenNormal = (url: string) => {
        const pictureName = url.split('/').pop();
        const picturePreview = AWS + BIGSIZE + '/' + pictureName;

        this.setState({
            originalUrl: url,
            picturePreview,
            openNormal: true,
        })
    };

    getPitch = () => {
        this.setState({
            //@ts-ignore
            x: ref.current.getViewer().getYaw(),
            //@ts-ignore
            y: ref.current.getViewer().getPitch(),
            //@ts-ignore
            z: ref.current.getViewer().getHfov(),
        });
    };

    render() {
        const { recentlyDeleted, selectable } = this.props;
        return (
            <div>
                <div
                    className={`image-list with-right-btn cursor-pointer pb-20 
                             ${this.props.selectable? "selected": ""}`}
                >
                    {this.props.pictures.map((picture: any, index: number) =>
                        <Picture
                            userId={this.props.userId}
                            userRole={this.props.userRole}
                            selectable={selectable}
                            onPictureSelect={this.props.onPictureSelect}
                            unselectPictures={this.props.unselectPicture}
                            selectedPictures={this.props.selectedPictures}
                            recentlyDeleted={recentlyDeleted}
                            key={index}
                            onInfoOpen={this.props.onInfoOpen}
                            onOpenNormal={this.onOpenNormal}
                            open360Modal={this.open360Modal}
                            device={this.props.device}
                            picture={picture}
                        />
                        )}
                </div>

                {/*
					//@ts-ignore*/}
                <div className="modal modal-sm" ref={refShowImages}>
                    <Modal
                        open={this.state.openDelete}
                        onClose={() => this.onCloseDelete(NaN)}
                        center
                        container={refShowImages.current}
                    >
                        <div>
                            <p className="font-md pt-40">{DELETE_IMAGE}</p>
                            <div className="flex justify-end mt-50">
                                <button className="btn outline text mr-15"
                                        onClick={() => this.onCloseDelete(NaN)}>Cancel
                                </button>
                                <button className="btn filled warning ripple" onClick={() => {
                                    this.props.handleDeleteSelected(this.state.selectedPictureId);
                                    this.onCloseDelete(NaN)
                                }}>
                                    <span>Delete</span>
                                </button>
                            </div>
                        </div>
                    </Modal>
                </div>
                <Modal
                    open={this.state.open360}
                    onClose={this.onClose360Modal}
                    center
                    onEntered={() => this.setState({pannelum_ready: true})}
                >
                    {this.state.pannelum_ready &&
                    <div>
                        <Pannellum
                            showControls={false}
                            yaw={this.state.x || 0}
                            pitch={this.state.y || 0}
                            hfov={this.state.z || 100}
                            autoLoad
                            image={this.state.picturePreview}
                            id='containers'
                            width='600px'
                            height='480px'
                            ref={ref}
                            onMouseup={this.getPitch}
                            maxHfov={100}
                        />
                        <button onClick={() => this.downloadByURL(this.state.picturePreview)}
                                className="btn p-0 link-btn with-icon download-btn color-white">
								<span className="icon-download white icon">
									<span className="path1"></span>
									<span className="path2"></span>
								</span>
                            <span>download</span>
                        </button>
                        {this.props.userRole === 'back_officer' &&
                        <button className="btn-360" onClick={() => {
                            this.props.SavePreviewFor360(this.state.pictureId,
                                this.state.x,
                                this.state.y,
                                this.state.z);
                            this.onClose360Modal();
                        }}>Save as preview</button>}
                    </div>
                    }
                </Modal>

                <Modal
                    open={this.state.openNormal}
                    onClose={this.onCloseNormal}
                    center
                >
                    <div className="pos-rel">
                        <img src={this.state.picturePreview}/>
                        <button className="btn p-0 link-btn with-icon download-btn color-white"
                                onClick={() => this.downloadByURL(this.state.originalUrl)}>
								<span className="icon-download white icon">
									<span className="path1"></span>
									<span className="path2"></span>
								</span>
                            <span>download</span>
                        </button>
                    </div>
                </Modal>
                <BottomScrollListener onBottom={this.props.handleLoadMore}/>
            </div>

        );
    }
}

export default ShowImages;
