import * as Yup from 'yup';

const requiredFieldText = 'This field is required';
const validEmailText = 'Please enter valid email';
const maxLength = (length: number) => `Ensure this field has no more than ${length} characters.`;

const first_name = Yup.string().max(50,maxLength(50)).required(requiredFieldText);
const name = Yup.string().max(50,maxLength(50)).required(requiredFieldText);
const index = Yup.string().max(50,maxLength(50)).required(requiredFieldText);
const last_name = Yup.string().max(50,maxLength(50)).required(requiredFieldText);
const email = Yup.string().max(254,maxLength(254)).email(validEmailText).required(requiredFieldText);
const password = Yup.string().max(255,maxLength(255)).required(requiredFieldText);
const role = Yup.string().oneOf(['admin','back_officer','worker']).required();
const confirm_password = Yup.string()
  .oneOf([Yup.ref('password')],'Passwords don\'t match')
  .required(requiredFieldText);
const tagValue = Yup.string().required();
const installation_number = Yup.string().required();
const old_password = Yup.string().max(255,maxLength(255)).required(requiredFieldText);
const tag = Yup.string().max(100).required(requiredFieldText);

export const UserFormSchema = Yup.object().shape({
  first_name,
  last_name,
  email,
  role,
});

export const SignInSchema = Yup.object().shape({
  email,
  password
});

export const EmailSchema = Yup.object().shape({
  email,
});

export const SignUpSchema = Yup.object().shape({
  first_name,
  last_name,
  password,
  confirm_password,
});

export const ChangePasswordSchema = Yup.object().shape({
  password,
  confirm_password,
});

export const Step3Schema = Yup.object().shape({
  tagValue
});

export const Step1Schema = Yup.object().shape({
  installation_number
});

export const ClientFormSchema = Yup.object().shape({
  name,
  index,
});

export const ProfileChangePasswordSchema = Yup.object().shape({
  old_password,
  password,
  confirm_password,
});

export const TagsValidationSchema = Yup.object().shape({
  tag
});
