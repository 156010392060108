import React from 'react';
import {withFormik, FormikProps} from 'formik';

import AddingTagsBottomBar from "./AddingTagsBottomBar";
import {Step3Schema} from "../../../../helpers";
import ScrollArea from "react-scrollbar";
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css';

export interface FormValues {
    tags: string[]
    tagValue: string
}

interface OtherProps {
    showDefault: boolean
    defaultTags: any[],
    next: string,

    toggleShowDefault(tags: string[]): void

    previousStep(tags: string[]): void;

    nextStep(tags: string[]): void

    loadMoreTags(next: string): void
}

const InnerForm = (props: OtherProps & FormikProps<FormValues>) => {
    const {
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        previousStep,
        nextStep,
        setValues,
        showDefault,
        toggleShowDefault,
        defaultTags,
        next,
        loadMoreTags
    } = props;
    return (
        <div onClick={ e => {
            if (showDefault){
                toggleShowDefault(values.tags)
            }
        }}>
            <form onSubmit={handleSubmit} className="container-sm py-100 p-sm-0" autoComplete="off">
                <div className="box">
                    <div className="stepper">
                        <div className="stepper-header">
                            <ul className="stepper-header-top">
                                <li className="active"><span className="icon icon-checked"/></li>
                                <li className="active"><span className="icon icon-checked"/></li>
                                <li className="active"><span>3</span></li>
                            </ul>
                            <div className="stepper-header-bottom">
                                <h4 className="fw-400 text-uppercase">step 3</h4>
                                <h3>Add Tags</h3>
                            </div>
                        </div>
                        <div className="stepper-body pb-100">
                            <div className="px-80 px-sm-15">
                                <div className="tag-dropdown">
                                    <div className="textfield with-btn with-dropdown">
                                        <input
                                            type="text"
                                            id="tags"
                                            name="tagValue"
                                            value={values.tagValue}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder="Enter tag here"
                                        />
                                        <span onClick={() => {
                                            if (!showDefault){
                                                toggleShowDefault(values.tags);
                                            }
                                        }}
                                              className="icon-arrow-dropdown icon"/>
                                        <button type="submit" className="no-style">
                                            <span className="icon-add icon"/>
                                        </button>
                                    </div>
                                    {showDefault &&
                                    <div   onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }}>
                                        <PerfectScrollbar
                                            onYReachEnd={() => {
                                                if (next) {
                                                    loadMoreTags(next);
                                                }
                                            }}
                                            className="dropdown-content"
                                        >
                                            <h5 className="fw-500 color-text-70 mb-20">Default Tags</h5>
                                            <ul className="tag-group pb-30">
                                                {defaultTags.map((tag, index) => (
                                                    <li onClick={() => {
                                                        if (!values.tags.includes(tag.name)) {
                                                            setValues({
                                                                tagValue: values.tagValue,
                                                                tags: [...values.tags, tag.name]
                                                            })
                                                        }
                                                    }}
                                                        key={index}
                                                        className={"tag-item" + (values.tags.includes(tag.name) ? " selected" : '')}
                                                    >
														<span
                                                            className="tag-text">{tag.name}</span>
                                                    </li>
                                                ))}
                                            </ul>
                                        </PerfectScrollbar></div>
                                    }
                                </div>

                                <ul className="tag-group">
                                    {values.tags.map((tag, index) => (
                                        <li key={index} className="tag-item with-close">
                                            <span className="tag-text">{tag}</span>
                                            <span onClick={() => {
                                                values.tags.splice(index, 1);
                                                setValues({
                                                    tagValue: values.tagValue, tags: values.tags
                                                })
                                            }} className="icon-close icon"/></li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <AddingTagsBottomBar
                previousStep={() => previousStep(values.tags)}
                nextStep={() => nextStep(values.tags)}
            />
        </div>
    )
};

interface AddingTagsStep3FormProps {
    initialValues: FormValues,
    showDefault: boolean,
    defaultTags: any[],
    next: string

    loadMoreTags(next: string): void

    toggleShowDefault(tags: string[]): void

    nextStep(tags: string[]): void

    previousStep(tags: string[]): void;
}

export const AddingTagsStep3Form = withFormik<AddingTagsStep3FormProps, FormValues>({
    enableReinitialize: true,

    mapPropsToValues: props => {
        const {initialValues} = props;
        return {
            tags: initialValues.tags,
            tagValue: initialValues.tagValue
        }
    },

    validationSchema: Step3Schema,

    handleSubmit(
        {tags, tagValue}: FormValues,
        {setValues}
    ) {
        if (!tags.includes(tagValue)) {
            setValues({tagValue: '', tags: [...tags, tagValue]});
        }
    },

})(InnerForm);
