import React from 'react';


interface HeaderProps {
	restorePictures(): void

	handleDeleteSelected(): void

	// selected_count: number
}


class RecentlyDeletedBottomBar extends React.Component<HeaderProps> {

	render() {
		const {handleDeleteSelected, restorePictures} = this.props;
		return (
			<div className="bar-bottom blue-bar text-right">
				<div className="container">
					<ul className="inline-flex">
						<li>
							<button onClick={handleDeleteSelected} className="no-style cursor-pointer">
                                <span className="icon-delete icon mr-10">
                                  <span className="path1"/>
                                  <span className="path2"/>
                                </span>
								<span>Delete</span>
							</button>
						</li>
						<li>
							<button onClick={restorePictures} className="no-style cursor-pointer">
								<span>Restore</span>
								<span className="icon-restore icon ml-10"/>
							</button>
						</li>
					</ul>
				</div>
			</div>
		);
	}
}

export default RecentlyDeletedBottomBar;