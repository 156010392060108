import React from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import Signup from './pages/auth/Signup/index';
import Signin from './pages/auth/Signin/index';
import Forgot from './pages/auth/Forgot/pages/Forgot';
import {Store} from 'redux';
import {ApplicationState} from './store';
import {Provider} from 'react-redux';
import {toast} from "react-toastify";
import NewPassword from "./pages/auth/Forgot/pages/NewPassword";
import PrivateRoutes from "./PrivateRoutes";
import 'react-toastify/dist/ReactToastify.css';
import * as ROUTES from './helpers/routes';

interface Props {
	store: Store<ApplicationState>,
}

toast.configure({
	autoClose: 3000,
});

export const randomTokenName = 'alns1290wpfoaope90jq23pofapsd90f';

class App extends React.Component<Props> {
	render() {
		return (
			<Provider store={this.props.store}>
				<BrowserRouter>
					<Switch>
						<Route exact path='/' component={Signin}/>
						<Route exact path={ROUTES.SIGN_UP} component={Signup}/>
						<Route exact path={ROUTES.SIGN_IN} component={Signin}/>
						<Route exact path={ROUTES.FORGOT_PASSWORD} component={Forgot}/>
						<Route exact path={ROUTES.RESET_PASSWORD} component={NewPassword}/>
						<PrivateRoutes/>
					</Switch>
				</BrowserRouter>
			</Provider>
		);
	}
}

export default App;
