import React from 'react';
import {FormValues, UserForm, FormInitialValues} from '../components/Admin_Users_Components/UserForm';
import {compose} from 'recompose';
import {RouteComponentProps, withRouter} from "react-router";
import {connect} from 'react-redux'
import Modal from 'react-responsive-modal';
import {User} from '../../../store/users/types';
import {ConnectedReduxProps, ApplicationState} from '../../../store';
import {
	fetchUsers,
	addNewUser,
	editUserById,
	verifyToken,
	Users,
	resendEmail,
	activateUser,
	deactivateUser,
} from '../../../store/users/actions';
import UsersTable from "../components/Admin_Users_Components/UsersTable";
import {initialUserState} from '../../../store/users/reducer';
import {SessionState} from "../../../store/session/types";
import {toast} from "react-toastify";
import {getUser} from "../../../store/session/actions";
import {clearSearchValue} from "../../../store/shared/actions";
import AquaSecLoader from "../components/Loader";
import {
	ADMIN_USER_CREATED,
	USER_DEACTIVATED_BY_ADMIN,
	USER_ACTIVATED_BY_ADMIN,
	ADMIN_USER_EDITED,
	ADMIN_USER_ERROR_ON_CREATION,
	EMAIL_ACTIVATION_MESSAGE_FAIL,
	EMAIL_ACTIVATION_MESSAGE_SUCCESS
} from "../../../helpers/toastMessages";
import {ACTIVATE_USER, DEACTIVATE_USER} from "../../../helpers/modalMessages";


const refDeleteUser = React.createRef();

interface PropsFromState {
	users: Users,
	errorMessage: any,
	session: SessionState,
	searchValue: string,
	loading: boolean
}

interface PropsFromDispatch {
	fetchUsers: (searchParam: string, page: number) => void
	addNewUser: (email: string, role: string, first_name: string, last_name: string) => void
	editUserById: (id: number, email: string, role: string, first_name: string, last_name: string) => void
	verifyToken: (token: string) => void
	getUser: (id: number) => void
	activateUser: (id: number) => void
	deactivateUser: (id: number) => void

	clearSearchValue(): void

	resendEmail(email: string): void
}

interface AdminUsersProps extends RouteComponentProps<any>, PropsFromState, PropsFromDispatch, ConnectedReduxProps {
	openRightSidebar: boolean

	openAdminUserRightSidebar(): void

	bodyOverlay(): void
}

interface AdminUsersState {
	page: number,
	openLeftSidebar: boolean,
	openRightSidebar: boolean,
	singleUser: User,
	open: boolean,
	title: string,
	disabled: boolean,
	initialValues: FormValues,
	openGlobalFilter: boolean
	searchTypes: number
	onceLoaded: boolean
}

class AdminUsers extends React.Component<AdminUsersProps, AdminUsersState> {

	state: AdminUsersState = {
		page: 1,
		singleUser: initialUserState,
		openLeftSidebar: false,
		openRightSidebar: false,
		open: false,
		title: 'Add User',
		disabled: false,
		initialValues: FormInitialValues,
		openGlobalFilter: false,
		searchTypes: 0,
		onceLoaded: false,
	};

	async componentDidMount() {
		await this.props.clearSearchValue();
		await this.props.fetchUsers(this.props.searchValue, 1);
		this.setState({
			onceLoaded: true
		})
	}

	handleAddNewUser = async (email: string, role: string, first_name: string, last_name: string) => {
		try {
			await this.props.addNewUser(email, role, first_name, last_name);
			if (Object.keys(this.props.errorMessage).length === 0) {
				this.props.openAdminUserRightSidebar();
				this.props.bodyOverlay();
				await this.props.clearSearchValue();
				await this.props.fetchUsers(this.props.searchValue, this.state.page);
				toast.dismiss();
				toast(() =>
					<div className="toast-style success">
						<div className="left-side">
							<span className="icon-success icon">
								<span className="path1"/>
								<span className="path2"/>
							</span>
						</div>
						<div className="right-side">
							<p>{ADMIN_USER_CREATED}</p>
						</div>
					</div>
				)
			} else {
				toast.dismiss();
				toast(() =>
					<div className="toast-style error">
						<div className="left-side">
							<span className="icon-error icon">
								<span className="path1"/>
								<span className="path2"/>
							</span>
						</div>
						<div className="right-side">
							<p>{ADMIN_USER_ERROR_ON_CREATION}</p>
						</div>
					</div>
				)
			}
		} catch (e) {
		}
	};

	handleEditNewUser = async (email: string, role: string, first_name: string, last_name: string) => {
		try {
			await this.props.editUserById(this.state.singleUser.id, email, role, first_name, last_name);
			if (Object.keys(this.props.errorMessage).length === 0) {
				await this.props.fetchUsers(this.props.searchValue, this.state.page);
				toast.dismiss();
				toast(() =>
					<div className="toast-style success">
						<div className="left-side">
							<span className="icon-success icon">
								<span className="path1"/>
								<span className="path2"/>
							</span>
						</div>
						<div className="right-side">
							<p>{ADMIN_USER_EDITED}</p>
						</div>
					</div>
				);
				this.props.openAdminUserRightSidebar();
				this.props.bodyOverlay();
			} else {
				toast.dismiss();
				toast(() =>
					<div className="toast-style error">
						<div className="left-side">
							<span className="icon-error icon">
								<span className="path1"/>
								<span className="path2"/>
							</span>
						</div>
						<div className="right-side">
							<p>{this.props.errorMessage.data}</p>
						</div>
					</div>
				);
			}
		} catch (e) {
		}
	};

	deleteUser = async (user: User) => {
		try {
			if (user.is_deleted){
				await this.props.activateUser(user.id);
			}else {
				await this.props.deactivateUser(user.id);
			}
			if (Object.keys(this.props.errorMessage).length === 0) {
				this.onCloseModal();
				await this.props.fetchUsers(this.props.searchValue, this.state.page);
				toast.dismiss();
				toast(() =>
					<div className="toast-style success">
						<div className="left-side">
							<span className="icon-success icon">
								<span className="path1"/>
								<span className="path2"/>
							</span>
						</div>
						<div className="right-side">
							<p>{user.is_deleted? USER_ACTIVATED_BY_ADMIN: USER_DEACTIVATED_BY_ADMIN}</p>
						</div>
					</div>
				);

			} else {
				this.onCloseModal();
				toast.dismiss();
				toast(() =>
					<div className="toast-style error">
						<div className="left-side">
							<span className="icon-error icon">
								<span className="path1"/>
								<span className="path2"/>
							</span>
						</div>
						<div className="right-side">
							<p>{this.props.errorMessage.message}</p>
						</div>
					</div>
				);
			}
		} catch (e) {
		}
	};

	handlePageChange = async (page: number) => {
		this.setState({page});
		await this.props.fetchUsers(this.props.searchValue, page);
	};

	openAddNewUser = () => {
		this.props.openAdminUserRightSidebar();
		this.setState(prevState => ({
			title: 'Add User',
			initialValues: FormInitialValues,
			openRightSidebar: !prevState.openRightSidebar,
			disabled: false,
		}));
		this.props.bodyOverlay();
	};

	openEdit = (user: User) => {
		const userInfo: FormValues = {
			first_name: user.first_name,
			last_name: user.last_name,
			email: user.email,
			role: user.role,
		};
		this.props.openAdminUserRightSidebar();
		this.setState(() => ({
			singleUser: user,
			title: 'Edit User',
			initialValues: userInfo,
			disabled: true,
		}));
		this.props.bodyOverlay();
	};

	resendMail = async (email: string) => {
		await this.props.resendEmail(email);
		if(Object.keys(this.props.errorMessage).length === 0) {
			toast.dismiss();
			toast(() =>
				<div className="toast-style success">
					<div className="left-side">
							<span className="icon-success icon">
								<span className="path1"/>
								<span className="path2"/>
							</span>
					</div>
					<div className="right-side">
						<p>{EMAIL_ACTIVATION_MESSAGE_SUCCESS}</p>
					</div>
				</div>
			);
			await this.props.fetchUsers(this.props.searchValue,this.state.page)
		} else {
			toast.dismiss();
			toast(() =>
				<div className="toast-style error">
					<div className="left-side">
							<span className="icon-error icon">
								<span className="path1"/>
								<span className="path2"/>
							</span>
					</div>
					<div className="right-side">
						<p>{EMAIL_ACTIVATION_MESSAGE_FAIL}</p>
					</div>
				</div>
			);
		}
	};

	onOpenModal = (user: User) => {
		this.setState({
			open: true,
			singleUser: user
		});
	};

	onCloseModal = () => {
		this.setState({
			open: false,
			singleUser: initialUserState,
		});
	};

	onStopPropagtion = (e: React.MouseEvent<HTMLElement>) => {
		e.stopPropagation();
	};


	render() {
		const showClassName = this.props.openRightSidebar ? "show" : " ";

		return (
			(!this.state.onceLoaded)
				? (<AquaSecLoader />)
				: (<>
					{/*right sidebar*/}
					<aside onMouseDown={this.onStopPropagtion} className={"sidebar-right " + showClassName}>
						<div className="sidebar-right-inner">
							<UserForm
								user={this.state.singleUser}
								onOpenModal={this.onOpenModal}
								isOpen={this.props.openRightSidebar}
								title={this.state.title}
								onRightSidebarOpen={this.props.openAdminUserRightSidebar}
								bodyOverlay={this.props.bodyOverlay}
								onSubmit={this.state.title === 'Add User' ? this.handleAddNewUser : this.handleEditNewUser}
								disabled={this.state.disabled}
								initialValues={this.state.initialValues}
							/>
						</div>
					</aside>
					{/*right sidebar*/}

					{/*users table*/}
					<UsersTable
						adminID={this.props.session.pk}
						searchParam={this.props.searchValue}
						users={this.props.users.results}
						openAddNewUser={this.openAddNewUser}
						onOpenModal={this.onOpenModal}
						openEdit={this.openEdit}
						total={this.props.users.count || 0}
						handlePageChange={this.handlePageChange}
						resendMail={this.resendMail}
						page={this.state.page}
					/>
					{/*users table*/}
					{/*
  // @ts-ignore */}
					<div className="modal" ref={refDeleteUser}>
						<Modal
							open={this.state.open}
							onClose={this.onCloseModal}
							center
                            container={refDeleteUser.current}
						>
							<div className="p-40">
								<p className="font-md pt-40">{this.state.singleUser.is_deleted? ACTIVATE_USER: DEACTIVATE_USER }</p>
								<div className="flex justify-end mt-50">
									<button className="btn outline text mr-15" onClick={this.onCloseModal}>No</button>
									<button className="btn filled warning ripple" onClick={() => this.deleteUser(this.state.singleUser)}>
										<span>Yes</span>
									</button>
								</div>
							</div>
						</Modal>
					</div>
				</>)
		);
	}

}

const mapStateToProps = (state: ApplicationState): PropsFromState => {
	return {
		users: state.users,
		errorMessage: state.errorMessage,
		session: state.session,
		searchValue: state.shared.searchValue,
		loading: state.shared.loading,
	}
};

const mapDispatchToProps: PropsFromDispatch = {
	fetchUsers,
	addNewUser,
	editUserById,
	verifyToken,
	getUser,
	clearSearchValue,
	resendEmail,
	activateUser,
	deactivateUser,
};

export default compose<AdminUsersProps, {}>(
	withRouter,
	connect<PropsFromState, PropsFromDispatch, {}, ApplicationState>(
		mapStateToProps,
		mapDispatchToProps,
	)
)(AdminUsers);
