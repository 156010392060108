import React from 'react';
import {connect} from 'react-redux';
import {ApplicationState} from "../../../../store";
import {fetchUsers} from "../../../../store/users/actions";
import {setSearchValue} from "../../../../store/shared/actions";

interface PropsFromState {
	searchValue: string
}

interface PropsFromDispatch {
	fetchUsers(searchParam: string, page: number): void

	setSearchValue(searchParam: string): void
}

interface SearchUsersState {
	search: string
}

class SearchUsers extends React.Component<any, SearchUsersState> {
	state = {
		search: this.props.searchValue,
	};

	componentWillReceiveProps(nextProps: Readonly<any>, nextContext: any): void {
		if(nextProps.searchValue !== this.props.searchValue) {
			this.setState({
				search: nextProps.searchValue,
			});
		}
	}

	handleSearchChange = (e: React.FormEvent<HTMLInputElement>) => {
		this.setState({
			search: e.currentTarget.value,
		})
	};

	searchUsers = async (e: any) => {
		e.preventDefault();
		const {search} = this.state;
		await this.props.setSearchValue(search);
		this.props.fetchUsers(search, 1);
	};

	render() {
		const {search} = this.state;
		return (
			<form onSubmit={this.searchUsers} className="textfield mt-15 with-btn">
				<input
					type="text"
					placeholder="Search Users"
					value={search}
					onChange={this.handleSearchChange}
				/>
				<button type='submit' className="no-style">
					<span className="icon-search font-lg"/>
				</button>
			</form>
		);
	}
}

const mapStateToProps = (state: ApplicationState) => {
	return {
		searchValue: state.shared.searchValue
	}
};

const mapDispatchToProps: PropsFromDispatch = {
	fetchUsers,
	setSearchValue,
};

export default connect<PropsFromState, PropsFromDispatch, {}, ApplicationState>(
	mapStateToProps,
	mapDispatchToProps,
)(SearchUsers);
