import React from 'react';
import {FormValues, FilterForm, FormInitialValues} from '../components/Gallery/FilterForm';
import ImageInfo from '../components/Gallery/ImageInfo';
import {compose} from 'recompose';
import {Route, RouteComponentProps, withRouter} from "react-router";
import {connect} from 'react-redux'
import {ConnectedReduxProps, ApplicationState} from '../../../store';
import {SessionState} from "../../../store/session/types";
import {toast} from "react-toastify";
import {getUser} from "../../../store/session/actions";
import {verifyToken} from "../../../store/users/actions";
import {
    clearClients,
    getClientsBySearchParam,
    getClientsWithPictures, getFolderNameByID, getInstallationNumbers, getMoreOptions, getTemporaryIDs, loadOptions
} from "../../../store/clients/actions";
import NoNumber from "../components/Gallery/NoNumber";
import {
    deleteSelected,
    getPicturesBy,
    clearPictures,
    LoadMore,
    editPicture,
    updateCoordinates,
    saveBulkTags,
} from "../../../store/pictures/actions";
import InstallationOrTemoraryPictures from "../components/Gallery/InstallationOrTemoraryPictures";
import {clearSearchValue, setSearchValue} from "../../../store/shared/actions";
import {EditPicturesStep2Form} from "../components/Gallery/EditPicturesStep2Form";
import {EditPicturesStep3Form} from "../components/Gallery/EditPicturesStep3Form";
import {EditPicturesStep1Form} from "../components/Gallery/EditPicturesStep1Form";
import {ClientsState} from "../../../store/clients/types";
import Temporary from "../components/Gallery/Temporary";
import Installation from "../components/Gallery/Installation";
import AquaSecLoader from "../components/Loader";
import {PicturesState} from "../../../store/pictures/types";
import {SINGLE_PICTURE_DELETED} from "../../../helpers/toastMessages";
import {TagsState} from "../../../store/tags/types";
import {getDefaultTags, getMoreTags} from "../../../store/tags/actions";
import Modal from "react-responsive-modal";
import {DELETE_IMAGE} from "../../../helpers/modalMessages";
import {AddTags} from "../components/Gallery/addTags";

const refShowImages = React.createRef();


interface PropsFromState {
    errorMessage: any,
    session: SessionState,
    searchValue: string,
    installationNumberClients: [],
    temporaryIdClients: [],
    pictures: PicturesState,
    nextValue: string
    loading: boolean
    clients: ClientsState,
    device: string,
    defaultTags: TagsState,
}

interface PropsFromDispatch {
    editPicture(id: number,
                tags: string[],
                p_type: string,
                ins_num: string,
                tem_id: string,
                type: string,
                oldType: string): void

    setSearchValue(searchParam: string): void

    verifyToken(token: string): void

    getUser(id: number): void

    getPicturesBy(queryParams: string): void

    getClientsWithPictures(): void

    deleteSelected(id: number): void

    clearPictures(): void

    LoadMore(queryParams: string): void

    getClientsBySearchParam(search: string, type: string): void

    updateCoordinates(id: number, x: number, y: number, z: number): void

    clearClients(): void

    clearSearchValue(): void

    getFolderNameByID(id: number): any

    getInstallationNumbers(page?: number, search?: string): void

    getTemporaryIDs(page?: number, search?: string): void

    getMoreOptions(next: string, type: string): void

    loadOptions(search: string, type: string): any

    getMoreTags(next: string): void

    getDefaultTags(page: number, search: string, limit?: number): any;

    saveBulkTags(tags: any[], pictures: any[]): void;

}

interface GalleryProps extends RouteComponentProps<any>, PropsFromState, PropsFromDispatch, ConnectedReduxProps {

    openRightSidebar: boolean

    openInfoBar: boolean

    openFilter(): void

    openInfoHandler(): void

    bodyOverlay(): void

    runGlobalFilter(): void

    oncloseSidebar(): void
}

interface GalleryState {
    openLeftSidebar: boolean,
    openRightSidebar: boolean,
    initialValues: FormValues,
    folders: [],
    searchParam: string,
    openGlobalFilter: boolean
    filterParams: string
    searchTypes: number,
    type: string,
    singlePicture: any,
    installation_number_options: { value: string, label: string }[],
    temporary_id_options: { value: string, label: string }[]
    temporary_id: string,
    installation_number: string,
    installation_number_name: string,
    temporary_id_name: string,
    editedPictureType: string,
    pictureType: string
    searchInstallationFolderValue: string
    searchTemporaryFolderValue: string
    savePictureType: string
    editedFolderID: number
    folderName: string
    onceLoaded: boolean
    filterOptionsCount: number
    pageSize: number;
    showDefault: boolean;
    openDelete: boolean;
    selectedPictures: any[];

}

class Gallery extends React.Component<GalleryProps, GalleryState> {

    state: GalleryState = {
        openLeftSidebar: false,
        openRightSidebar: false,
        openGlobalFilter: false,
        initialValues: FormInitialValues,
        folders: [],
        searchParam: '',
        filterParams: '',
        searchTypes: this.props.location.state && this.props.location.state.searchTypes,
        type: '',
        singlePicture: {tags: [], type: ''},
        editedPictureType: '',
        editedFolderID: NaN,
        installation_number_options: [],
        temporary_id_options: [],
        temporary_id: '',
        installation_number: '',
        installation_number_name: '',
        temporary_id_name: '',
        pictureType: 'no_number',
        searchInstallationFolderValue: '',
        searchTemporaryFolderValue: '',
        savePictureType: '',
        folderName: '',
        onceLoaded: false,
        filterOptionsCount: 0,
        pageSize: 21,
        showDefault: false,
        openDelete: false,
        selectedPictures: [],
    };

    async componentDidMount() {
        const type = this.props.match.params.type;
        const id = this.props.match.params.name;
        if (type !== 'installation_number' &&
            type !== 'temporary_id' && type !== 'no_number') {
            this.props.history.push('/gallery/installation_number');
        }
        if (type === 'no_number' && id) {
            this.props.history.push('/gallery/no_number');
        }
        if (this.props.device === 'pc') {
            this.setState({
                pageSize: 40,
            })
        }
        await this.props.getDefaultTags(1, '', 30);
        await this.props.getClientsWithPictures();
        if(type !== 'no_number') {
            if(id) {
                this.handleLoadInstallationOrTemporary();
            } else {

                if (type === 'temporary_id') {
                    this.setState({
                        folders: this.props.temporaryIdClients,
                        onceLoaded: true,
                    })
                } else {
                    this.setState({
                        folders: this.props.installationNumberClients,
                        onceLoaded: true,
                    })
                }
            }
        } else {
            this.handleLoadNoNumber();
        }
    }

    componentDidUpdate(prevProps: Readonly<GalleryProps>, prevState: Readonly<GalleryState>, snapshot?: any): void {
        if(prevProps.pictures.count === -1 && this.props.pictures.count !== -1) {
            this.setState({
                onceLoaded: true,
            })
        }
    }

    handlePictureTypeChange = async (type: string) => {

        await this.setState({
            searchParam: '',
            filterParams: '',
        });

        if (type === 'installation_number') {
            this.setState({folders: this.props.installationNumberClients})
        } else if (type === 'temporary_id') {
            this.setState({folders: this.props.temporaryIdClients})
        } else if (type === 'no_number') {
            const queryParams = `is_deleted=False&no_number=True&search=${this.state.searchParam}&${this.state.filterParams}&page=1&page_size=${this.state.pageSize}`;
            await this.props.getPicturesBy(queryParams)
        }

        await this.props.history.push({
            pathname: `${type}`,
        });
    };

    handleChosenFolder = async (id: number, type?: string) => {
        this.setState({
            searchParam: '',
            filterParams: '',
        });
        await this.props.clearPictures();
        const folderName = await this.props.getFolderNameByID(id);
        this.setState({
            folderName,
        });
        const path = type || this.props.match.params.type;
        const queryParams = `is_deleted=False&${path}=${encodeURIComponent(folderName)}&search=${this.state.searchParam}&${this.state.filterParams}&page=1&page_size=${this.state.pageSize}`;
        await this.props.getPicturesBy(queryParams);
        this.props.history.push({
            pathname: `/gallery/${path}/${id}`
        })
    };

    handleSearchFolder = async (search: string, type: string) => {
        await this.props.getClientsBySearchParam(search, type);
        if (type === 'installation_number') {
            this.setState({
                searchInstallationFolderValue: search,
                folders: this.props.installationNumberClients,
            })
        } else {
            this.setState({
                searchTemporaryFolderValue: search,
                folders: this.props.temporaryIdClients,
            })
        }
    };

    setSearchValue = async (value: string) => {
        await this.setState({
            searchParam: value
        });
        if (this.props.match.params.type === 'no_number') {
            await this.handleLoadNoNumber(value);
        } else {
            await this.handleLoadInstallationOrTemporary(value);
        }
    };

    toggleShowDefault = (tags: string[]) => {
        const newTags = tags.map(tag => ({name: tag}));
        this.setState((prevState: GalleryState) => ({
            showDefault: !prevState.showDefault,
            singlePicture: {
                ...prevState.singlePicture,
                tags: [...newTags]
            },
        }))
    };

    setFilterOptions = async (normal: boolean,
                              threeSixty: boolean,
                              for_future_information: boolean,
                              quotation: boolean,
                              intervention: boolean,
                              startDate: Date,
                              endDate: Date) => {
        let filterQuery = '';
        let optionsCount = 0;
        if (normal) {
            ++optionsCount;
            filterQuery += 'is_normal=True&';
        }
        if (threeSixty) {
            ++optionsCount;
            filterQuery += 'is_normal=False&';
        }
        if (normal && threeSixty) {
            optionsCount = 2;
            filterQuery = '';
        }
        if (for_future_information) {
            ++optionsCount;
            filterQuery += 'for_future_information_boolean=True&';
        }
        if (quotation) {
            ++optionsCount;
            filterQuery += 'quotation_boolean=True&';
        }
        if (intervention) {
            ++optionsCount;
            filterQuery += 'intervention_boolean=True&';
        }
        if (startDate) {
            ++optionsCount;
            filterQuery += 'from_date=' + startDate.toLocaleDateString() + '&';
        }
        if (endDate) {
            ++optionsCount;
            filterQuery += 'to_date=' + endDate.toLocaleDateString() + '&';
        }
        await this.setState({
            filterParams: filterQuery,
            filterOptionsCount: optionsCount,
        });
        // this.props.clearPictures();
        if (this.props.match.params.type === 'no_number') {
            await this.handleLoadNoNumber();
        } else {
            await this.handleLoadInstallationOrTemporary();
        }
    };

    handleLoadInstallationOrTemporary = async (value?: string) => {
        const folderName = await this.props.getFolderNameByID(this.props.match.params.name);
        if (folderName === 'NOPE') {
            await this.redirectToGallery();
        } else {
            const queryParams = `is_deleted=False&${this.props.match.params.type}=${encodeURIComponent(folderName)}&search=${(value !== undefined) ? value : this.state.searchParam}&${this.state.filterParams}&page=1&page_size=${this.state.pageSize}`;
            await this.props.getPicturesBy(queryParams);
            this.setState({
                folderName,
                onceLoaded: true,
            })
        }
    };

    handleLoadNoNumber = async (value?: string) => {
        const queryParams = `is_deleted=False&no_number=True&search=${(value !== undefined) ? value : this.state.searchParam}&${this.state.filterParams}&page=1&page_size=${this.state.pageSize}`;
        await this.props.getPicturesBy(queryParams)
        this.setState({
            onceLoaded: true,
        })
    };

    handleLoadMore = async (nextValue: string) => {
        await this.props.LoadMore(nextValue);
    };

    redirectToGallery = async () => {
        let type = this.props.match.params.type;
        this.props.oncloseSidebar();
        await this.props.getClientsWithPictures();
        if (type === 'temporary_id') {
            this.setState({
                folders: this.props.temporaryIdClients,
                onceLoaded: true,
            })
        } else if (type === 'installation_number') {
            this.setState({
                folders: this.props.installationNumberClients,
                onceLoaded: true
            })
        }
        this.props.history.replace(`/gallery/${type}`)
    };

    goBack = async () => {
        let type = this.props.match.params.type;
        await this.props.getClientsWithPictures();
        if (type === 'temporary_id') {
            this.setState({
                folders: this.props.temporaryIdClients,
                filterOptionsCount: 0,
            })
        } else if (type === 'installation_number') {
            this.setState({
                folders: this.props.installationNumberClients,
                filterOptionsCount: 0,
            })
        }
        this.props.history.push(`/gallery/${this.props.match.params.type}`)
    };

    handleDeleteSelected = async (id: number) => {
        await this.props.deleteSelected(id);
        // await this.props.clearPictures();
        if (this.props.match.params.type === 'no_number') {
            const queryParams = `is_deleted=False&no_number=True&search=${this.state.searchParam}&${this.state.filterParams}&page=1&page_size=${this.state.pageSize}`;
            await this.props.getPicturesBy(queryParams);
        } else {
            const queryParams = `is_deleted=False&${this.props.match.params.type}=${this.state.folderName}&search=${this.state.searchParam}&${this.state.filterParams}&page=1&page_size=${this.state.pageSize}`;
            await this.props.getPicturesBy(queryParams);
        }

        toast.dismiss();
        toast(() =>
            <div className="toast-style success">
                <div className="left-side">
							<span className="icon-success icon">
								<span className="path1"/>
								<span className="path2"/>
							</span>
                </div>
                <div className="right-side">
                    <p>{SINGLE_PICTURE_DELETED}</p>
                </div>
            </div>
        )
    };

    handleEdit = async (picture: any) => {
        await this.props.getTemporaryIDs(1, '');
        await this.props.getInstallationNumbers(1, '');
        this.props.openInfoHandler();

        this.setState({
            installation_number_options: this.props.clients.installation_number_paginated.results.map((client: any) => ({
                value: client.id,
                label: client.name
            })),
            temporary_id_options: this.props.clients.temporary_id_paginated.results.map((client: any) => ({
                value: client.id,
                label: client.name
            })),
            singlePicture: picture,
            editedPictureType: this.props.match.params.type,
            editedFolderID: this.props.match.params.name,
            pictureType: picture.no_number ? 'no_number' : ''
        });
        this.props.history.push(`/gallery/edit/step/1`);
    };

    getMoreOptions = async (next: string, type: string) => {
        if (next) {
            if (type === 'installation_number') {
                await this.props.getMoreOptions(next, 'installation_number');
                this.setState({
                    installation_number_options: this.props.clients.installation_number_paginated.results.map((client: any) => ({
                        value: client.id,
                        label: client.name
                    })),
                })
            } else {
                await this.props.getMoreOptions(next, 'temporary_id');
                this.setState({
                    temporary_id_options: this.props.clients.temporary_id_paginated.results.map((client: any) => ({
                        value: client.id,
                        label: client.name
                    })),
                })
            }
        }
    };

    getMoreTags = async (next: string) => {
        if (next) {
            await this.props.getMoreTags(next);
        }
    };

    saveEditedPicture = async (tags: string[]) => {
        const {editedPictureType, editedFolderID, singlePicture, savePictureType, installation_number, temporary_id, type} = this.state;
        await this.props.clearPictures();
        await this.props.editPicture(singlePicture.id, tags, savePictureType, installation_number, temporary_id, type, this.state.pictureType);
        if (editedPictureType !== 'no_number') {
            await this.handleChosenFolder(editedFolderID, editedPictureType)
        } else {
            if (installation_number) {
                this.props.history.push(`/gallery/installation_number/${this.state.installation_number}`)
            } else if (temporary_id) {
                this.props.history.push(`/gallery/temporary_id/${this.state.temporary_id}`)
            } else {
                this.props.history.push('/gallery/no_number')
            }
        }
    };

    handleEditedPicture = async (tags: string[]) => {
        this.props.history.goBack();
    };

    handleSavePreviewFor360 = async (id: number, x: number, y: number, z: number) => {
        this.props.updateCoordinates(id, x, y, z);
    };

    onStopPropagtion = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
    };

    openInfo = (picture: any) => {
        this.setState({singlePicture: picture});
        this.props.openInfoHandler();
    };

    onCloseDelete = () => {
        this.setState({

            openDelete: false
        })
    };

    onOpenDelete = () => {
        this.props.openInfoHandler();
        this.setState({
            openDelete: true
        })
    };

    stepTwo = (pictureType: string,
               installation_number: string,
               installation_number_name: string,
               temporary_id: string,
               temporary_id_name: string) => {
        this.setState({
            savePictureType: pictureType,
            installation_number,
            installation_number_name,
            temporary_id,
            temporary_id_name,
        });
        this.props.history.push({
            pathname: '/gallery/edit/step/2',
        })
    };

    stepThree = (type: string) => {
        this.setState({
            type,
            singlePicture: {...this.state.singlePicture, type }
        });
        this.props.history.push({
            pathname: '/gallery/edit/step/3',
        })
    };

    async componentWillUnmount() {
        // await this.props.clearPictures();
        await this.props.clearClients();
        await this.props.clearSearchValue();
    }

    saveBulkTags = async (tags: string[], from: string) => {
        let queryParams;
        const {folderName} = this.state;
		await this.props.saveBulkTags(tags, this.state.selectedPictures);
		if (this.props.location.state.from === "no_number"){
            queryParams = `is_deleted=False&${this.props.location.state.from}=True&search=${this.state.searchParam}&${this.state.filterParams}&page=1&page_size=${this.state.pageSize}`;

        }else {
            queryParams = `is_deleted=False&${this.props.location.state.from}=${encodeURIComponent(folderName)}&search=${this.state.searchParam}&${this.state.filterParams}&page=1&page_size=${this.state.pageSize}`;

        }
        await this.props.getPicturesBy(queryParams);
        this.props.history.goBack();
    };

    render() {
        const {device} = this.props;
        const showClassName = this.props.openRightSidebar ? "show" : " ";
        const showInfoBar = this.props.openInfoBar ? "show" : " ";
        const type = this.props.match.params.type;

        return (
            (!this.state.onceLoaded)
                ? (<AquaSecLoader/>)
                : (<>
                    {/*right sidebar*/}
                    <aside onMouseDown={this.onStopPropagtion} className={"sidebar-right " + showClassName}>
                        <div className="sidebar-right-inner">

                            <FilterForm
                                onRightSidebarOpen={this.props.openFilter}
                                isOpen={this.props.openRightSidebar}
                                bodyOverlay={this.props.bodyOverlay}
                                onSubmit={this.setFilterOptions}
                                initialValues={this.state.initialValues}
                            />
                        </div>
                    </aside>
                    {/*right sideBar*/}

                    {/*right infoBar*/}
                    <aside onMouseDown={this.onStopPropagtion} className={"sidebar-right " + showInfoBar}>
                        <div className="sidebar-right-inner">
                            <ImageInfo
                                device={device}
                                onCloseSideBar={this.props.openInfoHandler}
                                handleEdit={this.handleEdit}
                                handleDeleteSelected={this.onOpenDelete}
                                picture={this.state.singlePicture}
                                userRole={this.props.session.role}
                                userId={this.props.session.pk}
                            />
                        </div>
                    </aside>
                    {/*
					//@ts-ignore*/}
                    <div className="modal modal-sm" ref={refShowImages}>
                        <Modal
                            open={this.state.openDelete}
                            onClose={this.onCloseDelete}
                            center
                            container={refShowImages.current}
                        >
                            <div>
                                <p className="font-md pt-40">{DELETE_IMAGE}</p>
                                <div className="flex justify-end mt-50">
                                    <button className="btn outline text mr-15"
                                            onClick={this.onCloseDelete}>Cancel
                                    </button>
                                    <button className="btn filled warning ripple" onClick={() => {
                                        this.handleDeleteSelected(this.state.singlePicture.id);
                                        this.onCloseDelete()
                                    }}>
                                        <span>Delete</span>
                                    </button>
                                </div>
                            </div>
                        </Modal>
                    </div>
                    {/*right infoBar*/}

                    {/*users table*/}
                    <div className="page-wrapper">
                        <div className="container">
                            <Route exact path={'/gallery/installation_number'} render={() => <Installation
                                searchInstallationFolderValue={this.state.searchInstallationFolderValue}
                                handleSearchFolder={this.handleSearchFolder}
                                pictureType={type}
                                handlePictureTypeChange={this.handlePictureTypeChange}
                                folders={this.state.folders}
                                handleChosenFolder={this.handleChosenFolder}
                            />}/>
                            <Route exact path={'/gallery/temporary_id'} render={() => <Temporary
                                searchTemporaryFolderValue={this.state.searchTemporaryFolderValue}
                                handleSearchFolder={this.handleSearchFolder}
                                pictureType={type}
                                handlePictureTypeChange={this.handlePictureTypeChange}
                                folders={this.state.folders}
                                handleChosenFolder={this.handleChosenFolder}
                            />}/>
                            <Route exact path={'/gallery/no_number'} render={() => <NoNumber
                                device={this.props.device}
                                filterOptionsCount={this.state.filterOptionsCount}
                                handleSavePreviewFor360={this.handleSavePreviewFor360}
                                filterParameters={this.state.filterParams}
                                handleEdit={this.handleEdit}
                                bodyOverlay={this.props.bodyOverlay}
                                searchValue={this.state.searchParam}
                                setSearchValue={this.setSearchValue}
                                redirectToGallery={this.redirectToGallery}
                                loaded={this.props.loading}
                                handleLoadNoNumber={this.handleLoadNoNumber}
                                nextValue={this.props.nextValue}
                                handleDeleteSelected={this.handleDeleteSelected}
                                userRole={this.props.session.role}
                                userId={this.props.session.pk}
                                pictures={this.props.pictures}
                                pictureType={type}
                                onRightSidebarOpen={this.props.openFilter}
                                onInfoOpen={this.openInfo}
                                handlePictureTypeChange={this.handlePictureTypeChange}
                                handleLoadMore={this.handleLoadMore}
                                history={this.props.history}
                                match={this.props.match}
                                addSelectedPictures={(pictures) => this.setState({selectedPictures: pictures})}
                            />}/>

                            <Route exact path={`/gallery/${this.props.match.params.type}/${this.props.match.params.name}`}
                                   render={() => <InstallationOrTemoraryPictures
                                       addSelectedPictures={(pictures) => this.setState({selectedPictures: pictures})}
                                       history={this.props.history}
									   match={this.props.match}
                                       device={this.props.device}
                                       filterOptionsCount={this.state.filterOptionsCount}
                                       handleSavePreviewFor360={this.handleSavePreviewFor360}
                                       handleEdit={this.handleEdit}
                                       filterQuery={this.state.filterParams}
                                       bodyOverlay={this.props.bodyOverlay}
                                       searchValue={this.state.searchParam}
                                       setSearchValue={this.setSearchValue}
                                       redirectToGallery={this.redirectToGallery}
                                       loaded={this.props.loading}
                                       nextValue={this.props.nextValue}
                                       handleDeleteSelected={this.handleDeleteSelected}
                                       userRole={this.props.session.role}
                                       userId={this.props.session.pk}
                                       handleLoad={this.handleLoadInstallationOrTemporary}
                                       pictureType={type}
                                       onRightSidebarOpen={this.props.openFilter}
                                       onInfoOpen={this.openInfo}
                                       pictures={this.props.pictures}
                                       chosenFolderName={this.state.folderName}
                                       goBack={this.goBack}
                                       handleLoadMore={this.handleLoadMore}
                                   />}/>
                            <Route path='/gallery/edit/step/1' render={() => <EditPicturesStep1Form
                                nextInstallation={this.props.clients.installation_number_paginated.next}
                                nextTemporary={this.props.clients.temporary_id_paginated.next}
                                loadOptionsInstallation={(search: string) => this.props.loadOptions(search, 'installation_number')}
                                loadOptionsTemporary={(search: string) => this.props.loadOptions(search, 'temporary_id')}
                                getMore={this.getMoreOptions}
                                installation_number_options={this.state.installation_number_options}
                                temporary_id_options={this.state.temporary_id_options}
                                previousStep={() => this.props.history.goBack() }
                                nextStep={this.stepTwo}
                                initialValues={{
                                    picture_type: this.state.pictureType,
                                    temporary_id: {value: this.state.temporary_id, label: this.state.temporary_id_name},
                                    installation_number: {
                                        value: this.state.installation_number,
                                        label: this.state.installation_number_name
                                    }
                                }}
                            />}/>
                            <Route exact path='/gallery/edit/step/2' component={() => <EditPicturesStep2Form
                                previousStep={() => ((this.state.pictureType === 'no_number') ? this.props.history.goBack() : this.props.history.go(-2))}
                                nextStep={this.stepThree}
                                initialValues={{type: this.state.singlePicture.type}}
                            />}/>


                            <Route exact path='/gallery/edit/step/3' render={() => <EditPicturesStep3Form
                                handleEditClick={this.handleEditedPicture}
                                handleSave={this.saveEditedPicture}
                                initialValues={{
                                    tags: this.state.singlePicture.tags.map((tag: any) => tag.name),
                                    tagValue: ''
                                }}
                                showDefault={this.state.showDefault}
                                toggleShowDefault={this.toggleShowDefault}
                                defaultTags={this.props.defaultTags.results}
                                next={this.props.defaultTags.next}
                                loadMoreTags={this.getMoreTags}
                            />}/>

                            <Route exact path='/gallery/edit/bulk/tag' render={() => <AddTags
                                handleEditClick={() => this.props.history.goBack()}
                                handleSave={this.saveBulkTags}
                                initialValues={{tags: [], tagValue: ''}}
                                showDefault={this.state.showDefault}
                                toggleShowDefault={this.toggleShowDefault}
                                defaultTags={this.props.defaultTags.results}
                                next={this.props.defaultTags.next}
                                loadMoreTags={this.getMoreTags}
                            />}/>

                        </div>
                    </div>
                </>)
        );
    }

}

const mapStateToProps = (state: ApplicationState): PropsFromState => {
    return {
        errorMessage: state.errorMessage,
        session: state.session,
        searchValue: state.shared.searchValue,
        installationNumberClients: state.clients.installation_number_with_pictures,
        temporaryIdClients: state.clients.temporary_id_with_pictures,
        pictures: state.pictures,
        nextValue: state.pictures.next,
        loading: state.shared.loading,
        clients: state.clients,
        device: state.shared.device,
        defaultTags: state.tags,
    }
};

const mapDispatchToProps: PropsFromDispatch = {
    verifyToken,
    getUser,
    getClientsWithPictures,
    getPicturesBy,
    deleteSelected,
    clearPictures,
    LoadMore,
    setSearchValue,
    editPicture,
    getClientsBySearchParam,
    updateCoordinates,
    clearClients,
    clearSearchValue,
    getFolderNameByID,
    getTemporaryIDs,
    getInstallationNumbers,
    getMoreOptions,
    loadOptions,
    getMoreTags,
    getDefaultTags,
    saveBulkTags,
};

export default compose<GalleryProps, {}>(
    withRouter,
    connect<PropsFromState, PropsFromDispatch, {}, ApplicationState>(
        mapStateToProps,
        mapDispatchToProps,
    )
)(Gallery);
