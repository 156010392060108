import {ClientsActionTypes} from "./types";
import {AnyAction, Dispatch} from "redux";
import {ThunkAction, ThunkDispatch} from "redux-thunk";
import {AddingNewUserFailed, ClearErrorMessages, NoAccess} from "../errorMessage/actions";
import {randomTokenName} from "../../App";
import {API_URL} from "../../configureStore";
import {loading} from "../shared/actions";
import {loadMore} from "../pictures/actions";
import request from "../../helpers/request";

export const getInstallationClients = (result: any) => (dispatch: Dispatch<any>) => {
	return dispatch({type: ClientsActionTypes.GET_INSTALLATION_CLIENTS, data: result});
};

export const getTemporaryClients = (clients: []) => (dispatch: Dispatch<any>) => {
	return dispatch({type: ClientsActionTypes.GET_TEMPORARY_CLIENTS, data: clients});
};

export const getInstallationWithPictures = (clients: any[]) => (dispatch: Dispatch<any>) => {
	return dispatch({type: ClientsActionTypes.GET_INSTALLATION_WITH_PICTURES, data: clients})
};

export const getTemporaryWithPictures = (clients: any[]) => (dispatch: Dispatch<any>) => {
	return dispatch({type: ClientsActionTypes.GET_TEMPORARY_WITH_PICTURES, data: clients})
};

export const getTemporaryIDsPaginated = (result: any) => (dispatch: Dispatch<any>) => {
	return dispatch({type: ClientsActionTypes.GET_TEMPORARY_CLIENTS_PAGINATED, data: result})
};

export const getInstallationClientsPaginated = (result: any) => (dispatch: Dispatch<any>) => {
	return dispatch({type: ClientsActionTypes.GET_INSTALLATION_CLIENTS_PAGINATED, data: result})
};

export const clearInstallationNumbers = () => (dispatch: Dispatch<any>) => {
	return dispatch({type: ClientsActionTypes.CLEAR_INSTALLATION_NUMBERS})
};


export const clearClients = () => (dispatch: Dispatch<any>) => {
	return dispatch({type: ClientsActionTypes.CLEAR_CLIENTS})
};

export const getMoreTemporary = (result: any) => (dispatch: Dispatch<any>) => {
	return dispatch({type: ClientsActionTypes.GET_MORE_TEMPORARY, data: result})
};

export const getTemporaryIDs = (page?: number, searchValue?: string)
	: ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
		return new Promise<void>(async (res) => {
			const token = localStorage.getItem(randomTokenName) || sessionStorage.getItem(randomTokenName);
			if (page) {
				// dispatch(loading(true));
				await request(API_URL + `clients/?type=temporary_id&search=${searchValue}&page=${page}`, {
					method: 'GET',
					headers: {
						'Authorization': 'JWT ' + token,
					}
				})
					.then(async res => {
						if (res.ok) {
							await res.json()
								.then(val => dispatch(getTemporaryIDsPaginated(val)))
						} else {
							dispatch(NoAccess({message: 'wrong token'}));
						}
					});
				// dispatch(loading(false));
			} else {
				await request(API_URL + 'clients/?type=temporary_id', {
					method: 'GET',
					headers: {
						'Authorization': 'JWT ' + token,
					}
				})
					.then(async res => {
						if (res.ok) {
							await res.json()
								.then(val => dispatch(getTemporaryClients(val.results)))
						} else {
							dispatch(NoAccess({message: 'wrong token'}));
						}
					});
			}
			res();
		})
	}
};

export const getInstallationNumbers = (page?: number, searchValue?: string):
	ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
		return new Promise<void>(async (res) => {
			const token = localStorage.getItem(randomTokenName) || sessionStorage.getItem(randomTokenName);
			if (page) {
				await request(API_URL + `clients/?type=installation_number&page=${page}`, {
					method: 'GET',
					headers: {
						'Authorization': 'JWT ' + token,
					}
				})
					.then(async res => {
						if (res.ok) {
							await res.json()
								.then(val => dispatch(getInstallationClientsPaginated(val)))
						} else {
							dispatch(NoAccess({message: 'Wrong token'}))
						}
					});
			} else {
				await request(API_URL + 'clients/?type=installation_number', {
					method: 'GET',
					headers: {
						'Authorization': 'JWT ' + token,
					}
				})
					.then(async res => {
						if (res.ok) {
							await res.json()
								.then(val => dispatch(getInstallationClients(val)))
						} else {
							dispatch(NoAccess({message: 'Wrong token'}))
						}
					});
			}
			res();
		})
	}
};

export const getClientsWithPictures = ()
	: ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
		return new Promise<void>(async (res) => {
			dispatch(loading(true));
			const token = localStorage.getItem(randomTokenName) || sessionStorage.getItem(randomTokenName);
			const results = await request(API_URL + 'clients/clients-with-picture-by-type/', {
				method: 'GET',
				headers: {
					'Authorization': 'JWT ' + token
				}
			})
				.then(res => res.json())
				.then(val => {
					return val.results;
				});

			if (results.length !== 0) {
				if (results[0]) {
					if (results[0].types === 'installation_number') {
						dispatch(getInstallationWithPictures(results[0].clients))
					} else {
						dispatch(getTemporaryWithPictures(results[0].clients));
						dispatch(getInstallationWithPictures([]))
					}
				}
				if (results[1]) {
					dispatch(getTemporaryWithPictures(results[1].clients))
				} else {
					if(results[0].types === 'installation_number') {
						dispatch(getTemporaryWithPictures([]))
					}
				}
			} else {
				dispatch(getInstallationWithPictures([]));
				dispatch(getTemporaryWithPictures([]))
			}
			dispatch(loading(false));
			res();
		})
	}
};

export const getClientsBySearchParam = (search: string, type: string)
	: ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
		return new Promise<void>(async (res) => {
			const token = localStorage.getItem(randomTokenName) || sessionStorage.getItem(randomTokenName);
			const results = await request(API_URL + `clients/clients-with-picture-by-type/?search=${search}`, {
				method: 'GET',
				headers: {
					'Authorization': 'JWT ' + token,
				}
			})
				.then(res => res.json())
				.then(val => val.results);
			if (results) {
				if (results[0]) {
					if (results[0].types === 'installation_number') {
						if (type === 'installation_number') {
							dispatch(getInstallationWithPictures(results[0].clients))
						}
						if (type === 'temporary_id' && !results[1]) {
							dispatch(getTemporaryWithPictures([]))
						}
					} else {
						if (type === 'temporary_id') {
							dispatch(getTemporaryWithPictures(results[0].clients))
						}
						if (type === 'installation_number') {
							dispatch(getInstallationWithPictures([]))
						}
					}
					if (results[1]) {
						if (type === 'temporary_id') {
							dispatch(getTemporaryWithPictures(results[1].clients))
						}
					}
				} else {
					if (type === 'temporary_id') {
						dispatch(getTemporaryWithPictures([]))
					} else {
						dispatch(getInstallationWithPictures([]))
					}
				}
			}
			res();
		})
	}
};

export const getFolderNameByID = (id: number): ThunkAction<Promise<string>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<string> => {
		return new Promise<string>(async (res) => {
			dispatch(ClearErrorMessages());
			const token = localStorage.getItem(randomTokenName) || sessionStorage.getItem(randomTokenName);
			await request(API_URL + `clients/${id}/`, {
				method: 'GET',
				headers: {
					'Authorization': 'JWT ' + token,
				}
			})
				.then(async response => {
					if (response.ok) {
						await response.json().then(val => res(val.name))
					} else {
						dispatch(AddingNewUserFailed({message: 'No such Client'}))
						res('NOPE')
					}
				})
		})
	}
};

export const addClient = (name: string, index: string)
	: ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
		return new Promise<void>(async (res) => {
			dispatch(ClearErrorMessages());
			const token = localStorage.getItem(randomTokenName) || sessionStorage.getItem(randomTokenName);
			await request(API_URL + 'clients/', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'JWT ' + token,
				},
				body: JSON.stringify({
					name,
					type: 'temporary_id',
					index
				})
			})
				.then(async response => {
					if (!response.ok) {
						await response.json()
							.then(error => dispatch(AddingNewUserFailed(error)))
					}
				});
			res();
		})
	}
};

export const editClient = (name: string, index: string, id: number)
	: ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
		return new Promise<void>(async (res) => {
			dispatch(ClearErrorMessages());
			const token = localStorage.getItem(randomTokenName) || sessionStorage.getItem(randomTokenName);
			await request(API_URL + `clients/${id}/`, {
				method: 'PATCH',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'JWT ' + token,
				},
				body: JSON.stringify({
					name,
					index
				})
			})
				.then(async response => {
					if (!response.ok) {
						await response.json()
							.then(error => dispatch(AddingNewUserFailed(error)))
					} else if (response.ok) {
					}
				});
			res();
		})
	}
};

export const changeTemporaryToInstallation = (tem_id: number, ins_id: string)
	: ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
		return new Promise<void>(async (res) => {
			dispatch(ClearErrorMessages());
			const token = localStorage.getItem(randomTokenName) || sessionStorage.getItem(randomTokenName);
			await request(API_URL + 'clients/temporary-client-to-installation/', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'JWT ' + token,
				},
				body: JSON.stringify({
					temporary_id: tem_id,
					installation_number: +ins_id
				})
			})
				.then(async response => {
					if (!response.ok) {
						await response.json()
							.then(error => dispatch(AddingNewUserFailed(error)))
					} else if (response.ok) {
					}
				});
			res();
		})
	}
};

export const deleteClient = (token: string)
	: ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
		return new Promise<void>(async (res) => {
			dispatch(ClearErrorMessages());
			const token1 = localStorage.getItem(randomTokenName) || sessionStorage.getItem(randomTokenName);
			await request(API_URL + `clients/delete-client-by-slug/${token}/`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'JWT ' + token1
				},
			})
				.then(async response => {
					if (!response.ok) {
						await response.json()
							.then(error => dispatch(AddingNewUserFailed(error)))
					} else if (response.ok) {
					}
				});
			res();
		})
	}
};

export const sendTokenToEmail = (id: number)
	: ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
		return new Promise<void>(async (res) => {
			dispatch(ClearErrorMessages());
			const token = localStorage.getItem(randomTokenName) || sessionStorage.getItem(randomTokenName);
			await request(API_URL + `clients/${id}/`, {
				method: 'DELETE',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'JWT ' + token
				},
				body: JSON.stringify({
					id
				})
			})
				.then(async response => {
					if (!response.ok) {
						await response.json()
							.then(error => dispatch(AddingNewUserFailed(error)))
					} else if (response.ok) {
					}
				});
			res();
		})
	}
};

export const temporaryIdGotPictures = (name: string): ThunkAction<Promise<boolean>, {}, {}, AnyAction> => {
	return async (): Promise<boolean> => {
		return new Promise<boolean>(async (res) => {
			const token = localStorage.getItem(randomTokenName) || sessionStorage.getItem(randomTokenName);
			await request(API_URL + `clients/clients-with-picture-by-type/?type=temporary_id&name=${name}`, {
				method: 'GET',
				headers: {
					'Authorization': 'JWT ' + token
				}
			})
				.then(res => res.json())
				.then(val => res(!!val.count));
		})
	}
};

export const getMoreOptions = (next: string, type: string): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
		return new Promise<void>(async (res) => {
			const token = localStorage.getItem(randomTokenName) || sessionStorage.getItem(randomTokenName);
			await request(next, {
				method: 'GET',
				headers: {
					'Authorization': 'JWT ' + token,
				}
			})
				.then(res => res.json())
				.then(val => {
					if (type === 'installation_number') {
						dispatch(getInstallationClientsPaginated(val));
					} else {
						dispatch(getMoreTemporary(val))
					}
				});
			res();
		})
	}
};

export const loadOptions = (search: string, type: string): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
	return async (): Promise<any> => {
		return new Promise<any>(async (res) => {
			const token = localStorage.getItem(randomTokenName) || sessionStorage.getItem(randomTokenName);
			if (type === 'installation_number') {
				const options = await request(API_URL + `clients/?type=installation_number&search=${search}`, {
					method: 'GET',
					headers: {
						'Authorization': 'JWT ' + token,
					}
				})
					.then(res => res.json())
					.then(val => val.results)
				const answer = options.map((option: any) => ({
					value: option.id,
					label: option.name
				}));
				res(answer);
			} else {
				const options = await request(API_URL + `clients/?type=temporary_id&search=${search}`, {
					method: 'GET',
					headers: {
						'Authorization': 'JWT ' + token
					}
				})
					.then(res => res.json())
					.then(val => val.results);
				const answer = options.map((option: any) => ({
					value: option.id,
					label: option.name
				}));
				res(answer);
			}
		})
	}
};
