import React, {Component} from 'react';
//@ts-ignore
import Loader from 'react-loader';

class AquaSecLoader extends Component<any, any> {
	private timer: any;
    constructor(props: any) {
        super(props);
        this.enableMessage = this.enableMessage.bind(this);

        this.state = {
            displayMessage: false,
        };

        this.timer = setTimeout(this.enableMessage, 250);
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    enableMessage() {
        this.setState({displayMessage: true});
    }

    render() {

        const {displayMessage} = this.state;

        if (!displayMessage) {
            return null;
        }
        return (
            <div className="spinner-wrapper ">
                <Loader
                    options={{
                        lines: 9,
                        length: 20,
                        width: 8,
                        radius: 14,
                        scale: 1.00,
                        corners: 1,
                        color: '#179DFF',
                        opacity: .4,
                        direction: 1,
                        speed: 1,
                        trail: 60,
                        fps: 20,
                        zIndex: 2e9,
                        top: '50%',
                        left: '50%',
                        shadow: false,
                        hwaccel: false,
                        position: 'absolute'
                    }

                    }
                />
            </div>
        );
    }
}

export default AquaSecLoader;