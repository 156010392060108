import React from 'react';
import {compose} from 'recompose';
import {RouteComponentProps, withRouter} from "react-router";
import {connect} from 'react-redux'
import Modal from 'react-responsive-modal';
import {User} from '../../../store/users/types';
import {ConnectedReduxProps, ApplicationState} from '../../../store';
import {
	fetchUsers,
	addNewUser,
	editProfile,
	verifyToken,
	Users,
	changeUserPassword
} from '../../../store/users/actions';
import {initialUserState} from '../../../store/users/reducer';
import {SessionState} from "../../../store/session/types";
import {toast} from "react-toastify";
import {getUser} from "../../../store/session/actions";
import {MyProfileForm} from "../components/My_Profile/MyProfileForm";
import {getPicturesBy} from "../../../store/pictures/actions";
import {ChangePasswordForm} from "../components/My_Profile/ChangePasswordForm";
import {
	PROFILE_FIRST_NAME_EDITED,
	PROFILE_LAST_NAME_EDITED,
	PROFILE_MULTIPLE_FIELD_EDITED, PROFILE_PASSWORD_CHANGE_FAILED, PROFILE_PASSWORD_CHANGED
} from "../../../helpers/toastMessages";


interface PropsFromState {
	users: Users,
	errorMessage: any,
	session: SessionState,
	searchValue: string,
}

interface PropsFromDispatch {
	fetchUsers: (searchParam: string, page: number) => void
	addNewUser: (email: string, role: string, first_name: string, last_name: string) => void
	editProfile: (id: number, first_name: string, last_name: string) => void
	verifyToken: (token: string) => void
	getUser: (id: number) => void
	getPicturesBy(queryParams: string): void
	changeUserPassword(old_password: string, password: string): void
}

interface MyProfileProps extends RouteComponentProps<any>, PropsFromState, PropsFromDispatch, ConnectedReduxProps {

}

interface MyProfileState {
	openLeftSidebar: boolean,
	openRightSidebar: boolean,
	singleUser: User,
	open: boolean,
	title: string,
	disabled: boolean,
	first_name: string,
	last_name: string,
	email: string,
	role: string
	searchTypes: number,
	openGlobalFilter: boolean

}

const passwordFormRef = React.createRef();

class MyProfile extends React.Component<MyProfileProps, MyProfileState> {

	state: MyProfileState = {
		singleUser: initialUserState,
		openLeftSidebar: false,
		openRightSidebar: false,
		open: false,
		title: 'My Profile',
		disabled: false,
		first_name: this.props.session.name,
		last_name: this.props.session.surname,
		email: this.props.session.email,
		role: this.props.session.role,
		searchTypes: 0,
		openGlobalFilter: false
	};

	componentWillUpdate(nextProps: Readonly<MyProfileProps>, nextState: Readonly<MyProfileState>, nextContext: any): void {
		if (nextProps.session.name !== this.props.session.name) {
			this.setState({
				first_name: nextProps.session.name,
				last_name: nextProps.session.surname,
				email: nextProps.session.email,
				role: nextProps.session.role,
			})
		}
	}

	onOpenModal = () => {
		this.setState({
			open: true,

		});
	};

	handleSubmit = async (email: string,role: string, name: string, surname: string) => {
		try{
			await this.props.editProfile(this.props.session.pk, name, surname);
			if(Object.keys(this.props.errorMessage).length === 0) {
				if(name !== this.state.first_name && surname !== this.state.last_name) {
					this.setState({
						first_name: name,
						last_name: surname,
					});
					toast.dismiss();
					toast(() =>
						<div className="toast-style success">
							<div className="left-side">
							<span className="icon-success icon">
								<span className="path1"/>
								<span className="path2"/>
							</span>
							</div>
							<div className="right-side">
								<p>{PROFILE_MULTIPLE_FIELD_EDITED}</p>
							</div>
						</div>
					)
				} else {
					if(name !== this.state.first_name) {
						this.setState({
							first_name: name,
						});
						toast.dismiss();
						toast(() =>
							<div className="toast-style success">
								<div className="left-side">
							<span className="icon-success icon">
								<span className="path1"/>
								<span className="path2"/>
							</span>
								</div>
								<div className="right-side">
									<p>{PROFILE_FIRST_NAME_EDITED}</p>
								</div>
							</div>
						)
					}
					if(surname !== this.state.last_name) {
						this.setState({
							last_name: surname,
						});
						toast.dismiss();
						toast(() =>
							<div className="toast-style success">
								<div className="left-side">
							<span className="icon-success icon">
								<span className="path1"/>
								<span className="path2"/>
							</span>
								</div>
								<div className="right-side">
									<p>{PROFILE_LAST_NAME_EDITED}</p>
								</div>
							</div>
						)
					}
				}
				await this.props.getUser(this.props.session.pk);
			}
		} catch (e) {
		}
	};

	handlePasswordChange = async (old_password: string, password: string) => {
		try {
			await this.props.changeUserPassword(old_password,password);
			if(Object.keys(this.props.errorMessage).length === 0) {
				toast.dismiss();
				toast(() =>
					<div className="toast-style success">
						<div className="left-side">
							<span className="icon-success icon">
								<span className="path1"/>
								<span className="path2"/>
							</span>
						</div>
						<div className="right-side">
							<p>{PROFILE_PASSWORD_CHANGED}</p>
						</div>
					</div>
				);
				this.onCloseModal();
			} else {
				toast.dismiss();
				toast(() =>
					<div className="toast-style error">
						<div className="left-side">
							<span className="icon-error icon">
								<span className="path1"/>
								<span className="path2"/>
							</span>
						</div>
						<div className="right-side">
							<p>{PROFILE_PASSWORD_CHANGE_FAILED}</p>
						</div>
					</div>
				)
			}
		} catch (e) {
		}
	};

	onCloseModal = () => {
		this.setState({
			open: false,
		});
	};

	bodyOverlay = () => {
		document.body.classList.toggle('overlay');
		// const element = document.getElementById('main');
		// if (element) {
		// 	element.classList.toggle('overlay');
		// }
	};

	render() {
		return (
			<>
				{/*users table*/}
				<MyProfileForm
					onOpenModal={this.onOpenModal}
					title={this.state.title}
					onSubmit={this.handleSubmit}
					initialValues={{
						first_name: this.state.first_name,
						last_name: this.state.last_name,
						email: this.state.email,
						role: this.state.role
					}}
				/>
				{/*users table*/}
				{/*
				//@ts-ignore*/}
				<div className="modal modal-md" ref={passwordFormRef}>
					<Modal
	  					open={this.state.open}
						onClose={this.onCloseModal}
						center
						container={passwordFormRef.current}
					>
						<ChangePasswordForm
							onSubmit={this.handlePasswordChange}
							onCloseModal={this.onCloseModal}
						/>
					</Modal>
				</div>
			</>
		);
	}

}

const mapStateToProps = (state: ApplicationState): PropsFromState => {
	return {
		users: state.users,
		errorMessage: state.errorMessage,
		session: state.session,
		searchValue: state.shared.searchValue,
	}
};

const mapDispatchToProps: PropsFromDispatch = {
	fetchUsers,
	addNewUser,
	editProfile,
	verifyToken,
	getUser,
	getPicturesBy,
	changeUserPassword
};

export default compose<MyProfileProps, {}>(
	withRouter,
	connect<PropsFromState, PropsFromDispatch, {}, ApplicationState>(
		mapStateToProps,
		mapDispatchToProps,
	)
)(MyProfile);
