import React, {Component} from 'react';
import {withFormik, FormikProps} from 'formik';
import DatePicker from "react-datepicker";

export const GlobalFormInitialValues: FormValues = {
	search: '',
	normal: false,
	threeSixty: false,
	no_number_boolean: true,
	temporary_id_boolean: true,
	installation_number_boolean: true,
	for_future_information: false,
	intervention: false,
	quotation: false,
	startDate: null,
	endDate: null
};

export interface FormValues {
	search: string,
	normal: boolean,
	threeSixty: boolean,
	no_number_boolean: boolean,
	temporary_id_boolean: boolean,
	installation_number_boolean: boolean,
	for_future_information: boolean,
	intervention: boolean,
	quotation: boolean
	startDate: any,
	endDate: any
}

interface OtherProps {
	openGlobalFilter(): void,

	bodyOverlay(): void,

	onSubmit(
		search: string,
		normal: boolean,
		threeSixty: boolean,
		no_number_boolean: boolean,
		temporary_id_boolean: boolean,
		installation_number_boolean: boolean,
		for_future_information: boolean,
		quotation: boolean,
		intervention: boolean,
		startDate: Date,
		endDate: Date): void
	isOpen: boolean
	showReset?: boolean
	clearValues(): void
}

interface InnerFormProps extends FormikProps<FormValues>, OtherProps {

}

class InnerForm extends Component<InnerFormProps> {

	componentWillUpdate(nextProps: Readonly<InnerFormProps>, nextState: Readonly<{}>, nextContext: any): void {
		if(nextProps.isOpen !== this.props.isOpen) {
			if(!this.props.isOpen) {
				this.props.resetForm();
			}
		}
	}

	render() {

		const {
			values,
			handleChange,
			handleBlur,
			handleSubmit,
			setFieldValue,
			openGlobalFilter,
			bodyOverlay,
			resetForm,
			showReset,
			clearValues
		} = this.props;

		return (
			<form onSubmit={handleSubmit}>
				<div className="flex align-center  mb-35 justify-between">
					<h2 className="fw-500">Search & Filter</h2>
					{showReset &&
          <button type="button" onClick={() => {
						clearValues();
						handleSubmit();
					}}
                  className="link pointer no-style"
          >
              Reset
          </button>}
				</div>
				<div className="textfield with-icon mb-25">
					<input
						type="text"
						placeholder="Search"
						name='search'
						value={values.search}
						onChange={handleChange}
						onBlur={handleBlur}
					/>
					<span className="icon-search icon"/>
				</div>

				<div className="pb-35">
					<h3 className="fw-500 mb-20">Select Number or ID</h3>
					<div className="checkbox mb-25">
						<label htmlFor="Installation">
							<input
								type="checkbox"
								id="Installation"
								name="installation_number_boolean"
								onChange={handleChange}
								onBlur={handleBlur}
								checked={values.installation_number_boolean}
							/>
							<span className="checkmark vertical-middle"/>
							<span className="fw-700 ml-20 vertical-middle">Installation  Number</span>
						</label>
					</div>
					<div className="checkbox mb-25">
						<label htmlFor="Temporary">
							<input
								type="checkbox"
								id="Temporary"
								name="temporary_id_boolean"
								onChange={handleChange}
								onBlur={handleBlur}
								checked={values.temporary_id_boolean}
							/>
							<span className="checkmark vertical-middle"/>
							<span className="fw-700 ml-20 vertical-middle">Temporary ID</span>
						</label>
					</div>
					<div className="checkbox mb-25">
						<label htmlFor="NoNumber">
							<input
								type="checkbox"
								id="NoNumber"
								name="no_number_boolean"
								onChange={handleChange}
								onBlur={handleBlur}
								checked={values.no_number_boolean}
							/>
							<span className="checkmark vertical-middle"/>
							<span className="fw-700 ml-20 vertical-middle">No Number or ID</span>
						</label>
					</div>
				</div>


				<div className="pb-35">
					<h3 className="fw-500 mb-20">Select Image Type(s)</h3>
					<div className="checkbox mb-25">
						<label htmlFor="Normal">
							<input
								type="checkbox"
								id="Normal"
								name="normal"
								onChange={handleChange}
								onBlur={handleBlur}
								checked={values.normal}
							/>
							<span className="checkmark vertical-middle"/>
							<span className="fw-700 ml-20 vertical-middle">Normal</span>
						</label>
					</div>
					<div className="checkbox mb-25">
						<label htmlFor="threeSixty">
							<input
								type="checkbox"
								id="threeSixty"
								name="threeSixty"
								onChange={handleChange}
								onBlur={handleBlur}
								checked={values.threeSixty}
							/>
							<span className="checkmark vertical-middle"/>
							<span className="fw-700 ml-20 vertical-middle">360</span>
						</label>
					</div>
				</div>

				<div>
					<h3 className="fw-500 mb-20">Select Purpose(s)</h3>
					<div className="checkbox mb-25">
						<label htmlFor="Future">
							<input
								type="checkbox"
								id="Future"
								name="for_future_information"
								value="for_future_information"
								checked={values.for_future_information}
								onChange={handleChange}
								onBlur={handleBlur}
							/>
							<span className="checkmark vertical-middle"/>
							<span className="fw-700 ml-20 vertical-middle">For Future Information</span>
						</label>
					</div>
					<div className="checkbox mb-25">
						<label htmlFor="Intervention">
							<input
								type="checkbox"
								id="Intervention"
								name="intervention"
								value="intervention"
								checked={values.intervention}
								onChange={handleChange}
								onBlur={handleBlur}
							/>
							<span className="checkmark vertical-middle"/>
							<span className="fw-700 ml-20 vertical-middle">Intervention</span>
						</label>
					</div>
					<div className="checkbox mb-25">
						<label htmlFor="Quotation">
							<input
								type="checkbox"
								id="Quotation"
								name="quotation"
								value="quotation"
								checked={values.quotation}
								onChange={handleChange}
								onBlur={handleBlur}
							/>
							<span className="checkmark vertical-middle"/>
							<span className="fw-700 ml-20 vertical-middle">Quotation</span>
						</label>
					</div>
					<div className="cols-md-2">
						<div className="col-md-6">
							<div className="textfield with-right-icon">
								<DatePicker
									placeholderText="From"
									value={values.startDate}
									selected={values.startDate}
									selectsStart
									startDate={values.startDate}
									endDate={values.endDate}
									onChange={e => setFieldValue('startDate', e)}
								/>
								<span className="icon-date-range icon">
                <span className="path1"/>
                <span className="path2"/>
              </span>
							</div>
						</div>
						<div className="col-md-6">
							<div className="textfield with-right-icon align-right">
								<DatePicker
									placeholderText="To"
									value={values.endDate}
									selected={values.endDate}
									selectsEnd
									startDate={values.startDate}
									endDate={values.endDate}
									onChange={e => setFieldValue('endDate', e)}
								/>
								<span className="icon-date-range icon">
                <span className="path1"/>
                <span className="path2"/>
              </span>
							</div>
						</div>
					</div>
				</div>
				<div className="sidebar-meta">
					<button type='button' onClick={()=> {
						resetForm();
						openGlobalFilter();
						bodyOverlay();
					}} className="btn outline text mr-15">Cancel</button>
					<button type='submit' onClick={() => {openGlobalFilter(); bodyOverlay()}} className="btn filled primary ripple">
						<span>Apply</span>
					</button>
				</div>
			</form>
		);
	}
}

interface MyFormProps {
	initialValues: FormValues,

	openGlobalFilter(): void,

	bodyOverlay(): void,

	onSubmit(
		search: string,
		normal: boolean,
		threeSixty: boolean,
		no_number_boolean: boolean,
		temporary_id_boolean: boolean,
		installation_number_boolean: boolean,
		for_future_information: boolean,
		quotation: boolean,
		intervention: boolean,
		startDate: Date,
		endDate: Date): void
	isOpen: boolean
	showReset?: boolean
	clearValues(): void
}

export const GlobalFilter = withFormik<MyFormProps, FormValues>({
	enableReinitialize: true,

	mapPropsToValues: props => {
		const {initialValues} = props;
		return {
			search: initialValues.search,
			normal: initialValues.normal,
			threeSixty: initialValues.threeSixty,
			no_number_boolean: initialValues.no_number_boolean,
			temporary_id_boolean: initialValues.temporary_id_boolean,
			installation_number_boolean: initialValues.installation_number_boolean,
			for_future_information: initialValues.for_future_information,
			quotation: initialValues.quotation,
			intervention: initialValues.intervention,
			startDate: initialValues.startDate,
			endDate: initialValues.endDate
		}
	},

	handleSubmit(
		{search,
			normal,
			threeSixty,
			temporary_id_boolean,
			no_number_boolean,
			installation_number_boolean,
			for_future_information,
			quotation,
			intervention,
			startDate,
			endDate}: FormValues,
		{props, resetForm}
	) {
		props.onSubmit(
			search,
			normal,
			threeSixty,
			no_number_boolean,
			temporary_id_boolean,
			installation_number_boolean,
			for_future_information,
			quotation,
			intervention,
			startDate,
			endDate
			);
		resetForm();
	}
	,
})(InnerForm);
