//Auth
export const USER_DOESNT_EXIST = "User with this email address doesn't exist";
export const PASSWORD_UPDATE = "Password has been updated";
export const USER_ACTIVATED = "Account has been activated";
export const TOKEN_USED_OR_EXPIRED = "This link is not valid any more";
//Recently Deleted
export const SINGLE_PICTURE_DELETED = "Image was deleted";
export const MULTIPLE_PICTURES_DELETED = "Images were deleted";
export const SINGLE_PICTURE_RESTORED = "Image was restored in respective folder";
export const MULTIPLE_PICTURES_RESTORED = "Images were restored in respective folders";
//Admin Users
export const ADMIN_USER_CREATED = "User was successfully created";
export const ADMIN_USER_EDITED = "User was successfully edited";
export const ADMIN_USER_DELETED = "User was deleted";
export const ADMIN_USER_ERROR_ON_CREATION = "User with this email already exists";
export const EMAIL_ACTIVATION_MESSAGE_SUCCESS = "Email is successfully sent";
export const EMAIL_ACTIVATION_MESSAGE_FAIL = "Impossible to resend the email";
//Clients
export const TEMPORARY_ID_CREATED = "Temporary ID was successfully added";
export const TEMPORARY_ID_FAILED = "Temporary ID couldn't be added";
export const TEMPORARY_ID_EDITED = "Temporary ID was successfully edited";
export const TEMPORARY_ID_DELETED = "Temporary ID was deleted";
export const WRONG_DELETION_TOKEN = "Confirmation code is wrong";
export const TOKEN_EXPIRED_MESSAGE = "Confirmation code has expired";
export const TEMPORARY_ID_EXISTS = "Temporary ID with this name already exists";
//My-Profile
export const PROFILE_MULTIPLE_FIELD_EDITED = "Changes are saved";
export const PROFILE_FIRST_NAME_EDITED = "First Name has been changed";
export const PROFILE_LAST_NAME_EDITED = "Last Name has been changed";
export const PROFILE_PASSWORD_CHANGED = "Your password was successfully changed";
export const PROFILE_PASSWORD_CHANGE_FAILED = "Current password is invalid";
//Tags
export const TAG_CREATED = "Tag was successfully added";
export const TAG_FAILED = "Tag couldn't be added";
export const TAG_EDITED = "Tag was successfully edited";
export const TAG_DELETED = "Tag was deleted";
export const TAG_EXISTS = "Tag with this name already exists";
//Others
export const IMAGE_LIMITATION = "Please, add no more than 20 images";
export const IMAGE_VALIDATION = "File format is wrong";
export const USER_ACTIVATED_BY_ADMIN = "User have been reactivated";
export const USER_DEACTIVATED_BY_ADMIN = "User have been deactivated";

