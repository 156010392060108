import React from 'react';
import ReactDOM from 'react-dom';
import "react-datepicker/dist/react-datepicker.css";
import App from './App';
import * as serviceWorker from './serviceWorker';
import configureStore from './configureStore';
import './assets/scss/main.scss';
// import worker from "./worker";
// import wokerFactory from './worker.factory';

const store = configureStore();

ReactDOM.render(<App store={store}/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();

// const myWorker = wokerFactory(worker);
// myWorker.onmessage = (e) => {
//   console.log(e.data);
// };

// myWorker.postMessage('message');