import {UsersActionTypes, User} from './types'
import {ThunkAction, ThunkDispatch} from 'redux-thunk';
import {AnyAction, Dispatch} from 'redux'
import {
	ClearErrorMessages,
	AddingNewUserFailed,
	ResetingPasswordFailed,
	NoAccess,
	cantDeleteAdmin, emailAlreadyExist
} from "../errorMessage/actions";
import {API_URL} from "../../configureStore";
import {randomTokenName} from "../../App";
import {loading} from "../shared/actions";
import request from "../../helpers/request";
import {ADMIN_USER_ERROR_ON_CREATION} from "../../helpers/toastMessages";

export type Users = {
	count: number,
	next: string,
	previous: string,
	results: User[],
}

export interface GetUsers {
	type: UsersActionTypes.FETCH_USERS,
	data: Users
}

export const getUsers = (users: Users): GetUsers => {
	return {type: UsersActionTypes.FETCH_USERS, data: users}
};

export const changeEditedUser = (id: number, role: string, first_name: string, last_name: string) => (dispatch: Dispatch<any>) => {
	return dispatch({type: UsersActionTypes.UPDATE_EDITED_USER, data: {id, role, first_name, last_name}})
};

export const fetchUsers = (searchParam: string, page: number): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
		return new Promise<void>(async (res) => {
			dispatch(ClearErrorMessages());
			dispatch(loading(true));
			const token = localStorage.getItem(randomTokenName) || sessionStorage.getItem(randomTokenName);
			await request(API_URL + `users/?search=${searchParam}&page=${page}`, {
				method: 'GET',
				headers: {
					'Authorization': 'JWT ' + token
				}
			})
				.then(async (res) => {
					if (res.status === 401) {
						await res.json()
							.then(error => dispatch(NoAccess(error)))
					} else if (res.ok) {
						await res.json()
							.then(users => dispatch(getUsers(users)))
					}
				});
			dispatch(loading(false));
			res();
		})
	}
};

export const addNewUser = (email: string, role: string, first_name: string, last_name: string)
	: ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
		return new Promise<void>(async (res) => {
			dispatch(ClearErrorMessages());
			const token = localStorage.getItem(randomTokenName) || sessionStorage.getItem(randomTokenName);
			await request(API_URL + 'users/', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'JWT ' + token
				},
				body: JSON.stringify({
					email,
					role,
					first_name,
					last_name,
				})
			})
				.then(async response => {
					if (response.status === 400) {
						await response.json()
							.then(error => dispatch(AddingNewUserFailed(error)))
					} else if (response.ok) {
					}
				});
			res();
		})
	}
};

export const sendPasswordResetToken = (email: string)
	: ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
		return new Promise<void>(async (res) => {
			dispatch(ClearErrorMessages());
			await request(API_URL + 'users/forgot-password/', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					email,
				})
			})
				.then(async response => {
					if (response.status === 400) {
						await response.json()
							.then(error => dispatch(ResetingPasswordFailed(error)))
					}
				});
			res();
		});
	}
};

export const editUserById = (id: number, email: string, role: string, first_name: string, last_name: string)
	: ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
		return new Promise<void>(async (res) => {
			dispatch(ClearErrorMessages());
			const token = localStorage.getItem(randomTokenName) || sessionStorage.getItem(randomTokenName);
			await request( API_URL + `users/${id}/`, {
				method: 'PATCH',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'JWT ' + token,
				},
				body: JSON.stringify({
					email,
					role,
				})
			})
				.then(async response => {
					if (response.ok) {
						dispatch(changeEditedUser(id, role, first_name, last_name))
					} else if (response.status === 400){
						dispatch(emailAlreadyExist({data: ADMIN_USER_ERROR_ON_CREATION}));
					}
				});
			res();
		})
	}
};

export const editProfile = (id: number, first_name: string, last_name: string)
	: ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
		return new Promise<void>(async (res) => {
			dispatch(ClearErrorMessages());
			const token = localStorage.getItem(randomTokenName) || sessionStorage.getItem(randomTokenName);
			await request( API_URL + `users/${id}/`, {
				method: 'PATCH',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'JWT ' + token,
				},
				body: JSON.stringify({
					first_name,
					last_name
				})
			})
				.then(async response => {
					if (response.ok) {
						dispatch(changeEditedUser(id, '', first_name, last_name))
					} else {

					}
				});
			res();
		})
	}
};

export const verifyToken = (token: string) => {
	return new Promise<boolean>(async (res) => {
		request(API_URL + 'users/api-token-verify/', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				token,
			})
		})
			.then(response => {
				if (!response.ok) {
					res(false);
				} else {
					res(true);
				}
			});
	})
};


export const changeUserPassword = (old_password: string, password: string)
	: ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
		return new Promise<void>(async (res) => {
			dispatch(ClearErrorMessages());
			const token = localStorage.getItem(randomTokenName) || sessionStorage.getItem(randomTokenName);
			await request(API_URL + 'users/change-password/', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'JWT ' + token,
				},
				body: JSON.stringify({
					old_password,
					password
				})
			})
				.then(async response => {
					if (!response.ok) {
						await response.json()
							.then((error) => dispatch(NoAccess(error)))
					}
				});
			res();
		})
	}
};

export const resendEmail = (email: string) : ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
		return new Promise<void>(async (res) => {
			dispatch(ClearErrorMessages());
			const token = localStorage.getItem(randomTokenName) || sessionStorage.getItem(randomTokenName);
			await request(API_URL + 'users/resend-activation-token/', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'JWT ' + token,
				},
				body: JSON.stringify({
					email
				})
			})
				.then(async response => {
					if(!response.ok) {
						await response.json()
							.then((error) => dispatch(NoAccess(error)))
					}
				});
			res();
		})
	}
};


export const activateUser = (id: number) : ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
		return new Promise<void>(async (res) => {
			dispatch(ClearErrorMessages());
			const token = localStorage.getItem(randomTokenName) || sessionStorage.getItem(randomTokenName);
			await request(API_URL + `users/reactivate-user/`, { method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'JWT ' + token,
				},
				body: JSON.stringify({
					user_id: id,
				})
			 });
			res();
		})
	}
};

export const deactivateUser = (id: number) : ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
		return new Promise<void>(async (res) => {
			dispatch(ClearErrorMessages());
			const token = localStorage.getItem(randomTokenName) || sessionStorage.getItem(randomTokenName);
			await request(API_URL + `users/deactivate-user/`, { method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'JWT ' + token,
				},
				body: JSON.stringify({
					user_id: id,
				})
			});
			res();
		})
	}
};


