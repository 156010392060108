import { Reducer } from 'redux';
import { ErrorMessageActionTypes } from './types';
import {ADMIN_USER_ERROR_ON_CREATION} from "../../helpers/toastMessages";

export const initialState: object = {}

const errorMessageReducer: Reducer<object> = (state: object = initialState, action) => {
  switch (action.type) {
    case ErrorMessageActionTypes.SIGN_IN_FAILURE:
    case ErrorMessageActionTypes.ADDING_NEW_USER_FAILED:
    case ErrorMessageActionTypes.RESETING_PASSWORD_FAILED:
    case ErrorMessageActionTypes.NO_ACCESS:
    case ErrorMessageActionTypes.SIGN_UP_FAILURE:
    case ErrorMessageActionTypes.CANT_DELETE_ADMIN:
    case ErrorMessageActionTypes.EMAIL_ALREADY_EXIST:
      return action.data;
    case ErrorMessageActionTypes.CLEAR_ERROR_MESSAGES:
      return initialState;
    default:
      return state;
  }
}

export default errorMessageReducer;