import React from 'react';
import {withFormik, FormikProps} from 'formik';
// import { connect } from 'react-redux';

import {UserFormSchema} from '../../../../helpers';
import {User} from "../../../../store/users/types";

export const FormInitialValues: FormValues = {
    first_name: '',
    last_name: '',
    email: '',
    role: '',
};

export interface FormValues {
    first_name: string,
    last_name: string,
    email: string,
    role: string,
}

interface OtherProps {
  user: User,

    title: string,

    onRightSidebarOpen(): void,

  onOpenModal(user: User): void

    bodyOverlay(): void,

    disabled: boolean,

    onSubmit(email: string, role: string, first_name: string, last_name: string): void,

    isOpen: boolean
}

interface InnerFormProps extends FormikProps<FormValues>, OtherProps {

}

interface InnerFormState {

}

class InnerForm extends React.Component<InnerFormProps, InnerFormState> {
    componentWillUpdate(nextProps: Readonly<InnerFormProps>, nextState: Readonly<{}>, nextContext: any): void {
        if (nextProps.isOpen !== this.props.isOpen) {
            if (!this.props.isOpen) {
                this.props.resetForm();
            }
        }
    }

    render() {
        const {
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            title,
            onRightSidebarOpen,
            bodyOverlay,
            disabled,
            resetForm,
            user,
          onOpenModal,
        } = this.props;


        return (
            <form onSubmit={handleSubmit}>
                <h2 className="fw-500 mb-35">{title}</h2>
                <div
                    className={"textfield with-icon" + ((errors.first_name && touched.first_name) ? ' error-field' : '')}>
                    <input
                        type="text"
                        placeholder="First Name"
                        name='first_name'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.first_name}
                        disabled={disabled}
                    />
                    <span className="icon-user icon">
            <span className="path1"/>
            <span className="path2"/>
            <span className="path3"/>
          </span>
                    {errors.first_name && touched.first_name ? (
                        <p className="error-text">{errors.first_name}</p>
                    ) : null}
                </div>
                <div
                    className={"textfield with-icon" + ((errors.last_name && touched.last_name) ? ' error-field' : '')}>
                    <input
                        type="text"
                        placeholder="Last Name"
                        name='last_name'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.last_name}
                        disabled={disabled}
                    />
                    <span className="icon-user icon">
            <span className="path1"/>
            <span className="path2"/>
            <span className="path3"/>
          </span>
                    {errors.last_name && touched.last_name ? (
                        <p className="error-text">{errors.last_name}</p>
                    ) : null}
                </div>

                <div className={"textfield with-icon" + ((errors.email && touched.email) ? ' error-field' : '')}>
                    <input
                        type="text"
                        placeholder="E-mail"
                        name='email'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                    />
                    <span className="icon-email icon">
              <span className="path1"/>
              <span className="path2"/>
          </span>
                    {errors.email && touched.email ? (
                        <p className="error-text">{errors.email}</p>
                    ) : null}
                </div>

                <h3 className="mt-50 mb-25 fw-500">Choose role</h3>
                <ul>
                    <li className="radio-btn radio-right mb-20">
                        <label htmlFor="admin">
                            <input
                                type="radio"
                                id="admin"
                                name="role"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value="admin"
                                checked={values.role === 'admin'}
                                disabled={title === 'Edit User' && !disabled}
                            />
                            <span className="icon-admin icon blue">
              <span className="path1"/>
              <span className="path2"/>
            </span>
                            <span>Admin</span>
                            <span className="checkmark"/>
                        </label>
                    </li>
                    <li className="radio-btn radio-right mb-20">
                        <label htmlFor="back_officer">
                            <input
                                type="radio"
                                id="back_officer"
                                name="role"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value='back_officer'
                                checked={values.role === 'back_officer'}
                                disabled={title === 'Edit User' && !disabled}
                            />
                            <span className="icon-user icon blue">
              <span className="path1"/>
              <span className="path2"/>
              <span className="path3"/>
            </span>
                            <span>Back Officer</span>
                            <span className="checkmark"/>
                        </label>
                    </li>
                    <li className="radio-btn radio-right mb-20">
                        <label htmlFor="worker">
                            <input
                                type="radio"
                                id="worker"
                                name="role"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value="worker"
                                checked={values.role === 'worker'}
                                disabled={title === 'Edit User' && !disabled}
                            />
                            <span className="icon-worker icon blue">
              <span className="path1"/>
              <span className="path2"/>
              <span className="path3"/>
            </span>
                            <span>Worker</span>
                            <span className="checkmark"/>
                        </label>
                    </li>
                </ul>
                {title === "Edit User" && (
                    <div className="pt-15 border-top">
                        <button
                            type={"button"}
                            onClick={() => onOpenModal(user)}
                            className="link with-icon color-warning no-style"
                        >
                            {user.is_deleted ? (<></>) : (<>
                                <span className="icon-deactivate icon red">
                                    <span className="path1"> </span>
                                    <span className="path2"> </span>
                                </span>
                                <span className="vertical-middle">Deactivate Account</span>
                            </>)}
                        </button>
                    </div>
                )}
                <div className="sidebar-meta">
                    <button type='button' onClick={() => {
                        onRightSidebarOpen();
                        bodyOverlay();
                        resetForm();
                    }} className="btn outline text mr-15">Cancel
                    </button>
                    <button type='submit' className="btn filled primary ripple"><span>Save</span></button>
                </div>
            </form>
        );
    }
}

interface MyFormProps {
    user: User,
    initialValues: FormValues,
    title: string,

    onRightSidebarOpen(): void,

  onOpenModal(user: User): void

    bodyOverlay(): void,

    onSubmit(email: string, role: string, first_name: string, last_name: string): void,

    disabled: boolean,
    isOpen: boolean,
}

export const UserForm = withFormik<MyFormProps, FormValues>({
    enableReinitialize: true,

    mapPropsToValues: props => {
        const {initialValues} = props;
        return {
            first_name: initialValues.first_name || '',
            last_name: initialValues.last_name || '',
            email: initialValues.email || '',
            role: initialValues.role || 'worker',
        }
    },

    validationSchema: UserFormSchema,

    handleSubmit(
        {first_name, last_name, email, role}: FormValues,
        {props, resetForm}
    ) {
        props.onSubmit(email, role, first_name, last_name);
        props.title === 'Add User' && resetForm();
    },

})(InnerForm);