export interface TagsState {
		count: number,
		next: any,
		previous: any,
		results: any[]
}

export enum TagsActionTypes {
	GET_DEFAULT_TAGS = '@@tags/GET_DEFAULT_TAGS',
	CLEAR_TAGS = '@@tags/CLEAR_TAGS',
	LOAD_MORE_TAGS = '@@tags/LOAD_MORE_TAGS',
}
