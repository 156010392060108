import React from 'react';
import { ForgotForm } from '../components/ForgotForm';
import {compose} from 'recompose'
import {RouteComponentProps, withRouter} from "react-router";
import {connect} from "react-redux";
import {ApplicationState, ConnectedReduxProps} from "../../../../store";
import {sendPasswordResetToken} from "../../../../store/users/actions";
import CheckEmail from '../components/CheckEmail'
import {toast} from "react-toastify";
import * as TOAST from "../../../../helpers/toastMessages";
import {SIGN_IN} from "../../../../helpers/routes";

interface PropsFromDispatch {
	sendPasswordResetToken(email: string): void
}

interface PropsFromState {
	errorMessage: object
}


interface ForgotProps extends RouteComponentProps<any>, PropsFromDispatch, PropsFromState, ConnectedReduxProps {

}

interface ForgotState {
	checkEmail: boolean
}

class Forgot extends React.Component<ForgotProps,ForgotState> {

	state = {
		checkEmail: false,
	};

	sendEmail = async (email: string) => {
		try {
			await this.props.sendPasswordResetToken(email);
			if(Object.keys(this.props.errorMessage).length === 0) {
				this.setState({
					checkEmail: true,
				})
			} else {
				toast.dismiss();
				toast(() =>
					<div className="toast-style error">
						<div className="left-side">
							<span className="icon-error icon">
								<span className="path1"/>
								<span className="path2"/>
							</span>
						</div>
						<div className="right-side">
							<p>{TOAST.USER_DOESNT_EXIST}</p>
						</div>
					</div>
				);
			}
		} catch (e) {
		}
	};

	render() {

		return (
			<div className="sign-page">
				{this.state.checkEmail
					? <CheckEmail />
					: <ForgotForm
						onSubmit={this.sendEmail}
						onCancel={() => this.props.history.push(SIGN_IN)}
					/>
				}
			</div>
		);
	}
}

const mapStateToProps = (state: ApplicationState): PropsFromState => {
	return {
		errorMessage: state.errorMessage,
	}
};

const mapDispatchToProps: PropsFromDispatch = {
	sendPasswordResetToken,
};

export default compose<ForgotProps, {}>(
	withRouter,
	connect<PropsFromState, PropsFromDispatch, {}, ApplicationState>(
		mapStateToProps,
		mapDispatchToProps,
	)
)(Forgot);
