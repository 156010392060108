import React, {Component} from 'react';

interface NavigationProps {
	pictureType: string | number

	handlePictureTypeChange(type: string): void
}

class Navigation extends Component<NavigationProps> {
	render() {
		return (
			<>
				<ul className="tab-style mt-30 mb-20 folder-mobile">
					<li
						onClick={() => this.props.handlePictureTypeChange('installation_number')}
						className={(this.props.pictureType === 'installation_number' ||
							this.props.pictureType === 0 || this.props.pictureType === 1 ||
							this.props.pictureType === 4 || this.props.pictureType === 6 ||
							this.props.pictureType === 9) ? "active" : ''}>
            <span className="icon-folder-open icon green">
              <span className="path1"></span>
              <span className="path2"></span>
            </span>
						<span>Installation  Numbers</span>
					</li>
					<li
						onClick={() => this.props.handlePictureTypeChange('temporary_id')}
						className={(this.props.pictureType === 'temporary_id' ||
							this.props.pictureType === 0 || this.props.pictureType === 3 ||
							this.props.pictureType === 4 || this.props.pictureType === 8 ||
							this.props.pictureType === 9) ? "active" : ''}>
            <span className="icon-folder-open icon green">
              <span className="path1"></span>
              <span className="path2"></span>
            </span>
						<span>Temporary IDs</span>
					</li>
					<li
						onClick={() => this.props.handlePictureTypeChange('no_number')}
						className={(this.props.pictureType === 'no_number' ||
							this.props.pictureType === 0 || this.props.pictureType === 5 ||
							this.props.pictureType === 6 || this.props.pictureType === 8 ||
							this.props.pictureType === 9) ? "active" : ''}>
            <span className="icon-folder-open icon green">
              <span className="path1"></span>
              <span className="path2"></span>
            </span>
						<span>No Number or ID</span>
					</li>
				</ul>
			</>
		);
	}
}

export default Navigation;