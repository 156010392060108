import React from 'react';
import {SessionState} from "../../../store/session/types";
import {beautifyRole} from "../../../helpers/BeautifierForPm";


interface HeaderProps {
	session: SessionState
	active: string

	onLeftSidebarOpen(): void

	bodyOverlay(): void

	getUser(id: number): void

	handleFileChange(input: HTMLInputElement): void

	openGlobalFilter(): void

	logOut(e: any): void
}

class Header extends React.Component<HeaderProps> {
	render() {
		return (
			<header>
				<button onClick={() => {
					this.props.onLeftSidebarOpen();
					this.props.bodyOverlay()
				}} className="no-style sidebar-open-btn drawer-btn mr-30">
					<span className="icon-menu block"/>
				</button>
				<div className="container">
					<div className="flex align-center header-inner">
						<div className="left-side flex align-center">
							<div className="mr-40 mr-sm-30">
								<input
									type='file'
									multiple
									accept='image/*'
									style={{display: "none"}}
									id='files3'
									onChange={(event) => this.props.handleFileChange(event.target!)}
								/>
								<label htmlFor={(this.props.active === 'upload')?'gagosa':'files3'} className="no-style color-blue pointer">
								<span className="icon-cloud icon blue vertical-middle">
									<span className="path1"/>
									<span className="path2"/>
								</span>
									<span className="fw-700 color-primary vertical-middle ml-10 hide-md">Upload</span>
								</label>
							</div>
							<button onClick={() =>{
								this.props.active !== 'search-in-gallery' && this.props.bodyOverlay();
								this.props.openGlobalFilter();
							}} className="no-style color-text-70">
								<span className="icon-search icon vertical-middle mr-15"/>
								<span className="fw-700 vertical-middle hide-md">Search in Gallery</span>
							</button>

						</div>
						<div className="right-side inline-flex align-center pull-right">
							<div className="pr-15">
								<h5>{(this.props.session.name && this.props.session.surname)
									? this.props.session.name + " " + this.props.session.surname
									: '  '}</h5>
								<span className="role">{beautifyRole(this.props.session.role)}</span>
							</div>
							<a onClick={this.props.logOut} href=""><span className="icon-turn-off icon"/></a>
						</div>
					</div>

				</div>
			</header>

		);
	}
}

export default Header;