import React from 'react';
import {withFormik, FormikProps} from 'formik';
import DatePicker from "react-datepicker";

export interface FormValues {
	normal: boolean,
	threeSixty: boolean,
	startDate: any,
	endDate: any
}

interface OtherProps {
	isOpen: boolean

	onRightSidebarOpen(): void,

	bodyOverlay(): void,

	onSubmit(normal: boolean, threeSixty: boolean, startDate: any, endDate: any): void,
}

interface InnerFormProps extends FormikProps<FormValues>, OtherProps {
	clearSelectedPicturesArray(): void;
}

interface InnerFormState {

}

class InnerForm extends React.Component<InnerFormProps, InnerFormState> {

	render() {
		const {
			values,
			handleChange,
			handleBlur,
			handleSubmit,
			setFieldValue,
			onRightSidebarOpen,
			bodyOverlay,
			resetForm,
			clearSelectedPicturesArray,
			onSubmit
		} = this.props;
		const {normal, threeSixty, startDate, endDate} = values;

		return (
			<form onSubmit={handleSubmit}>
				<div className="flex align-center  mb-35 justify-between">
					<h2 className="fw-500">Filter</h2>
					<button type="submit"
					        onClick={() => {
						        resetForm();
						        clearSelectedPicturesArray()
					        }}
					        className="link pointer no-style"
					>
						Reset
					</button>
				</div>

				<div className="pb-40">
					<h3 className="fw-500 mb-20">Select Image Type(s)</h3>
					<div className="checkbox mb-30">
						<label htmlFor="normal">
							<input
								type="checkbox"
								id="normal"
								name="normal"
								onChange={handleChange}
								onBlur={handleBlur}
								checked={normal}
							/>
							<span className="checkmark vertical-middle"/>
							<span className="fw-700 ml-20 vertical-middle">Normal</span>
						</label>
					</div>

					<div className="checkbox">
						<label htmlFor="360">
							<input
								type="checkbox"
								id="360"
								name="threeSixty"
								onChange={handleChange}
								onBlur={handleBlur}
								checked={threeSixty}
							/>
							<span className="checkmark vertical-middle"/>
							<span className="fw-700 ml-20 vertical-middle">360</span>
						</label>
					</div>
				</div>

				<div className="cols-md-2">
					<div className="col-md-6">
						<div className="textfield with-right-icon">
							<DatePicker
								placeholderText={'From'}
								value={startDate}
								selected={startDate}
								selectsStart
								startDate={startDate}
								endDate={endDate}
								onChange={e => setFieldValue('startDate', e)}
							/>
							<span className="icon-date-range icon">
                <span className="path1"/>
                <span className="path2"/>
              </span>
						</div>
					</div>
					<div className="col-md-6">
						<div className="textfield with-right-icon align-right">
							<DatePicker
								placeholderText={'To'}
								value={endDate}
								selected={endDate}
								selectsEnd
								startDate={startDate}
								endDate={endDate}
								onChange={e => setFieldValue('endDate', e)}
							/>
							<span className="icon-date-range icon">
                <span className="path1"/>
                <span className="path2"/>
              </span>
						</div>
					</div>
				</div>

				<div className="sidebar-meta">
					<button onClick={() => {
						onRightSidebarOpen();
						bodyOverlay();
					}} type='button' className="btn outline text mr-15">Cancel
					</button>
					<button type='submit' onClick={() => {
						onRightSidebarOpen();
						bodyOverlay();
					}} className="btn filled primary ripple">
						<span>Apply</span>
					</button>
				</div>
			</form>
		);
	}
}

interface MyFormProps {

	isOpen: boolean

	onRightSidebarOpen(): void,

	bodyOverlay(): void,

	onSubmit(normal: boolean, threeSixty: boolean, startDate: any, endDate: any): void,
}

export const PendingFilterForm = withFormik<MyFormProps, FormValues>({
	enableReinitialize: true,

	mapPropsToValues: () => {

		return {
			normal: false,
			threeSixty: false,
			startDate: null,
			endDate: null
		}
	},

	handleSubmit(
		{normal, threeSixty, startDate, endDate}: FormValues,
		{props}
	) {
		props.onSubmit(normal, threeSixty, startDate, endDate);
	},
})(InnerForm as any);
